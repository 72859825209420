import {
  ORDER_NOT_FOUND,
  OrderStatusType,
  SERVICE_UNAVAILABLE,
  TRY_AGAIN_LATER,
} from 'components/order-status/constants';

export type DeliveryInfo = {
  deliveryDate: string;
  deliveryTimeFrom: string;
  deliveryTimeTo: string;
};

export type OrderStatusInfo = {
  createdDate: string;
  status: {
    type: OrderStatusType;
    displayedText: string;
  };
  deliveryInfo: DeliveryInfo;
};

export type OrderStatusInfoRequest = {
  isMarketplaceOrder: boolean;
  orderNumber: string;
  phone: string;
  recaptchaToken: string;
};

export enum OrderStatusError {
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export const OrderStatusErrorText = {
  [OrderStatusError.NOT_FOUND]: ORDER_NOT_FOUND,
  [OrderStatusError.INTERNAL_ERROR]: `${SERVICE_UNAVAILABLE} ${TRY_AGAIN_LATER}`,
};
