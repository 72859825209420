import { css } from 'linaria';

export const dividerStyle = css`
  width: auto !important;
  margin: 0 0 0 var(--space-400);
  &.marginBottom {
    margin-bottom: var(--space-200);
  }

  &.marginTop {
    margin-top: var(--space-200);
  }

  @media (--to-tablet) {
    margin: 0 0 0 var(--space-300);
  }
`;

export const stretchedLink = css`
  @media (hover: hover) {
    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: -1;
    }

    &:hover,
    &:focus {
      &::after {
        background-color: var(--background-secondary);
      }
    }
  }
`;

export const primaryMenuItem = css`
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;

  padding-top: var(--space-200) !important;
  padding-bottom: var(--space-200) !important;

  & > * {
    display: flex !important;
    flex-direction: column !important;
    align-items: baseline !important;
    row-gap: var(--space-100) !important;
  }
`;

export const secondaryItemsRoot = css`
  display: grid;
  width: 100%;
  position: relative;

  margin-top: var(--space-200);
  margin-bottom: var(--space-200);
  overflow: hidden;
`;

export const secondaryItem = css`
  width: 100%;
  display: flex !important;

  padding-top: var(--space-150) !important;
  padding-bottom: var(--space-150) !important;
  align-items: center !important;
  justify-content: flex-start !important;
  position: relative !important;
  column-gap: var(--space-200) !important;

  & > * {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    column-gap: var(--space-200) !important;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--text-primary) !important;
    }
  }
`;

export const footer = css`
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
  z-index: 1;

  @media (--to-tablet) {
    padding-left: 0;
    padding-right: 0;
    flex-grow: unset;
  }
`;

export const content = css`
  padding: 0;

  @media (--to-tablet) {
    display: flex;
    flex: unset;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
  }
`;

export const linkSpace = css`
  padding-left: var(--space-400) !important;
  padding-right: var(--space-400) !important;

  @media (--to-tablet) {
    padding-left: var(--space-300) !important;
    padding-right: var(--space-300) !important;
  }
`;
