import { OrderStatusInfo } from '../types/order-status';

export type OrderStatusEvents = {
  'orderStatus/FETCH_ORDER_STATUS/loading': string;
  'orderStatus/FETCH_ORDER_STATUS/request': string;
  'orderStatus/FETCH_ORDER_STATUS/success': OrderStatusInfo;
  'orderStatus/FETCH_ORDER_STATUS/failure': string;
  'orderStatus/OPEN_MODAL': string;
  'orderStatus/CLOSE_MODAL': string;
};

export const FETCH_ORDER_STATUS = {
  loading: 'orderStatus/FETCH_ORDER_STATUS/loading',
  request: 'orderStatus/FETCH_ORDER_STATUS/request',
  success: 'orderStatus/FETCH_ORDER_STATUS/success',
  failure: 'orderStatus/FETCH_ORDER_STATUS/failure',
} as const;

export const ORDER_STATUS = {
  openModal: 'orderStatus/OPEN_MODAL',
  closeModal: 'orderStatus/CLOSE_MODAL',
} as const;
