const ld = 1200;
const md = 1024;
const sm = 768;

const ldMin = ld;

const mdMin = md;
const mdMax = ld - 1;

const smMin = sm;
const smMax = md - 1;

const xsMax = sm - 1;

/**
 * Медиа-выражения, которые учитывают верхнюю и нижнюю границу брейкпойнта
 */
const strictBreakpoints = {
  '--only-mobile': `(max-width: ${xsMax}px)`,
  '--only-tablet': `(min-width: ${smMin}px) and (max-width: ${smMax}px)`,
  '--only-desktop': `(min-width: ${mdMin}px) and (max-width: ${mdMax}px)`,
  '--only-large-desktop': `(min-width: ${ldMin}px)`,
};

/**
 * Медиа-выражения, которые учитывают только нижнюю границу брейкпойнта
 */
const lowerBoundsBreakpoints = {
  '--from-tablet': `(min-width: ${smMin}px)`,
  '--from-desktop': `(min-width: ${mdMin}px)`,
  '--from-large-desktop': `(min-width: ${ldMin}px)`,
};

const upperBoundsBreakpoints = {
  '--to-tablet': `(max-width: ${smMax}px)`,
  '--to-desktop': `(max-width: ${mdMax}px)`,
};

// eslint-disable-next-line prefer-object-spread
const breakpoints = Object.assign({}, strictBreakpoints, lowerBoundsBreakpoints, upperBoundsBreakpoints);

module.exports = {
  ldMin,
  mdMin,
  mdMax,
  smMin,
  smMax,
  xsMax,
  breakpoints,
  strictBreakpoints,
  lowerBoundsBreakpoints,
  upperBoundsBreakpoints,
};
