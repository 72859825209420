import type { Store } from '../store/types';
import { FETCH_SUGGESTIONS, SUGGESTIONS_RESET_SERVICES } from '../actions';
import type { Suggestions } from '../types/suggestions';
import { getSuggestions } from '../api';
import { uniqID } from '../utils/uniq-id';
import { createCookies, getLangFromCookies, getRegion, getDomain, DEFAULT_KZ_REGION } from '../utils';

export const suggestions = (store: Store) => {
  store.on('@init', () => ({
    suggestions: { suggestions: null, error: false, loading: false },
  }));

  store.on(FETCH_SUGGESTIONS.init, (_, props) => {
    const {
      apiKey,
      cookies,
      host: hostname,
      orchestratorHost,
      orchestratorHostKz,
      requestID,
      useCredentials,
    } = store.get();
    const lang = getLangFromCookies(createCookies(cookies));
    const domain = getDomain(hostname);
    const region = getRegion(hostname, domain);
    const isKzHost = !__IS_SERVER__ && orchestratorHostKz && region === DEFAULT_KZ_REGION;
    const host = isKzHost ? orchestratorHostKz : orchestratorHost;

    store.dispatch(FETCH_SUGGESTIONS.request, true);

    return getSuggestions({ ...props, lang }, { orchestratorHost: host, useCredentials, apiKey, requestID })
      .then((data) =>
        store.dispatch(FETCH_SUGGESTIONS.success, {
          products: data.products.map((suggestion) => ({ ...suggestion, id: suggestion.id ?? uniqID() })),
          services: data.services.map((service) => ({
            ...service,
            serviceId: service.serviceId ?? uniqID(),
          })),
          categories: data.categories ? [...data.categories] : [],
        }),
      )
      .catch(() => store.dispatch(FETCH_SUGGESTIONS.failure, true));
  });

  store.on(FETCH_SUGGESTIONS.request, (state) => ({
    suggestions: {
      ...state.suggestions,
      loading: true,
      error: false,
    },
  }));

  store.on(FETCH_SUGGESTIONS.success, (_state, data: Suggestions) => {
    const {
      products: newProducts = [],
      services: newServices = [],
      pages: newPages = [],
      categories: newCategories = [],
    } = data;
    const products = newProducts;
    const services = newServices;
    const pages = newPages;
    const categories = newCategories;

    return {
      suggestions: { suggestions: { products, services, pages, categories }, loading: false, error: false },
    };
  });

  store.on(FETCH_SUGGESTIONS.failure, () => ({
    suggestions: { suggestions: null, loading: false, error: true },
  }));

  store.on(SUGGESTIONS_RESET_SERVICES, (state) => {
    if (state.suggestions.suggestions != null)
      return {
        suggestions: {
          suggestions: {
            products: state.suggestions.suggestions.products,
            services: [],
            pages: state.suggestions.suggestions.pages,
            categories: [],
          },
          loading: state.suggestions.loading,
          error: state.suggestions.error,
        },
      };

    return state;
  });
};

export type SuggestionsStore = {
  suggestions: {
    suggestions: Suggestions | null;
    loading: boolean;
    error: boolean;
  };
};
