import { css } from 'linaria';

export const iconWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;

  &.withBorder {
    border: 1px solid var(--control-secondary);
  }
`;
