import { css } from 'linaria';

export const menuButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  padding: var(--space-150) var(--space-300) !important;
  margin-right: var(--space-150);
  height: 48px !important;
  width: auto !important;
`;
