import { css } from 'linaria';

export const regionLink = css`
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;

  padding: var(--space-200) var(--space-400) !important;

  @media (--to-tablet) {
    padding: var(--space-200) var(--space-300) !important;
  }

  & > * {
    display: flex !important;
    flex-direction: column;
    align-items: baseline !important;
    row-gap: var(--space-100);
  }
`;

export const regionNotFound = css`
  padding: var(--space-200) var(--space-400);
  @media (--to-tablet) {
    padding: var(--space-200) var(--space-300);
  }
`;
