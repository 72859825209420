const checkPadding = (value: string) => {
  const parsedValue = parseInt(value, 10);
  return !isNaN(parsedValue) ? parsedValue > 0 : false;
};

export const hasBodyBottomPadding = () => {
  const computedStyle = window.getComputedStyle(document.body, null);
  const bodyPadding = computedStyle.getPropertyValue('padding');
  const bodyBottomPadding = computedStyle.getPropertyValue('padding-bottom');
  const paddings = bodyPadding?.split(' ') || '';

  if (!checkPadding(bodyBottomPadding)) {
    if (paddings.length && paddings.length < 3) {
      return checkPadding(paddings[0]);
    }
    if (paddings.length > 2 && paddings.length < 5) {
      return checkPadding(paddings[2]);
    }
  } else {
    return true;
  }

  return false;
};
