import React from 'react';
import { Divider, Typography } from 'fronton-react';
import {
  UserCircleIcon,
  IdentificationBadgeIcon,
  ChevronRightIcon,
  PackageIcon,
} from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { B2B_PERSONAL_ACCOUNT_HOST } from 'common/constants/header-constants';
import { WithAuthProps, withAuth } from 'components/header/hoc';
import { useAuthLogic } from 'components/header/hooks/auth';
import { UserMenuItem } from '../profile/components/menu-item';
import { WithLoginB2B } from '../profile';
import { entryMenuTitle } from './styles';

export interface EntryUserMenuProps extends WithAuthProps, WithLoginB2B {
  loginPageUrl: string;
  onOrderStatusModalOpen: () => void;
  isOrderStatusOn: boolean;
  isB2BEnabled: boolean;
}

export const EntryUserMenuBase = ({
  loginPageUrl,
  login,
  loginB2B,
  onOrderStatusModalOpen,
  isOrderStatusOn,
  isB2BEnabled,
}: EntryUserMenuProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isOrderStatusOn && (
        <Typography variant="overline" color="text-minor" className={entryMenuTitle}>
          {t('header.main.user-menu.entry')}
        </Typography>
      )}

      <UserMenuItem
        icon={UserCircleIcon}
        rightIcon={ChevronRightIcon}
        data-qa="signin-lk"
        href={loginPageUrl}
        onClick={login}
      >
        {t('header.main.user-menu.private-acc')}
      </UserMenuItem>

      {isB2BEnabled && (
        <UserMenuItem
          onClick={loginB2B}
          icon={IdentificationBadgeIcon}
          rightIcon={ChevronRightIcon}
          data-qa="signin-lk-b2b"
          href={B2B_PERSONAL_ACCOUNT_HOST}
        >
          {t('header.main.user-menu.business-acc')}
        </UserMenuItem>
      )}

      {isOrderStatusOn && (
        <>
          <Divider />

          <UserMenuItem onClick={onOrderStatusModalOpen} icon={PackageIcon} data-qa="order-status-button">
            {t('header.main.user-menu.order-status')}
          </UserMenuItem>
        </>
      )}
    </>
  );
};

export const EntryUserMenu = withAuth<EntryUserMenuProps, WithAuthProps & WithLoginB2B>(
  EntryUserMenuBase,
  useAuthLogic,
);
