/**
 * Shallow compare two objects
 */

export const shallowObjectsEqual = <T1 extends object, T2 extends object>(object1: T1, object2: T2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};
