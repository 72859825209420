import { normalizeTrailingSlash } from 'utils/link-helpers';

export const patchLinkWithShopPath = (link: string) => {
  const url = new URL(link);
  const pathParts = url.pathname.split('/').filter((part) => part !== '');
  const shopString = 'shop';

  if (pathParts.length > 1 && pathParts[0] === shopString) {
    url.pathname = `/${shopString}/`;
  }
  return normalizeTrailingSlash(url.toString());
};
