export function prepareSrc(kitName: string, host: string, id: string) {
  const extraParam = kitName === 'dmpkitdl' ? '' : `&l=${kitName}`;

  return `//${host}/tm.js?id=${id}${extraParam}`;
}

export const addCdpScript = (kitName?: string, id?: string, host?: string) => {
  if (!kitName || !id || !host) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window[kitName] = window[kitName] || [];
  const { head } = document;

  if (head) {
    const scriptSrc = prepareSrc(kitName, host, id);
    const isExists = head.querySelector(`script[src='${scriptSrc}']`);

    if (!isExists) {
      const element = document.createElement('script');

      element.async = true;
      element.src = scriptSrc;
      element.id = 'cdp_script';

      head.appendChild(element);
    }
  }
};
