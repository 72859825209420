import { HeaderModelData, Region } from 'types/model';
import { selectCurrentRegion } from 'selectors/model';

export interface UseRegionsReturnType {
  regions: Region[];
  currentRegion: Region | null;
  defaultRegion: Region;
}

export const useRegions = (model: HeaderModelData): UseRegionsReturnType => {
  const {
    regions,
    properties: { regionId },
  } = model;
  const currentRegion = selectCurrentRegion(regions, regionId);

  return {
    regions,
    currentRegion,
    defaultRegion: regions[0],
  };
};
