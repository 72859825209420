import { useCallback } from 'react';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';
import { CITY_PICKER_MENU } from 'actions';

export interface UseCityPickerStateReturnType {
  show: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
}

export const useCityPickerState = (): UseCityPickerStateReturnType => {
  const { dispatch, headerDrawers } = useStoreon<State>('headerDrawers');

  const onMenuOpen = useCallback(() => dispatch(CITY_PICKER_MENU.openDrawer), [dispatch]);
  const onMenuClose = useCallback(() => dispatch(CITY_PICKER_MENU.closeDrawer), [dispatch]);

  return {
    show: headerDrawers.cityPickerVisible,
    onMenuClose,
    onMenuOpen,
  };
};
