import { analytics } from 'utils/analytics';

export type ProfileAnalyticsElementText =
  | 'purchases'
  | 'loyalty'
  | 'projects'
  | 'shoppingList'
  | 'info'
  | 'logout'
  | 'login'
  | 'b2blogin'
  | 'orderStatus';

const headerAnalyticsEventDetailBase = {
  elementType: 'button',
  elementBlock: 'header',
  elementName: 'headerAccount',
};

export const useProfileAnalyticsEvent = (elementText: ProfileAnalyticsElementText) => () =>
  analytics.sendEvent('GA:clickButton', {
    ...headerAnalyticsEventDetailBase,
    elementText,
  });
