import { useEffect, RefObject, useCallback, useState, useRef } from 'react';
import { useIsScrollLocked } from 'common/hooks';
import {
  createDirectionFn,
  getElementHeight,
  getVisibleCondition,
  getHasClassName,
  addClassName,
  removeClassName,
  Direction,
} from './utils';

export const useStickyHeader = (
  header: RefObject<HTMLElement>,
  className: string,
  disableSticky: boolean,
  threshold = 0,
  headerContainerHeight = 0,
) => {
  const isScrollLocked = useIsScrollLocked();
  const [getScrollDirection] = useState(() => createDirectionFn());
  let scrollingFrom = useRef<{ distance: number; direction: Direction }>({
    distance: 0,
    direction: 'bottom',
  });
  const [isStickyVisible, setIsStickyVisible] = useState(false);

  const onScroll = useCallback(() => {
    const { current } = header;

    if (current === null || isScrollLocked) return;

    const height = getElementHeight(current);

    const direction = getScrollDirection(window.scrollY);
    const headerVisible = getVisibleCondition(window.scrollY, headerContainerHeight - height);

    const hasClass = getHasClassName(current, className);

    const passedTopLimit = scrollingFrom.current.distance > threshold + window.scrollY;
    const passedBottomLimit = scrollingFrom.current.distance + threshold < window.scrollY;

    if (scrollingFrom.current.direction !== direction) {
      scrollingFrom.current = { distance: window.scrollY, direction };
    }

    if (hasClass && headerVisible) {
      removeClassName(current, className);
      setIsStickyVisible(false);
      scrollingFrom.current = { distance: window.scrollY, direction: 'top' };
    }

    if ((headerVisible || (direction === 'bottom' && hasClass)) && passedBottomLimit) {
      removeClassName(current, className);
      setIsStickyVisible(false);
    }

    if (!headerVisible && !hasClass && direction === 'top' && passedTopLimit) {
      addClassName(current, className);
      setIsStickyVisible(true);
    }
  }, [header, isScrollLocked, getScrollDirection, headerContainerHeight, className, threshold]);

  useEffect(() => {
    if (!__IS_SERVER__ && !disableSticky) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (!__IS_SERVER__ && !disableSticky) {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [disableSticky, onScroll]);

  return isStickyVisible;
};
