import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Region } from 'types/model';
import { Input, IconButton } from 'fronton-react';
import { XCircleIcon } from '@fronton/icons-react';
import { WithCitiesSuggestions, useBreakpoints } from 'common/hooks';

import { useGetRegionLink } from 'components/header/hooks';
import { useTranslation } from 'react-i18next';
import { useKeyboardNavigation } from 'common/hooks/use-keyboard-navigation';
import { sendCitiesInputGAEvents } from 'common/utils';
import { analytics } from 'utils';
import { form, clearButton, searchInput, searchRoot } from './styles';
import { RegionLink } from '../regions-list/region-link';
import { MenuContentLayer } from '../../menu-content-layer';
import { getNextSearchInputValue } from './utils';
import { useOnIntersectionFocus } from './use-intersection-focus';
import { RegionNotFoundMessage } from '../regions-list/region-not-found-message';
import { RegionsList } from '../regions-list/regions-list';

export interface RegionsSearchProps extends WithCitiesSuggestions {
  regions: Region[];
}

export const RegionsSearch = (props: RegionsSearchProps) => {
  const { regions, suggestions, setSuggestions, hasRegionNumberChanged, filteredCitiesList } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  const onReset = () => {
    setValue('');
    sendCitiesInputGAEvents('inputClean');
  };

  const {
    index: navigationIndex,
    reset,
    selectedItem,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
  } = useKeyboardNavigation(suggestions, { canUnselectAllItems: false });

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: valueFromEvent } = event.target;
    setValue((prev): string => getNextSearchInputValue(prev, valueFromEvent));
  }, []);

  useEffect(() => {
    reset();
    if (value) {
      const nextSuggestions = filteredCitiesList(value);
      setSuggestions(nextSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [filteredCitiesList, setSuggestions, value, reset]);

  const onFocus = () => sendCitiesInputGAEvents('inputFocus');
  const onBlur = () => sendCitiesInputGAEvents('inputUnfocus', value);

  const getRegionLink = useGetRegionLink();

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (selectedItem) {
        analytics.sendEvent('GA:click', { name: 'changeRegion', position: 'header' });
        document.location.href = getRegionLink(selectedItem.currentPageLink);
      }
      e.preventDefault();
    },
    [selectedItem, getRegionLink],
  );

  const match = useBreakpoints();
  const isMobile = match ? match.mobile : false;
  const inputRef = useRef<HTMLInputElement>(null);

  const suggestionsFound = value && suggestions.length > 0;
  const suggestionsNotFound = value && suggestions.length === 0;
  const notFiltering = !value;

  useOnIntersectionFocus(inputRef, isMobile);

  return (
    <div onKeyDown={onKeyDown}>
      <MenuContentLayer horizontal className={searchRoot}>
        <form onReset={onReset} onSubmit={onSubmit} className={form}>
          <Input
            allowClear
            value={value}
            onChange={onChange}
            className={searchInput}
            onFocus={onFocus}
            onBlur={onBlur}
            mobile={isMobile}
            data-qa="region-search-input"
            ref={inputRef}
            placeholder={t('header.main.search-placeholder')}
          />
          {value && (
            <IconButton
              data-qa="region-search-clear-btn"
              as="button"
              type="reset"
              size="l"
              label={t('header.searchbar.clear-search')}
              className={clearButton}
            >
              <XCircleIcon type="fill" color="text-minor" />
            </IconButton>
          )}
        </form>
      </MenuContentLayer>

      {suggestionsNotFound && <RegionNotFoundMessage />}

      {suggestionsFound && (
        <div data-qa="region-suggestions-list">
          {suggestions.map((region, idx) => (
            <RegionLink
              key={region.regionName}
              region={region}
              hasRegionNumberChanged={hasRegionNumberChanged}
              onMouseEnter={() => onMouseEnter(idx)}
              onMouseLeave={onMouseLeave}
              focused={idx === navigationIndex}
              getRegionLink={getRegionLink}
            />
          ))}
        </div>
      )}

      {notFiltering && (
        <div data-qa="region-list">
          <RegionsList regions={regions} />
        </div>
      )}
    </div>
  );
};
