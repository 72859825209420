import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { useOrderStatusAnalyticEvent } from './use-order-status-analytic-event';
import { ORDER_STATUS } from '../../../actions';

export const useOrderStatusModal = () => {
  const { dispatch, orderStatus } = useStoreon<State>('orderStatus');
  const { isShowModal } = orderStatus;
  const { sendOrderStatusClosingEvent, sendOrderStatusOpeningEvent } = useOrderStatusAnalyticEvent();

  const closeOrderStatusModal = () => {
    dispatch(ORDER_STATUS.closeModal);
    sendOrderStatusClosingEvent();
  };

  const openOrderStatusModal = () => {
    dispatch(ORDER_STATUS.openModal);
    sendOrderStatusOpeningEvent();
  };

  return {
    isShow: isShowModal,
    closeOrderStatusModal,
    openOrderStatusModal,
  };
};
