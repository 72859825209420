import { SERVICE_UNAVAILABLE, TRY_AGAIN_LATER } from '../constants/order-status-text';

export const getErrorSnack = (id: string) => ({
  id,
  title: SERVICE_UNAVAILABLE,
  content: TRY_AGAIN_LATER,
  variant: 'error',
  wide: true,
  hasCloseButton: true,
});
