import React from 'react';
import { InputSnackProps, Snackbar, Snack, SnackCloseButton, useSnackbar } from 'fronton-react';
import { orderStatusSnackIdRegexp } from 'components/order-status/constants/snack';
import { OrderStatusSnackbarProps } from './types';

export const OrderStatusSnackbar = ({ verticalPosition }: OrderStatusSnackbarProps) => {
  const { state, removeSnack } = useSnackbar();

  return (
    <Snackbar verticalPosition={verticalPosition} data-qa="order-status-snackbar">
      {(state as InputSnackProps[])
        .filter(({ id }) => orderStatusSnackIdRegexp.test(id))
        .map(({ id, title, content, variant, testId, hasCloseButton }) => (
          <Snack
            data-qa={testId}
            key={id}
            id={id}
            header={title}
            paragraph={content}
            variant={variant}
            closeButton={hasCloseButton && <SnackCloseButton onClick={() => removeSnack(id)} />}
          />
        ))}
    </Snackbar>
  );
};
