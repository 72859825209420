import i18n from 'i18next';
import config from 'i18n-config';
import Cookies from 'universal-cookie';
import { initReactI18next } from 'react-i18next';
import { getDomain } from 'utils';
import { getDatePlusYear } from './date-time-helpers';

export function initI18n(path = '', host = '') {
  let lang = 'ru';

  if (path.startsWith('/kz/')) {
    lang = 'kk';
  }

  const cookies = new Cookies();
  const domain = `.${getDomain(host)}`;
  cookies.set('lang', lang, { path: '/', expires: getDatePlusYear(), domain });

  i18n.use(initReactI18next).init(config(lang));

  return lang;
}
