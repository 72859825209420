import { css } from 'linaria';

export const root = css`
  height: var(--space-700);
  width: var(--space-900);
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    border: none;
  }
  @media (--only-mobile) {
    justify-content: center;
  }
`;

export const logo = css`
  height: var(--space-700);
  width: var(--space-900);
`;
