import { css } from 'linaria';

export const item = css`
  display: flex !important;
  justify-content: start !important;
  column-gap: var(--space-150);
  padding: var(--space-100) var(--space-150) !important;
  width: 100%;

  @media (--to-tablet) {
    padding: var(--space-150) var(--space-200) !important;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--background-secondary) !important;
      color: var(--text-primary) !important;
    }
  }
`;

export const itemIcon = css`
  display: flex;
  align-items: center;

  & svg {
    @media (--to-tablet) {
      width: 24px;
      height: 24px;
    }
  }
`;

export const itemIconRight = css`
  margin-left: auto;
  margin-right: var(--space-25);
`;
