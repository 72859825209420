import { Suggestions, SuggestionsRequest } from '../types/suggestions';

export type SuggestionsEvents = {
  'suggestions/FETCH_SUGGESTIONS/init': SuggestionsRequest;
  'suggestions/FETCH_SUGGESTIONS/request': boolean;
  'suggestions/FETCH_SUGGESTIONS/success': Suggestions;
  'suggestions/FETCH_SUGGESTIONS/failure': boolean;
  'suggestions/FETCH_SUGGESTIONS/cancel': undefined;
  'suggestions/RESET_SERVICES': undefined;
};

export const FETCH_SUGGESTIONS = {
  init: 'suggestions/FETCH_SUGGESTIONS/init',
  request: 'suggestions/FETCH_SUGGESTIONS/request',
  success: 'suggestions/FETCH_SUGGESTIONS/success',
  failure: 'suggestions/FETCH_SUGGESTIONS/failure',
  cancel: 'suggestions/FETCH_SUGGESTIONS/cancel',
} as const;

export const SUGGESTIONS_RESET_SERVICES = 'suggestions/RESET_SERVICES' as const;
