import React from 'react';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { regionNotFound } from './styles';

export const RegionNotFoundMessage = () => {
  const { t } = useTranslation();
  return (
    <div className={regionNotFound} data-qa="region-not-found-message">
      <Typography variant="m" size="body_long" color="text-minor">
        {t('header.popups.region.not-found')}
      </Typography>
    </div>
  );
};
