import { load } from 'recaptcha-v3';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';

export type AddTokenProps<T> = {
  action: string;
  data: T;
};

export const useRecaptchaToken = () => {
  const { model } = useStoreon<State>('model');
  const recaptchaPublicKey = model?.header?.recaptchaPublicKey ?? '';

  const addRecaptchaToken = <T>({
    action,
    data,
  }: AddTokenProps<T>): Promise<T | (T & { recaptchaToken: string })> => {
    if (!recaptchaPublicKey) {
      return Promise.reject(new Error('There is no public recaptcha key'));
    }

    return load(recaptchaPublicKey, { autoHideBadge: true })
      .then((recaptcha) => recaptcha.execute(action))
      .then((recaptchaToken) => ({ ...data, recaptchaToken }));
  };

  return { addRecaptchaToken };
};
