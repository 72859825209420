import log from 'loglevel';
import { getDomain, getHostname, getInitAuthUrl, getLangByPath, getRegion } from 'utils';
import type { Store } from '../store/types';
import { FETCH_MODEL, MODEL_RESET } from '../actions';
import { getModel } from '../api';
import { HFModelData } from '../types/model';
import { useSiteDomain } from '../components/header/components/searchbar/hooks/use-site-domain';

const model = (store: Store) => {
  store.on('@init', () => ({ model: null }));
  store.on(FETCH_MODEL.init, (_, { host, path, pathname }) => {
    const {
      modelLoading: storeModelLoading,
      orchestratorHost,
      useCredentials,
      apiKey,
      requestID,
    } = store.get();
    if (storeModelLoading) return Promise.resolve();

    store.dispatch(FETCH_MODEL.request);

    const hostname = getHostname(host);
    const domain = getDomain(hostname);
    const region = getRegion(hostname, domain);
    const lang = getLangByPath(path);
    const { isKzDomain } = useSiteDomain(domain);

    log.info('Fetch model initialised by HF', 'requestID: ', requestID, 'dateNow: ', Date.now());

    return getModel({ pathname, region, lang }, { orchestratorHost, useCredentials, apiKey, requestID })
      .then((data) => {
        log.info('Model successfully fetched by HF', 'requestID: ', requestID, 'dateNow: ', Date.now());
        const { header } = data;

        if (path !== undefined) {
          header.initUrl = getInitAuthUrl({
            authUrl: header.initUrl,
            curPage: header.logoutUrl,
            path,
            isKzDomain,
            lang,
          });
        }

        store.dispatch(FETCH_MODEL.success, data as HFModelData);
      })
      .catch((e) => store.dispatch(FETCH_MODEL.failure, e));
  });
  store.on(FETCH_MODEL.failure, (e) => {
    log.error('Failed to fetch model for HF', 'requestID: ', e.requestID, 'dateNow: ', Date.now());
    return { model: null };
  });
  store.on(MODEL_RESET, () => null);
  store.on(FETCH_MODEL.success, (_, res) => ({ model: res }));
};

const modelLoading = (store: Store) => {
  store.on('@init', () => ({ modelLoading: false }));
  store.on(FETCH_MODEL.success, () => ({ modelLoading: false }));
  store.on(FETCH_MODEL.failure, () => ({ modelLoading: false }));
  store.on(FETCH_MODEL.request, () => ({ modelLoading: true }));
};

const modelError = (store: Store) => {
  store.on('@init', () => ({ modelError: null }));
  store.on(FETCH_MODEL.request, () => ({ modelError: null }));
  store.on(FETCH_MODEL.success, () => ({ modelError: null }));
  store.on(FETCH_MODEL.failure, (_, err) => ({ modelError: err }));
};

export const modelJson = [model, modelLoading, modelError];
export type ModelJsonStore = {
  model: HFModelData | null;
  modelLoading: boolean;
  modelError: Error | null;
};
