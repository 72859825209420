import { getDomain } from 'utils';

export const prependRegionToUrl = (baseUrl: string, regionCode: string) => {
  const url = new URL(baseUrl);
  const linkRegion = regionCode === 'moscow' ? '' : `${regionCode}.`;
  const linkHostname = !!url.hostname.match(new RegExp('lm(ru|kz).tech'))?.length
    ? getCleanStageHostname(url.hostname)
    : getDomain(url.hostname);

  url.hostname = `${linkRegion}${linkHostname}`;

  return url.toString();
};

export const getCleanStageHostname = (hostname: string) => {
  const parts = hostname.split('.');

  return parts.length > 4 ? parts.slice(parts.length - 4).join('.') : parts.join('.');
};
