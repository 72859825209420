import { useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'fronton-react';
import { aboveTooltip } from 'styles/z-indexs';
import { useBreakpoints } from 'common/hooks';
import {
  applySearchBarAnimation,
  applySuggestionAnimation,
  getAnimationParams,
  getSearchBarAnimationFromTo,
  getSuggestionsAnimationFromTo,
} from './utils/animation';
import { animate } from './utils/animate';
import { TransitionState } from '../types';

export type UseSearchBarTransitionParams = {
  expanded: boolean;
  suggestionSelector: string;
  searchbarRef: React.MutableRefObject<HTMLElement | null>;
  containerRef: React.MutableRefObject<HTMLElement | null>;
  suggestionsRef: React.MutableRefObject<HTMLElement | null>;
};

export interface UseModernSearchBarTransitionParams extends UseSearchBarTransitionParams {
  // ref элемента размеры которого будут указывать от/до каких размеров анимарироть инпут
  inputSizeReference: React.MutableRefObject<HTMLElement | null>;
}

export const useModernSearchBarTransition = (params: UseModernSearchBarTransitionParams) => {
  const { containerRef, searchbarRef, suggestionsRef, expanded, suggestionSelector, inputSizeReference } =
    params;

  const prevExpanded = useRef<boolean>(expanded);
  const searchbarAnimation = useRef<Animation | null>(null);
  const suggestionsAnimation = useRef<Animation | null>(null);

  const [state, setState] = useState<TransitionState>(expanded ? 'needsExpand' : 'collapsed');

  const breakpoints = useBreakpoints();

  useIsomorphicLayoutEffect(() => {
    if (prevExpanded.current !== expanded) {
      setState(expanded ? 'needsExpand' : 'needsCollapse');
    }
  }, [expanded]);

  useIsomorphicLayoutEffect(() => {
    if (expanded && prevExpanded.current === expanded) {
      setState('needsReflow');
    }
  }, [breakpoints, expanded]);

  useIsomorphicLayoutEffect(() => {
    prevExpanded.current = expanded;
  }, [expanded]);

  useIsomorphicLayoutEffect(() => {
    setTimeout(() => {
      const currentSearchbarRef = searchbarRef.current;
      const currentContainerRef = containerRef.current;
      const currentSuggestionsRef = suggestionsRef.current;
      const currentExpandedAnimation = searchbarAnimation.current;
      const currentSuggestionsAnimation = suggestionsAnimation.current;
      const currentInputSizeReference = inputSizeReference.current;

      if (!currentSearchbarRef || !currentContainerRef || !currentInputSizeReference) return;

      const animationSettings = getAnimationParams(state);

      if ((state === 'needsExpand' || state === 'needsReflow') && currentSuggestionsRef) {
        currentExpandedAnimation?.cancel();
        currentSuggestionsAnimation?.cancel();

        currentSearchbarRef.style.position = 'fixed';
        currentSearchbarRef.style.zIndex = `${aboveTooltip + 1}`;

        const animationSearchBarParams = getSearchBarAnimationFromTo({
          ownReference: currentContainerRef,
          referenceFrom: currentInputSizeReference,
          referenceTo: currentContainerRef,
          breakpoints,
        });

        applySearchBarAnimation({
          animationRef: searchbarAnimation,
          element: currentSearchbarRef,
          onProcessed: () => setState('expanding'),
          animationProps: animationSearchBarParams,
          animationSettings,
        });

        if (state === 'needsExpand') {
          const suggestionChilds = Array.from(
            currentSuggestionsRef.querySelectorAll(`.${suggestionSelector}`),
          );

          suggestionChilds.forEach((child, idx) =>
            animate(
              child,
              [
                { opacity: '0', transform: 'translateY(-8px)' },
                { opacity: '1', transform: 'translateY(0px)' },
              ],
              {
                duration: 400,
                delay: animationSettings.duration + idx * 20,
                easing: 'cubic-bezier(0.24, 0.81, 0.2, 0.96)',
                fill: 'both',
              },
            ),
          );
        }

        const suggestionsAnimationParams = getSuggestionsAnimationFromTo({
          ownReference: currentSuggestionsRef,
          referencePosition: currentContainerRef,
          isMobile: breakpoints?.mobile || false,
          animationSearchBarParams,
        });

        applySuggestionAnimation({
          animationRef: suggestionsAnimation,
          element: currentSuggestionsRef,
          animationProps: suggestionsAnimationParams,
          animationSettings,
        });
      }

      if (state === 'needsCollapse') {
        currentExpandedAnimation?.cancel();
        currentSuggestionsAnimation?.cancel();

        const animationSearchBarParams = getSearchBarAnimationFromTo({
          ownReference: currentSearchbarRef,
          referenceFrom: currentInputSizeReference,
          referenceTo: currentContainerRef,
          reverse: true,
          breakpoints,
        });

        applySearchBarAnimation({
          animationRef: searchbarAnimation,
          element: currentSearchbarRef,
          onProcessed: () => setState('collapsing'),
          animationProps: animationSearchBarParams,
          animationSettings,
          withFinished: false,
        });
      }
    }, 100);
  }, [state, containerRef, searchbarRef, suggestionsRef, expanded, breakpoints, suggestionSelector]);

  useIsomorphicLayoutEffect(() => {
    if ((state === 'expanding' || state === 'collapsing') && searchbarAnimation.current) {
      searchbarAnimation.current.finished
        .then(() => {
          setState((currentState) => {
            if (currentState === 'expanding') return 'expanded';
            if (currentState === 'collapsing') return 'collapsed';

            return currentState;
          });
        })
        .catch(() => {});
    }
  }, [state]);

  useIsomorphicLayoutEffect(() => {
    const { current } = searchbarRef;
    const { current: suggestionCurrent } = suggestionsRef;

    if (state === 'collapsed' && current && suggestionCurrent) {
      current.style.position = '';
      current.style.zIndex = '';
      current.style.top = '';
      current.style.left = '';
      current.style.width = '';
      suggestionCurrent.style.opacity = '';
      searchbarAnimation.current?.cancel();
    }
  }, [searchbarRef, state]);

  return [state];
};
