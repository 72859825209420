import { LinkButton } from 'fronton-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLang, LangExperimentConfig } from 'common/hooks';
import { LANG_TO_REGION, LangType } from 'common/constants';
import { KV } from 'types/utils';
import { styled } from 'linaria/react';
import { isLangMatchDomain, updateLocation } from './utils';

export const changesTo: KV<LangType> = {
  ru: 'kk',
  kk: 'ru',
};

type Props = LangExperimentConfig;

export const LanguageSelector = ({ langDomains, changeLangPath }: Props) => {
  const { t } = useTranslation();
  const { lang, changeLang } = useLang();
  const region = LANG_TO_REGION[lang];

  const handleChangeClick = useCallback(() => {
    changeLang(region, changesTo[lang], changeLangPath, updateLocation);
  }, [changeLang, changeLangPath, lang, region]);

  if (!isLangMatchDomain(window.location.host, langDomains)) {
    return null;
  }

  return (
    <Container>
      <LinkButton
        size="m"
        withExtraClickZone
        aria-label={t('ui.lang-change-to.aria-label')}
        onClick={handleChangeClick}
        data-qa="lang-change-btn"
      >
        {t('ui.lang-change-to.label')}
      </LinkButton>
    </Container>
  );
};

const Container = styled.div`
  padding-right: var(--space-300);
  @media (--from-tablet) {
    padding-right: var(--space-200);
  }
`;
