import { css } from 'linaria';

export const menuWrap = css`
  display: flex;
  flex-direction: column;
  width: 280px;
`;

export const entryMenuTitle = css`
  padding: var(--space-50) var(--space-150) !important;
`;
