/* eslint-disable max-lines */
export const SEARCH_OVERRIDES: Record<string, string> = {
  адреса: '/contacty/',
  акции: '/promo/',
  'анализ воды': '/uslugi/analiz-vody/',
  баллы: '/programmy-loyalnosti/',
  бонус: '/programmy-loyalnosti/',
  'бонусная карта': '/programmy-loyalnosti/',
  бонусы: '/programmy-loyalnosti/',
  'быстрый монтаж': '/uslugi/',
  'бытовая техника': '/catalogue/bytovaya-tehnika/',
  вакансии: '/faq/',
  ванная: '/catalogue/santehnika/',
  'ванная комната': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  водоснабжение: '/uslugi/gotovye-resheniya-pod-klyuch/sistemy-vodosnabzheniya-i-kanalizacii-pod-klyuch/',
  'возврат товара': '/service/vozvrat-tovara/',
  'график работы': '/faq/',
  двери: '/catalogue/dveri/',
  'двери установка': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  дверь: '/catalogue/dveri/',
  'декоративная отделка стен': '/uslugi/',
  'дизайн ванной': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'дизайн ванной комнаты': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'дизайн ванны': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'дизайн кухни': '/proekt-kuhni/',
  'дизайн проект кухни': '/proekt-kuhni/',
  'для кухни': '/catalogue/kuhni/',
  доставка: '/service/dostavka/',
  замер: '/uslugi/',
  'замер входной двери': '/uslugi/catalogue/ustanovka-metallicheskoy-dveri/',
  'замер дверей': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'замер двери': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'замер кухни': '/proekt-kuhni/',
  'замер помещения': '/uslugi/',
  'записаться на замер': '/uslugi/',
  'записаться на проект': '/uslugi/',
  'записаться на проект кухни': '/proekt-kuhni/',
  'записаться на проектирование': '/uslugi/',
  'запись на дизайн': '/uslugi/',
  'запись на замер': '/uslugi/',
  'запись на проект': '/uslugi/',
  'запись на проект кухни': '/proekt-kuhni/',
  'запись на проектирование': '/uslugi/',
  'запись на проектирование кухни': '/proekt-kuhni/',
  ижевск: '/shop/',
  иркутск: '/shop/',
  казань: '/shop/',
  'карта магазина': '/programmy-loyalnosti/',
  киров: '/shop/',
  'ключ-карта': '/programmy-loyalnosti/',
  колеровка: '/uslugi/kolerovka/',
  'колеровка 49111155': '/uslugi/kolerovka/',
  'колеровка для краски': '/uslugi/kolerovka/',
  'колеровка краски': '/uslugi/kolerovka/',
  'колеровка красок': '/uslugi/kolerovka/',
  'колеровка онлайн': '/uslugi/kolerovka/',
  'колеровка палитра': '/uslugi/kolerovka/',
  'кондиционер установка': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'кондиционеры установка': '/uslugi/catalogue/ustanovka-kondicionerov/',
  контакты: '/contacty/',
  'краска колеровка': '/uslugi/kolerovka/',
  краснодар: '/shop/',
  красноярск: '/shop/',
  кредит: '/service/kredit/',
  кухни: '/catalogue/kuhni/',
  'кухни на заказ': '/proekt-kuhni/',
  кухня: '/catalogue/kuhni/',
  'кухня на заказ': '/proekt-kuhni/',
  'ламинат укладка': '/uslugi/ukladka-laminata/',
  'лдсп распил': '/uslugi/rezka-dereva/',
  'лемана про': '/about/',
  'леруа мерлен': '/about/',
  'мебель на заказ': '/uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/',
  монтаж: '/uslugi/',
  'монтаж дверей': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'монтаж двери': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'монтаж кондиционера': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'монтаж кухни': '/proekt-kuhni/',
  'монтаж ламината': '/uslugi/ukladka-laminata/',
  'монтаж москитной сетки': '/uslugi/montazh-okna/',
  'монтаж окна': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'монтаж окон': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'монтаж пластиковых окон': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'монтаж плинтуса': '/uslugi/ustanovka-plintusa/',
  'монтаж плитки': '/uslugi/catalogue/ukladka-plitki/',
  'монтаж радиатора': '/uslugi/catalogue/ustanovka-radiatorov/',
  'монтаж сплит системы': '/uslugi/catalogue/ustanovka-kondicionerov/',
  москва: '/shop/',
  новосибирск: '/shop/',
  ногинск: '/shop/',
  'обои поклейка': '/uslugi/catalogue/pokleyka-oboev/',
  обработка: '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка бани': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка дерева': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка для дерева': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка древесины': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка древисины': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка осб': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка от насекомых': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка от плесени': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  'обработка по дереву': '/uslugi/catalogue/obrabotka-derevyannyh-konstrukciy/',
  оверлок: '/uslugi/overlok/',
  окна: '/catalogue/okna/',
  окно: '/catalogue/okna/',
  омск: '/shop/',
  'онлайн заказ': '/uslugi/',
  'онлайн колеровка': '/uslugi/kolerovka/',
  'онлайн проект': '/uslugi/',
  'отделка ванной': '/uslugi/remont-vannoy-komnaty/',
  'отделка ванной комнаты': '/uslugi/remont-vannoy-komnaty/',
  'отделка ванны': '/uslugi/remont-vannoy-komnaty/',
  'отделка дверей': '/uslugi/gotovye-resheniya-pod-klyuch/mezhkomnatnye-dveri-na-zakaz/',
  'отделка двери': '/uslugi/gotovye-resheniya-pod-klyuch/mezhkomnatnye-dveri-na-zakaz/',
  'отделка для ванной': '/uslugi/remont-vannoy-komnaty/',
  'отделка для окон': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'отделка кухни': '/proekt-kuhni/',
  'отделка окна': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'отделка окон': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'отделка откосов': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'отделка пластиковых окон': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'отделка стен в ванной': '/uslugi/remont-vannoy-komnaty/',
  пенза: '/shop/',
  'плитка резка': '/uslugi/catalogue/ukladka-plitki/',
  'подарочная карта': '/podarochnaya-karta/',
  'подарочный сертификат': '/podarochnaya-karta/',
  'подключение варочной панели': '/uslugi/ustanovka-varochnoy-poverhnosti/',
  'подключение кондиционера': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'подключение плиты': '/uslugi/ustanovka-varochnoy-poverhnosti/',
  'подключение полотенцесушителя': '/uslugi/ustanovka-polotencesushitelya/',
  'подключение радиатора': '/uslugi/catalogue/ustanovka-radiatorov/',
  'подключение радиаторов': '/uslugi/catalogue/ustanovka-radiatorov/',
  'подключение смесителя': '/uslugi/ustanovka-smesitelya-gigienicheskogo-dusha/',
  'подключение стиральной': '/uslugi/podklyuchenie-i-ustanovka-stiralnyh-mashin/',
  'подключение стиральной машины': '/uslugi/podklyuchenie-i-ustanovka-stiralnyh-mashin/',
  'подключение унитаза': '/uslugi/ustanovka-napolnogo-unitaza/',
  'подключение электроплиты': '/uslugi/ustanovka-varochnoy-poverhnosti/',
  подшив: '/uslugi/poshiv-shtor/',
  'подшив штор': '/uslugi/poshiv-shtor/',
  поклейка: '/uslugi/catalogue/pokleyka-oboev/',
  'поклейка обоев': '/uslugi/catalogue/pokleyka-oboev/',
  покраска: '/uslugi/catalogue/pokraska-vnutri-pomeshcheniya/',
  'покраска дерева': '/uslugi/catalogue/pokraska-vnutri-pomeshcheniya/',
  'покраска обоев': '/uslugi/catalogue/pokraska-vnutri-pomeshcheniya/',
  'покраска потолка': '/uslugi/catalogue/pokraska-vnutri-pomeshcheniya/',
  'покраска стен': '/uslugi/catalogue/pokraska-vnutri-pomeshcheniya/',
  'полусухая стяжка': '/uslugi/gotovye-resheniya-pod-klyuch/polusuhaya-styazhka-pola/',
  пошив: '/uslugi/poshiv-shtor/',
  'пошив штор': '/uslugi/poshiv-shtor/',
  'программа лояльности': '/programmy-loyalnosti/',
  проект: '/uslugi/',
  'проект ванной': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'проект ванной комнаты': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'проект ванны': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'проект гардеробной': '/uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/',
  'проект кухни': '/proekt-kuhni/',
  'проект шкафа': '/uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/',
  проектирование: '/uslugi/',
  'проектирование ванной': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  'проектирование гардеробной': '/uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/',
  'проектирование кухни': '/proekt-kuhni/',
  'проектирование шкафа': '/uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/',
  профи: '/kluch-karta-pro/',
  'пункты выдачи': '/service/samovyvoz-tovara/',
  работа: '/faq/',
  распил: '/uslugi/rezka-dereva/',
  'распил дерева': '/uslugi/rezka-dereva/',
  'распил доски': '/uslugi/rezka-dereva/',
  'распил досок': '/uslugi/rezka-dereva/',
  'распил дсп': '/uslugi/rezka-dereva/',
  'распил лдсп': '/uslugi/rezka-dereva/',
  'распил материала': '/uslugi/rezka-dereva/',
  'распил мдф': '/uslugi/rezka-dereva/',
  'распил столешницы': '/uslugi/rezka-dereva/',
  'распил услуга': '/uslugi/rezka-dereva/',
  'распил фанеры': '/uslugi/rezka-dereva/',
  рассрочка: '/service/kredit/',
  'режим работы': '/faq/',
  резка: '/uslugi/rezka-dereva/',
  'резка дерева': '/uslugi/rezka-dereva/',
  'резка зеркал': '/uslugi/rezka-stekla/',
  'резка зеркала': '/uslugi/rezka-stekla/',
  'резка керамогранита': '/uslugi/catalogue/ukladka-plitki/',
  'резка ламината': '/uslugi/ukladka-laminata/',
  'резка лдсп': '/uslugi/rezka-dereva/',
  'резка оргстекла': '/uslugi/rezka-stekla/',
  'резка плитки': '/uslugi/catalogue/ukladka-plitki/',
  'резка стекла': '/uslugi/rezka-stekla/',
  'резка фанеры': '/uslugi/rezka-dereva/',
  'ремонт ванной': '/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/',
  самара: '/shop/',
  самовывоз: '/service/punkty-vydachi-zakazov/',
  сантехника: '/catalogue/santehnika/',
  саратов: '/shop/',
  сборка: '/uslugi/',
  'сборка душевой кабины': '/uslugi/ustanovka-dushevoy-kabiny/',
  'сборка кухни': '/proekt-kuhni/',
  'сборка мебели': '/uslugi/catalogue/sborka-mebeli/',
  'сборка москитной сетки': '/uslugi/montazh-okna/',
  'сборка шкафа': '/uslugi/catalogue/sborka-i-ustanovka-sistem-hraneniya/',
  'сервисная карта': '/kluch-karta-dom/',
  скидки: '/programmy-loyalnosti/',
  'стекло резка': '/uslugi/rezka-stekla/',
  тольятти: '/shop/',
  тюмень: '/shop/',
  укладка: '/uslugi/',
  'укладка кварцвинила': '/uslugi/ukladka-plitki-pvh/',
  'укладка керамогранита': '/uslugi/ukladka-keramogranita/',
  'укладка ламината': '/uslugi/ukladka-laminata/',
  'укладка линолеума': '/uslugi/ukladka-linoleuma-na-kley/',
  'укладка линолиума': '/uslugi/ukladka-linoleuma-na-kley/',
  'укладка плитки': '/uslugi/catalogue/ukladka-plitki/',
  'укладка плитки услуга': '/uslugi/catalogue/ukladka-plitki/',
  услуга: '/uslugi/',
  'услуга колеровки': '/uslugi/kolerovka/',
  'услуга распил': '/uslugi/rezka-dereva/',
  'услуга распила': '/uslugi/rezka-dereva/',
  услуги: '/uslugi/',
  'услуги мастера': '/uslugi/',
  'услуги плиточника': '/uslugi/catalogue/ukladka-plitki/',
  'услуги по распилу': '/uslugi/rezka-dereva/',
  'услуги по ремонту': '/uslugi/',
  'услуги распила': '/uslugi/rezka-dereva/',
  'услуги резки': '/uslugi/rezka-dereva/',
  'услуги сантехника': '/uslugi/catalogue/ustanovka-santehniki/',
  'услуги электрика': '/uslugi/gotovye-resheniya-pod-klyuch/sistemy-elektrosnabzheniya-pod-klyuch/',
  установка: '/uslugi/',
  'установка бойлера': '/uslugi/ustanovka-vodonagrevatelya/',
  'установка ванной': '/uslugi/ustanovka-vanny/',
  'установка ванны': '/uslugi/ustanovka-vanny/',
  'установка водонагревателя': '/uslugi/ustanovka-vodonagrevatelya/',
  'установка входной двери': '/uslugi/catalogue/ustanovka-metallicheskoy-dveri/',
  'установка входных дверей': '/uslugi/catalogue/ustanovka-metallicheskoy-dveri/',
  'установка вытяжки': '/uslugi/ustanovka-vytyazhki/',
  'установка дверей': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'установка двери': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'установка душевого уголка': '/uslugi/ustanovka-dushevoy-shirmy-i-ugolka/',
  'установка душевой': '/uslugi/ustanovka-dushevoy-kabiny/',
  'установка душевой кабины': '/uslugi/ustanovka-dushevoy-kabiny/',
  'установка жалюзи': '/uslugi/ustanovka-zhalyuzi/',
  'установка забора': '/uslugi/gotovye-resheniya-pod-klyuch/zabory-na-zakaz/#consultation',
  'установка зеркала': '/uslugi/ustanovka-mebeli-i-aksessuarov-dlya-vannoy-komnaty/',
  'установка и ремонт': '/uslugi/',
  'установка инсталяции': '/uslugi/ustanovka-podvesnogo-unitaza-i-ramy-installyacii/',
  'установка карниза': '/uslugi/ustanovka-karniza/',
  'установка конди': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка кондиционера': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка кондиционеров': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка кухни': '/proekt-kuhni/',
  'установка ламината': '/uslugi/ukladka-laminata/',
  'установка люверсов': '/uslugi/poshiv-shtor/',
  'установка люстры': '/uslugi/catalogue/ustanovka-osveshcheniya-v-pomeshcheniyah/',
  'установка межкомнатной двери': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'установка межкомнатных дверей': '/uslugi/catalogue/ustanovka-mezhkomnatnyh-dverey/',
  'установка окна': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'установка окон': '/uslugi/catalogue/ustanovka-balkonnoy-dveri-i-okna/',
  'установка плинтуса': '/uslugi/ustanovka-plintusa/',
  'установка плинтусов': '/uslugi/ustanovka-plintusa/',
  'установка радиатора': '/uslugi/catalogue/ustanovka-radiatorov/',
  'установка радиаторов': '/uslugi/catalogue/ustanovka-radiatorov/',
  'установка раковины': '/uslugi/ustanovka-rakoviny/',
  'установка рулонных штор': '/uslugi/ustanovka-rulonnoy-shtory/',
  'установка сантехники': '/uslugi/catalogue/ustanovka-santehniki/',
  'установка свай': '/uslugi/gotovye-resheniya-pod-klyuch/fundamenty-na-zakaz/',
  'установка смесителя': '/uslugi/ustanovka-smesitelya-gigienicheskogo-dusha/',
  'установка сплит': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка сплит систем': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка сплит системы': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка сплитсистемы': '/uslugi/catalogue/ustanovka-kondicionerov/',
  'установка стиральной машины': '/uslugi/podklyuchenie-i-ustanovka-stiralnyh-mashin/',
  'установка столешницы': '/uslugi/ustanovka-stoleshnic-na-kuhne/',
  'установка унитаза': '/uslugi/ustanovka-napolnogo-unitaza/',
  'установка фильтра': '/uslugi/catalogue/ustanovka-filtrov-dlya-vody/',
  уфа: '/shop/',
  'фанера распил': '/uslugi/rezka-dereva/',
  'фильтрация воды':
    '/uslugi/gotovye-resheniya-pod-klyuch/sistemy-filtracii-vody-dlya-zagorodnogo-doma-pod-klyuch/',
  фундамент: '/uslugi/gotovye-resheniya-pod-klyuch/fundamenty-na-zakaz/',
  'фундамент на винтовых сваях': '/uslugi/gotovye-resheniya-pod-klyuch/fundamenty-na-zakaz/',
  хабаровск: '/shop/',
  'шкаф на заказ': '/uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/',
};
