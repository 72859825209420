import React, { forwardRef } from 'react';
import { BaseButton, Typography } from 'fronton-react';
import { MagnifyingGlassIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';

import { Suggestion } from 'types/suggestions';
import { ClearButton } from './clear-button';
import {
  pseudoInputContent,
  pseudoInput,
  pseudoInputIcon,
  pseudoInputClear,
  textWrap,
  pseudoInputContainer,
} from './styles';

export interface PseudoInputProps {
  expanded: boolean;
  onClick: () => void;
  onClearClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  selectedItem: NonNullable<Suggestion> | null;
  value: string;
  placeholder: string;
}

export const PSEUDO_INPUT_CLEAR_QA = 'pseudo-input-clear-button';

export const PseudoInput = forwardRef<HTMLButtonElement, PseudoInputProps>(
  (props: PseudoInputProps, innerRef) => {
    const { expanded, onClick, onClearClick, selectedItem, value, placeholder } = props;

    const { t } = useTranslation();

    const text = selectedItem?.phrase || value || placeholder || t('header.searchbar.search');

    return (
      <div className={pseudoInputContainer}>
        <BaseButton
          ref={innerRef}
          type="button"
          className={pseudoInput}
          aria-label={t('header.searchbar.start-products-search')}
          aria-hidden={expanded}
          data-qa-header-open-search-button=""
          data-qa="search-button"
          onMouseDown={onClick}
        >
          <span className={pseudoInputIcon}>
            <MagnifyingGlassIcon color="text-primary" />
          </span>
          <div className={textWrap}>
            <Typography color="text-primary" variant="m" size="body_long" className={pseudoInputContent}>
              {text}
            </Typography>
          </div>
        </BaseButton>

        {value && (
          <ClearButton
            onClick={(e) => onClearClick(e)}
            selectedItem={selectedItem}
            value={value}
            classNames={pseudoInputClear}
            data-qa={PSEUDO_INPUT_CLEAR_QA}
          />
        )}
      </div>
    );
  },
);
