import { HFModelData } from 'types/model';
import { Store } from '../store/types';
import { EXECUTE_LOGOUT } from '../actions';
import { logout } from '../api';

export const getLogoutUrl = (model: HFModelData | null) => model?.header?.logoutUrl || '';

export const auth = (store: Store) => {
  store.on(EXECUTE_LOGOUT.request, async () => {
    const { requestID, model } = store.get();

    if (model) {
      const logoutUrl = getLogoutUrl(model);
      await logout(
        {
          orchestratorHost: logoutUrl,
          requestID,
          useCredentials: true,
        },
        {
          postLogoutRedirectUri: logoutUrl,
        },
      );
      store.dispatch(EXECUTE_LOGOUT.success);
    }
  });

  store.on(EXECUTE_LOGOUT.success, () => {
    const { model } = store.get();
    if (model) {
      window.location.href = model?.header?.home || '';
    }
  });
};
