import React, { memo } from 'react';
import { css } from 'linaria';

export const MobileAppLogo = memo(() => (
  <div className={logoAppWrap}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="white" />
      <path
        d="M25.1236 10.5684L19.9981 15.6364L24.7258 20.311V26.1448H15.2703V32.3894H31.9991V17.3665L25.1236 10.5684Z"
        fill="#BF8900"
      />
      <path
        d="M20.0011 5.5L8 17.3663V32.3889H15.2735V26.1443V20.3106L20.0011 15.6359L25.1267 10.5681L20.0011 5.5Z"
        fill="#FDC300"
      />
      <path d="M19.9998 15.6364L25.1253 10.5684L20.9492 16.5752L19.9998 15.6364Z" fill="#815F00" />
      <path d="M15.2703 26.1426V32.3872L16.6291 26.1426H15.2703Z" fill="#815F00" />
    </svg>
  </div>
));

export const logoAppWrap = css`
  border-radius: var(--rounding-l);
  box-shadow: var(--box-shadow-m);
`;
