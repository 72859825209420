export const NEED_LOG_FLAG = 'rrLog';
const gaBasketAddEvent = 'GA:addToCart';
const rrSearchEvent = 'rr:search';

type GABasketEvent = CustomEvent<{
  productId: string;
}>;

type RRSearchEvent = CustomEvent<{
  phrase: string;
}>;

/**
 * @description Класс для работы с событиями RetailRocket
 */
class RetailRocketHelper {
  needLog: boolean;

  constructor() {
    this.needLog = __IS_SERVER__ ? false : Boolean(localStorage.getItem(NEED_LOG_FLAG));
    if (!__IS_SERVER__) {
      this.listenAddToCard();
      this.listenSearch();
    }
  }

  log = (message: string) => {
    if (this.needLog) {
      // eslint-disable-next-line no-console
      console.log('RR', message);
    }
  };

  getIsCookieAccepted = () => document.cookie.includes('cookie_accepted=true');

  dispatchSetAcceptedCookie = () => {
    document.dispatchEvent(new CustomEvent('rr:cookie_accepted'));
    this.log('cookie_accepted');
  };

  getStockID = () => {
    const matchRegion = document.cookie.match(/(_regionID=)(\d*)(;?)/);
    return matchRegion && matchRegion?.length >= 3 ? matchRegion[2] : '';
  };

  dispatchAddToCard = (event: GABasketEvent) => {
    const {
      detail: { productId },
    } = event;

    const stockId = this.getStockID();

    this.log(`stockId: ${stockId}, productId: ${productId}`);

    this.pushEvent(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rrApi.addToBasket(productId, { stockId });
      } catch (e) {
        this.log(`rrApi addToBasket error`);
      }
    });
  };

  listenAddToCard = () => {
    document.addEventListener(gaBasketAddEvent, this.dispatchAddToCard as EventListener, { passive: true });
  };

  pushEvent = (rrEvent: unknown) => {
    (window.rrApiOnReady = window?.rrApiOnReady || []).push(rrEvent);
  };

  dispatchSearchEvent = (phrase: string) => {
    this.log(`rr search phrase ${phrase}`);
    this.pushEvent(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rrApi.search(phrase);
      } catch (e) {
        this.log(`rrApi search error`);
      }
    });
  };

  /**
   * Для обработки поиска на главной
   * document.dispatchEvent('rr:search', { phrase: 'поисковая фраза' })
   */
  listenSearch = () => {
    document.addEventListener(rrSearchEvent, (event) => {
      const {
        detail: { phrase },
      } = event as RRSearchEvent;
      this.dispatchSearchEvent(phrase);
    });
  };
}

export const rrHelper = new RetailRocketHelper();

if (!__IS_SERVER__) {
  window.LMRR = window.LMRR || { rrHelper };
}
