import React from 'react';
import clsx from 'clsx';
import { akinatorItemsWrap, akinatorWrap } from './styles';
import { useSearchHistoryContext } from '../../hooks';
import { AkinatorHistoryButton } from './akinator-history-button';
import { AkinatorClearHistoryButton } from './akinator-clear-history-button';

export const Akinator: React.FC = () => {
  const { history } = useSearchHistoryContext();

  if (!history.length) return null;

  return (
    <div className={akinatorWrap} data-qa="akinator">
      <div className={clsx(akinatorItemsWrap)}>
        {history.map((i, index) => (
          <AkinatorHistoryButton item={i} index={index} key={`${i.title}_${i.url}`} />
        ))}
        <AkinatorClearHistoryButton />
      </div>
    </div>
  );
};
