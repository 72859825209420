import { useCallback, useState } from 'react';
import { KV } from '../../types/utils';
import { useActualRef } from './use-actual-ref';
import { useUpdateEffect } from './index';

export interface UseKeyboardNavigationParams {
  defaultSelected?: number;
  canUnselectAllItems?: boolean;
}

const keys: KV<number> = {
  ArrowUp: -1,
  ArrowDown: 1,
};

export function useKeyboardNavigation<T>(items: T[] | null, params: UseKeyboardNavigationParams = {}) {
  const { canUnselectAllItems = false, defaultSelected = -1 } = params;
  const itemsRef = useActualRef(items);
  const [index, setIndex] = useState(defaultSelected);

  const onMouseEnter = useCallback((nextIndex) => setIndex(nextIndex), []);
  const onMouseLeave = useCallback(() => setIndex(-1), []);
  const reset = useCallback(() => setIndex(-1), []);

  useUpdateEffect(() => {
    const maxIndex = (items?.length ?? 0) - 1;
    setIndex((currentIndex) => (maxIndex < currentIndex ? maxIndex : currentIndex));
  }, [items]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      e.persist();
      setIndex((currentIndex) => {
        if (!itemsRef.current) return currentIndex;

        const maxIndex = itemsRef.current.length - 1;
        const step = keys[e.key];

        if (!step) return currentIndex;
        e.preventDefault();
        e.stopPropagation();

        return getNextIndex(canUnselectAllItems ? -1 : 0, currentIndex, step, maxIndex);
      });
    },
    [itemsRef, canUnselectAllItems],
  );

  const selectItem = useCallback(
    (nextIndex: number) => {
      if (!itemsRef.current) return;
      if (nextIndex < 0 || nextIndex >= itemsRef.current.length) return;

      setIndex(nextIndex);
    },
    [itemsRef],
  );

  const selectedItem = itemsRef.current?.[index] ?? null;

  return {
    index,
    reset,
    selectedItem,
    selectItem,
    onMouseEnter,
    onMouseLeave,
    onKeyDown,
  } as const;
}

function getNextIndex(min: number, current: number, step: number, max: number) {
  const maybeNextIndex = current + step;

  if (maybeNextIndex < min) {
    return max;
  }

  if (maybeNextIndex > max) {
    return min;
  }

  return maybeNextIndex;
}
