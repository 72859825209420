import { css } from 'linaria';

export const wrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: var(--space-400);
`;

export const statusBlock = css`
  display: flex;
  align-items: center;
  column-gap: var(--space-200);
  margin-top: var(--space-200);
`;

export const descriptionBlock = css`
  padding-left: var(--space-600);
`;
