import { css } from 'linaria';

export const searchRoot = css`
  padding-bottom: var(--space-200);
`;

export const form = css`
  position: relative;
`;

export const clearButton = css`
  position: absolute !important;
  right: var(--space-100);
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const searchInput = css`
  display: flex !important; /** fix fronton build bug */

  & input {
    padding-left: var(--space-100);
  }

  & > button {
    opacity: 0;
  }
`;
