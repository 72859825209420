import { useEffect, useState } from 'react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { OrderStatusError } from 'types/order-status';

export const useInfoBars = () => {
  const { orderStatus } = useStoreon<State>('orderStatus');

  const [isShowErrorInfoBar, setIsShowErrorInfoBar] = useState(false);
  const { error } = orderStatus;

  const closeErrorInfoBar = () => {
    setIsShowErrorInfoBar(false);
  };

  useEffect(() => {
    if (error === OrderStatusError.NOT_FOUND) {
      setIsShowErrorInfoBar(true);
    } else {
      closeErrorInfoBar();
    }
  }, [error]);

  return {
    isShowErrorInfoBar,
    closeErrorInfoBar,
  };
};
