import React from 'react';
import { Typography, LinkButton, Divider } from 'fronton-react';
import clsx from 'clsx';
import { dividerStyle } from './styles';
import { SecondaryMenuItemProps } from './types';

export const SecondaryMenuItem = (
  props: SecondaryMenuItemProps & { iconWidth?: number; iconHeight?: number },
) => {
  const {
    title,
    divider,
    iconHref,
    KindAnchor,
    className,
    iconWidth = 24,
    iconHeight = 24,
    ...rest
  } = props;

  return (
    <>
      {(divider === 'top' || divider === 'both') && (
        <Divider orientation="horizontal" variant="line" className={clsx(dividerStyle, 'marginBottom')} />
      )}
      <LinkButton as="a" target={KindAnchor.target} href={KindAnchor.href} className={className} {...rest}>
        <img src={iconHref} width={iconWidth} height={iconHeight} alt={KindAnchor.text} />
        <Typography variant="m" size="body_long">
          {KindAnchor.text}
        </Typography>
      </LinkButton>
      {(divider === 'bottom' || divider === 'both') && (
        <Divider orientation="horizontal" variant="line" className={clsx(dividerStyle, 'marginTop')} />
      )}
    </>
  );
};
