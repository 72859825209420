import React from 'react';
import { LinkButton } from 'fronton-react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
};

export const CloseButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <LinkButton
      form="headersearchform"
      size="m"
      data-qa-header-search-clear=""
      data-qa="close-button"
      variant="normal"
      onClick={onClick}
    >
      {t('ui.modal.close')}
    </LinkButton>
  );
};
