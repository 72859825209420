import React from 'react';
import { RegularButton } from 'fronton-react';
import { ClockCounterClockwiseIcon } from '@fronton/icons-react';
import { akinatorButton, akinatorButtonText } from './styles';
import { SearchHistoryItem, useSearchHistoryContext } from '../../hooks/use-search-history-context';
import { useAkinatorAnalyticsEvent } from './hooks/use-akinator-analytics-event';

type Props = {
  item: SearchHistoryItem;
  index: number;
};

export const AkinatorHistoryButton: React.FC<Props> = ({ item, index }) => {
  const { addHistoryItem } = useSearchHistoryContext();
  const sendAnalytics = useAkinatorAnalyticsEvent(`История/${index}-${item.title}`, '');

  return (
    <RegularButton
      key={`${item.title}_${item.url}`}
      variant="secondary"
      size="s"
      href={item.url}
      className={akinatorButton}
      iconLeft={<ClockCounterClockwiseIcon />}
      onClick={() => {
        addHistoryItem(item);
        sendAnalytics();
      }}
    >
      <span className={akinatorButtonText}>{item.title}</span>
    </RegularButton>
  );
};
