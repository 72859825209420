import React from 'react';
import { Region } from 'types/model';
import { useGetRegionLink } from 'components/header/hooks';
import { RegionLink } from './region-link';

export interface RegionsListProps {
  regions: Region[];
}

export const RegionsList = ({ regions }: RegionsListProps) => {
  const getRegionLink = useGetRegionLink();

  return (
    <>
      {regions.map((region) => (
        <RegionLink key={region.regionId} region={region} getRegionLink={getRegionLink} />
      ))}
    </>
  );
};
