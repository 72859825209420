import { MutableRefObject, useRef } from 'react';
import { useIsomorphicLayoutEffect } from './index';

/**
 * Хук создает Ref, которая всегда указывает на актуальное значение
 * @param {T} value
 */
export function useActualRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef<T>(value);

  useIsomorphicLayoutEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}
