import { analytics } from 'utils/analytics';

export function sendGaSubscribedEvent() {
  analytics.sendEvent('GA:show', {
    elementType: 'block',
    elementBlock: 'footer', // блок, в котором расположен элемент
    elementName: 'subscription', // условное обозначение
    elementText: 'Благодарим Вас за подписку на рассылку!',
  });
}

export const onBasketClickCb = () => {
  analytics.sendEvent('GA:click', {
    name: 'basket', // название элемента
    position: 'header', // блок, в котором расположен элемент
  });
};
