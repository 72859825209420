export class ErrorWithStatus extends Error {
  status: number | 'other';

  message: string;

  constructor(message = '', status: number | 'other' = 'other') {
    super(message);
    this.status = status;
    this.message = message;
  }
}
