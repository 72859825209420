import React from 'react';
import { Typography, RegularButton } from 'fronton-react';
import clsx from 'clsx';
import { ServiceSuggestion } from 'types/suggestions';
import { capitalize } from 'utils/capitalize';
import { useTranslation } from 'react-i18next';
import { patchUrlByLocale } from 'utils';
import { useSuggestionsButtonAction } from '../../hooks/use-suggestions-button-action';
import {
  suggestion,
  suggestionButton,
  suggestionText,
  suggestionCaption,
  servicesSuggestionIcon,
  suggestionContentWrap,
  suggestionIconWrap,
  suggestionTextContentWrap,
} from './suggestion-list-item-styles';
import { SuggestionListItemServicesIcon } from './suggestion-list-item-services-icon';

export interface SuggestionListItemServicesProps {
  servicesSuggestion: ServiceSuggestion;
  elementIndex: number;
  navigationIndex: number;
  isSrp: boolean;
  suggestionSelector: string;
  buttonAction: ReturnType<typeof useSuggestionsButtonAction>;
}

export const SuggestionListItemServices = (props: SuggestionListItemServicesProps) => {
  const { servicesSuggestion, elementIndex, navigationIndex, isSrp, buttonAction, suggestionSelector } =
    props;

  const { t } = useTranslation();

  const { serviceId, serviceName, categoryUrl, categoryName } = servicesSuggestion;

  const buttonParams = {
    ...buttonAction(
      elementIndex,
      categoryUrl,
      {
        block: t('header.searchbar.services'),
        index: elementIndex,
        text: serviceName,
      },
      capitalize(serviceName),
      categoryUrl,
    ),
  };

  const caption = categoryName
    ? `${t('header.searchbar.service')} | ${capitalize(categoryName)}`
    : t('header.searchbar.service');

  return (
    <div
      key={serviceId}
      role="option"
      id={`${serviceId}`}
      aria-selected={elementIndex === navigationIndex}
      data-qa-header-search-suggestion=""
    >
      <RegularButton
        variant="pseudo"
        tabIndex={-1}
        className={clsx(suggestionSelector, suggestion, {
          active: elementIndex === navigationIndex,
          [suggestionButton]: isSrp,
        })}
        key={serviceId}
        data-qa="suggestion-button"
        {...buttonParams}
        href={patchUrlByLocale({ url: categoryUrl })}
      >
        <div className={suggestionContentWrap}>
          <div className={clsx(suggestionIconWrap, servicesSuggestionIcon)}>
            <SuggestionListItemServicesIcon />
          </div>
          <div className={suggestionTextContentWrap}>
            <Typography variant="m" size="body_short" className={suggestionText} color="text-primary">
              {capitalize(serviceName)}
            </Typography>
            <Typography variant="caption" color="text-secondary" className={suggestionCaption}>
              {caption}
            </Typography>
          </div>
        </div>
      </RegularButton>
    </div>
  );
};
