import { Suggestion } from 'types/suggestions';
import { patchUrlByLocale } from 'utils';
import { capitalize } from 'utils/capitalize';

export const getHistoryItemTitle = (value: string, suggestion: Suggestion | null) => {
  if (!suggestion) {
    return value;
  }
  if (suggestion.categoryUrl && suggestion.serviceName) {
    return capitalize(suggestion.serviceName);
  }
  return suggestion.phrase ? capitalize(suggestion.phrase) : value;
};

export const getHistoryItemUrl = (sanitizedValue: string, suggestion: Suggestion | null) => {
  if (!suggestion) {
    return patchUrlByLocale({ url: `/search/?q=${sanitizedValue}` });
  }
  if (suggestion.categoryUrl && suggestion.serviceName) {
    return patchUrlByLocale({ url: suggestion.categoryUrl });
  }
  return patchUrlByLocale({ url: `/search/?q=${sanitizedValue}&suggest=true` });
};
