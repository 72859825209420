export const HAMBURGER_MENU = {
  openDrawer: 'hamburgerMenu/OPEN',
  closeDrawer: 'hamburgerMenu/CLOSE',
} as const;

export const CITY_PICKER_MENU = {
  openDrawer: 'cityPickerMenu/OPEN',
  closeDrawer: 'cityPickerMenu/CLOSE',
} as const;

export type HamburgerMenuEvents = {
  'cityPickerMenu/OPEN': boolean;
  'cityPickerMenu/CLOSE': boolean;
};

export type CityPickerEvents = {
  'cityPickerMenu/OPEN': boolean;
  'cityPickerMenu/CLOSE': boolean;
};
