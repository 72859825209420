import { DeviceOS, DeviceType, OnelinkType } from './types';

export const ONELINK_URL_PATH_BY_DEVICE: OnelinkType = {
  android: {
    mobile: 'https://leroymerlinru.onelink.me/l7Az/hoozp2r5',
    desktop: 'https://leroymerlinru.onelink.me/l7Az/hoozp2r5',
  },
  os: {
    mobile: 'https://leroymerlinru.onelink.me/FDRm/s4e50kre',
    desktop: 'https://leroymerlinru.onelink.me/FDRm/s4e50kre',
  },
  huawei: {
    mobile: 'https://leroymerlinru.onelink.me/l7Az/zpt2kiv2',
    desktop: 'https://leroymerlinru.onelink.me/l7Az/zpt2kiv2',
  },
};

const UTM_LABELS_MAP: Record<string, string> = {
  utm_source: 'pid',
  utm_medium: 'af_channel',
  utm_campaign: 'c',
  utm_term: 'af_adset',
  utm_content: 'af_ad',
};

export const createOpenlinkUrl = (os: DeviceOS, src: DeviceType, utmLabels: string[][] = [[]]) => {
  const searchParams =
    utmLabels.length && utmLabels[0].length
      ? utmLabels.reduce((result, [key, value]) => {
          result.append(UTM_LABELS_MAP[key], value);
          return result;
        }, new URLSearchParams())
      : undefined;
  return `${ONELINK_URL_PATH_BY_DEVICE[os][src]}${searchParams ? `?${searchParams.toString()}` : ''}`;
};

export const filterSearchParamsByUtm = (params: string[][]) =>
  params.filter(([key]) => Object.keys(UTM_LABELS_MAP).includes(key));
