import React from 'react';
import { Badge } from 'fronton-react';
import { onBasketClickCb } from 'utils/ga-events';
import { useBasketApi, useMobileMenuAnalyticsEvent } from 'components/header/hooks';
import { MobileMenuLink, MobileMenuLinkProps } from './mobile-menu-link';
import { topBadge } from './styles';

export const BasketMenuLink = (props: MobileMenuLinkProps) => {
  const { basketCount } = useBasketApi();
  const onBasketLinkClick = useMobileMenuAnalyticsEvent('Корзина');

  const onClick = () => {
    onBasketClickCb();
    onBasketLinkClick();
  };

  return (
    <MobileMenuLink
      {...props}
      onClick={onClick}
      topBadge={
        basketCount > 0 ? (
          <Badge size="s" data-qa="header-basket-count" value={basketCount} className={topBadge} />
        ) : undefined
      }
    />
  );
};
