import { HeaderModelData } from 'types/model';
import { getLogo } from 'utils/get-logo';

export interface UseLogoReturnType {
  home: string;
  logoUrl: string;
}

export const useLogo = (model: HeaderModelData): UseLogoReturnType => {
  const { logoImage, home } = model.header;
  return { logoUrl: getLogo(logoImage), home };
};
