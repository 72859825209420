import { css } from 'linaria';

export const akinatorWrap = css`
  display: block;
  width: 100%;
  padding: var(--space-75) var(--space-200) !important;
  overflow-y: hidden;
  overflow-x: scroll;
  max-height: 84px;
  height: fit-content;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (--from-desktop) {
    overflow: hidden;
  }
`;

export const akinatorItemsWrap = css`
  display: flex;
  position: relative;
  width: max-content;
  min-width: 100%;
  max-width: 200dvw;
  height: fit-content;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--space-100);
  padding-right: 108px;

  @media (--from-desktop) {
    width: 100%;
    max-width: 100%;
    max-height: 72px;
  }
`;

export const akinatorButton = css`
  flex: 0 0 auto;
  max-width: 190px;

  @media (--only-desktop) {
    max-width: 160px;
  }
`;

export const akinatorButtonText = css`
  display: inline !important;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const expandButton = css`
  position: absolute !important;
  right: 0 !important;
  padding: 6px !important;
  border: none !important;
`;
