import { formatDayAndMonth } from 'utils/date-time-formatter';

export const getOrderTitle = (createdDate: string) => {
  let orderStr = 'Заказ';
  if (!createdDate) {
    return orderStr;
  }

  const formattedCreationDate = formatDayAndMonth(new Date(createdDate));

  return `${orderStr} от ${formattedCreationDate}`;
};
