import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSuggestionsButtonAction } from '../../hooks/use-suggestions-button-action';
import { Akinator } from '../akinator';
import { SuggestionListItemServicesPage } from './suggestion-list-item-services-page';
import { SuggestionListItemPromoPage } from './suggestion-list-item-promo-page';
import { SuggestionsList } from './suggestions-list';

export type SuggestionsDefaultListProps = {
  suggestionSelector: string;
  index: number;
  isSrp: boolean;
  showServices: boolean;
  buttonAction: ReturnType<typeof useSuggestionsButtonAction>;
};

export const SuggestionsDefaultListPinnedItems = memo<SuggestionsDefaultListProps>(
  ({ suggestionSelector, index, isSrp, showServices, buttonAction }) => {
    const { t } = useTranslation();
    return (
      <>
        <Akinator />
        <SuggestionsList
          suggestionSelector={suggestionSelector}
          withTitle={false}
          withDivider
          title={t('header.searchbar.top-month')}
          aria-label={t('header.searchbar.top-month')}
        >
          <SuggestionListItemPromoPage
            elementIndex={0}
            navigationIndex={index}
            isSrp={isSrp}
            suggestionSelector={suggestionSelector}
            buttonAction={buttonAction}
          />
        </SuggestionsList>
        {showServices && (
          <SuggestionsList
            suggestionSelector={suggestionSelector}
            withTitle={false}
            withDivider
            title={t('header.searchbar.projects-services')}
            aria-label={t('header.searchbar.projects-services')}
          >
            <SuggestionListItemServicesPage
              elementIndex={1}
              navigationIndex={index}
              isSrp={isSrp}
              suggestionSelector={suggestionSelector}
              buttonAction={buttonAction}
            />
          </SuggestionsList>
        )}
      </>
    );
  },
);
