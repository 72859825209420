import React from 'react';
import { withAuth, WithAuthProps } from 'components/header/hoc/with-auth';
import { useOrderStatusModal } from 'components/order-status/hooks';
import { HeaderModelData } from 'types/model';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { getIsB2BEnabled, isUserAuth } from 'selectors';
import { useAuthLogic } from 'components/header/hooks/auth';
import { B2B_PERSONAL_ACCOUNT_HOST } from 'common/constants/header-constants';
import { useOrderStatusOn } from 'components/header/hooks';
import { SignIn, User } from './components';

export interface WithLoginB2B {
  loginB2B: () => void;
}

export interface ProfileBaseProps extends WithAuthProps, WithLoginB2B {
  model: HeaderModelData;
}

export const ProfileBase = (props: ProfileBaseProps) => {
  const { model, ...authProps } = props;
  const { login, loginB2B } = authProps;
  const loginPageUrl = model.header.initUrl;
  const { cookies } = useStoreon<State>('cookies');
  const { openOrderStatusModal } = useOrderStatusModal();
  const isAuth = isUserAuth(cookies);
  const isOrderStatusOn = useOrderStatusOn(model);
  const isB2BEnabled = getIsB2BEnabled(model.experiments);

  return (
    <>
      {isAuth ? (
        <User model={model} {...authProps} />
      ) : (
        <SignIn
          b2bLoginPageUrl={B2B_PERSONAL_ACCOUNT_HOST}
          login={login}
          loginPageUrl={loginPageUrl}
          loginB2B={loginB2B}
          onOrderStatusModalOpen={openOrderStatusModal}
          isOrderStatusOn={isOrderStatusOn}
          isB2BEnabled={isB2BEnabled}
        />
      )}
    </>
  );
};

export const Profile = withAuth<ProfileBaseProps, WithAuthProps & WithLoginB2B>(ProfileBase, useAuthLogic);
