import { useCallback } from 'react';
import { isTouchScreen, analytics } from 'utils';
import { SuggestionsGaMetaInfo } from 'components/header/components/searchbar/types';
import { useSearchHistoryContext } from './use-search-history-context';

export interface UseButtonActionParams {
  searchQuery: string;
  onMouseEnter: (idx: number) => void;
  onMouseLeave: () => void;
  onSelectSuggestion?: () => void;
}

export const useSuggestionsButtonAction = (params: UseButtonActionParams) => {
  const { onMouseEnter, onMouseLeave, onSelectSuggestion, searchQuery } = params;
  const { addHistoryItem } = useSearchHistoryContext();

  return useCallback(
    (
      id: number,
      linkToNavigate?: string,
      gaMeta?: SuggestionsGaMetaInfo,
      historyTitle?: string,
      historyUrl?: string,
    ) => {
      const onSelectSuggestionCb = () => {
        const elementText = `${gaMeta?.block}/${gaMeta?.index}-${gaMeta?.text}`;
        analytics.sendEvent('GA:clickButton', {
          elementType: 'button',
          elementBlock: 'header', // блок, в котором расположен элемент
          elementName: 'searchHint', // условное обозначение
          elementText,
          eventVariant: searchQuery,
        });

        if (historyTitle && historyUrl) {
          addHistoryItem({
            title: historyTitle,
            url: historyUrl,
          });
        }

        onSelectSuggestion?.();
        if (linkToNavigate) {
          // `location.assign` used instead of declarative `Link` component
          // because markup can be changed after `onSelectSuggestion` call
          // (e.g. popup closing),
          // so `Link` component will not dispatch event
          window.location.assign(linkToNavigate);
        }
      };
      return isTouchScreen()
        ? { onTouchEnd: onSelectSuggestionCb }
        : { onMouseEnter: () => onMouseEnter(id), onClick: onSelectSuggestionCb, onMouseLeave };
    },
    [onMouseLeave, searchQuery, onSelectSuggestion, addHistoryItem, onMouseEnter],
  );
};
