import React from 'react';
import { useAuthLogic } from '../hooks/auth';

export interface WithAuthProps {
  login: () => void;
  logout: () => void;
  closeAuthPopup: () => void;
}

export type AuthLogicFunction = typeof useAuthLogic;

export function withAuth<T extends WithAuthProps = WithAuthProps, W extends WithAuthProps = WithAuthProps>(
  WrappedComponent: React.ComponentType<T>,
  authLogicFunction: AuthLogicFunction = useAuthLogic,
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithAuth = (props: Omit<T, keyof W>) => {
    const authProps = authLogicFunction();
    return <WrappedComponent {...authProps} {...(props as T)} />;
  };

  if (process.env.NODE_ENV !== 'production') {
    ComponentWithAuth.displayName = `withAuth(${displayName})`;
  }

  return ComponentWithAuth;
}
