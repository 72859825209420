export const DEFAULT_RU_REGION = 'moscow';
export const DEFAULT_KZ_REGION = 'kazakhstan';

export const RU_DOMAINS = [
  'adeo.com',
  'leroymerlin.ru',
  'localhost',
  'lmru.tech',
  'lemonlid.ru',
  'lemanapro.ru',
];
export const KZ_DOMAINS = ['lemanapro.kz', 'lmkz.tech', 'leroymerlin.kz'];

const isDomainValid = (domain: string, domains: string[]) =>
  domains.some((matchingDomain) => domain === matchingDomain);

export function getRegion(hostname: string, domain: string) {
  const allFsStands = new Array(20).map((_, index) => `fs${index}`);
  const defenetlyNotARegion = [
    ...allFsStands,
    'aem',
    'arulm',
    'author',
    'dispatcher',
    'internal',
    'lemanapro',
    'lemonlid',
    'leroymerlin',
    'localhost',
    'mf',
    'mf-capella-a',
    'mf-capella-a-stage',
    'mf-taurus-a',
    'publish',
    'triffle',
    'ux',
    'yandex',
  ];

  const isRuDomain = isDomainValid(domain, RU_DOMAINS);
  const isKZDomain = isDomainValid(domain, KZ_DOMAINS);

  if (isRuDomain || isKZDomain) {
    const [maybeRegion] = hostname.split('.');

    if (!defenetlyNotARegion.some((prefix) => maybeRegion.startsWith(prefix))) {
      return maybeRegion;
    }
  }

  if (isRuDomain) {
    return DEFAULT_RU_REGION;
  }

  if (isKZDomain) {
    return DEFAULT_KZ_REGION;
  }

  return '';
}

export const getShortenRegionName = (reg: string) => reg.replace(' область', ' обл.');
