import { useMobileAppLogic } from 'components/header/hooks';
import React from 'react';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';
import { HeaderModelData } from 'types/model';
import { MobileAppContent } from './mobile-app-content';

export interface MobileAppProps {
  model: HeaderModelData;
}

export const MobileApp = (props: MobileAppProps) => {
  const { model } = props;

  const { cookies, userAgent, dispatch } = useStoreon<State>('cookies', 'userAgent');

  const { showMobileBanner, hideMobileBanner, os } = useMobileAppLogic(
    userAgent,
    model?.mobileApp.daysToHide,
    cookies,
    dispatch,
  );

  return props.model.mobileApp ? (
    <MobileAppContent
      shouldDisplay={showMobileBanner}
      os={os}
      hide={hideMobileBanner}
      {...model.mobileApp}
    />
  ) : null;
};
