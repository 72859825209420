import React, { forwardRef, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { FocusOn } from 'react-focus-on';
import { DrawerProps, DrawerScrollContextProps } from './types';
// import styles from './styles.module.scss';
import {
  drawerTransitionEnter,
  drawerTransitionEnterActive,
  drawerTransitionExit,
  drawerTransitionExitActive,
  drawer,
  handler,
  modal,
  scrollWrapper,
  contentWrapper,
} from './styles';
import { ScrollContext } from './context';
import { mergeRefs, shallowObjectsEqual } from './utils';
import { FocusOnContext, useFocusOnIgnore, useWindowSize } from './hooks';

const drawerTransition = {
  enter: drawerTransitionEnter,
  enterActive: drawerTransitionEnterActive,
  exit: drawerTransitionExit,
  exitActive: drawerTransitionExitActive,
};

type Action = { type: 'newState'; payload: DrawerScrollContextProps };

export function reducer(state: DrawerScrollContextProps, action: Action) {
  if (action.type === 'newState') return action.payload;
  throw new Error();
}

/**
 * `<Drawer />` Элемент навигации для десктопа и мобильной версии
 *
 * @example ``` tsx
 * <Drawer
 *  onClose={() => changeShow(false)}
 *  show={true}
 * >
 *  Content
 * </Drawer>
 * ```
 */
export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    { children, onClose, onInnerScroll, show, className, overlayClose = true, breakpoint = 440, ...props },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const testId = props['data-qa'] || 'drawer';

    const overlayRef = useRef<HTMLDivElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [scrollState, scrollDispatch] = useReducer(reducer, { top: false, bottom: false });

    const [mobile, setMobile] = useState(false);

    const windowSize = useWindowSize();

    const { isTargetIgnored } = useFocusOnIgnore();

    useEffect(() => {
      const newState = windowSize.innerWidth <= breakpoint;

      if (mobile !== newState) {
        setMobile(newState);
      }
    }, [windowSize, mobile, breakpoint]);

    useEffect(() => {
      if (onInnerScroll) {
        onInnerScroll(scrollState?.top, scrollState?.bottom);
      }
    }, [onInnerScroll, scrollState]);

    const drawerClassName = useMemo(
      () =>
        clsx(drawer, 'transition-drawer', className, {
          mobile,
        }),
      [className, mobile],
    );

    const checkScroll = useCallback(() => {
      if (contentRef.current) {
        const top = contentRef.current.scrollTop > 0;
        const bottom =
          contentRef.current.offsetHeight + contentRef.current.scrollTop < contentRef.current.scrollHeight;

        const newState = { top, bottom };

        if (!shallowObjectsEqual(scrollState, newState)) {
          scrollDispatch({ type: 'newState', payload: newState as DrawerScrollContextProps });
        }
      }
    }, [scrollState]);

    const overlayCloseHandler = useCallback(
      (event: MouseEvent | TouchEvent) => {
        if (overlayClose && !isTargetIgnored(event.target)) {
          onClose();
        }
      },
      [isTargetIgnored, onClose, overlayClose],
    );
    return (
      <CSSTransition
        nodeRef={overlayRef}
        timeout={250}
        in={show}
        classNames={drawerTransition}
        unmountOnExit
      >
        <div className={drawerClassName} ref={overlayRef} data-qa={`${testId}-overlay`}>
          <FocusOn
            onClickOutside={overlayCloseHandler}
            onEscapeKey={onClose}
            className={handler}
            enabled={show}
            autoFocus={false}
          >
            <div
              className={clsx(modal, 'transition-modal')}
              ref={mergeRefs([ref, modalRef])}
              {...props}
              data-qa={`${testId}-modal`}
            >
              <ScrollContext.Provider value={scrollState}>
                <FocusOnContext.Provider value>
                  <div
                    className={scrollWrapper}
                    onScroll={checkScroll}
                    ref={contentRef}
                    data-qa={`${testId}-content-wrapper`}
                  >
                    <div className={contentWrapper}>{children}</div>
                  </div>
                </FocusOnContext.Provider>
              </ScrollContext.Provider>
            </div>
          </FocusOn>
        </div>
      </CSSTransition>
    );
  },
);
