import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Typography, LinkButton, Divider } from 'fronton-react';
import { KindMainNavigationItem } from 'types/model';
import { dividerStyle, linkSpace } from './styles';

export const PrimaryMenuItem = (props: KindMainNavigationItem & HTMLAttributes<HTMLElement>) => {
  const { title, description, divider, target, href, ...rest } = props;

  return (
    <>
      {(divider === 'top' || divider === 'both') && (
        <Divider orientation="horizontal" variant="line" className={clsx(dividerStyle, 'marginBottom')} />
      )}
      <LinkButton as="a" href={href} target={target} {...rest}>
        <div className={linkSpace}>
          <Typography as="div" color="text-primary" variant="h3">
            {title}
          </Typography>
          <Typography variant="caption" color="text-primary">
            {description}
          </Typography>
        </div>
      </LinkButton>
      {(divider === 'bottom' || divider === 'both') && (
        <Divider orientation="horizontal" variant="line" className={clsx(dividerStyle, 'marginTop')} />
      )}
    </>
  );
};
