import { useStoreon } from 'storeon/react';
import { getDatePlusYear, getDomain, getLangByPath } from 'utils';
import { CookiesEvents } from 'actions';
import { LangType, RegionType } from 'common/constants';

export const useLang = () => {
  const { dispatch } = useStoreon('cookies');
  const { pathname } = global?.location || { pathname: '' };
  const lang = getLangByPath(pathname);

  const changeLang = (
    region: RegionType,
    nextLang: LangType,
    changePath = false,
    cb?: (currentLang: RegionType, nextLang: LangType, changePath: boolean) => void,
  ) => {
    dispatch(CookiesEvents.update, {
      key: 'lang',
      value: nextLang,
      params: {
        path: '/',
        domain: `.${getDomain(window.location.hostname)}`,
        expires: getDatePlusYear(),
      },
    });
    cb?.(region, nextLang, changePath);
  };

  return {
    lang,
    changeLang,
  };
};
