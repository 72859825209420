export const composeURLWithQueries = (
  currentPageLink: string,
  currentSearchParams: string,
  regionsModalIsOpen: boolean,
  currentRegionId?: string,
) => {
  const url = new URL(currentPageLink);
  const currentSearch = new URLSearchParams(currentSearchParams);

  currentSearch.forEach((val, key) => url.searchParams.set(key, val));

  if (currentRegionId && regionsModalIsOpen) {
    url.searchParams.set('fromRegion', currentRegionId);
  }

  return url;
};
