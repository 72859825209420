import React from 'react';
import { Button, Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { errorContainer, errorTitle } from './styles';

type ErrorProps = { fetchSuggestions: () => void };

export const Error: React.FC<ErrorProps> = ({ fetchSuggestions }) => {
  const { t } = useTranslation();
  return (
    <div className={errorContainer} data-qa-header-search-error="">
      <Typography className={errorTitle} variant="s" size="body_short" data-qa-header-search-error-title="">
        {t('ui.catalogue-control.error-first')} <br /> {t('ui.catalogue-control.error-second')}
      </Typography>

      <Button
        kind="regular"
        variant="primary"
        size="s"
        onClick={fetchSuggestions}
        data-qa-header-search-error-retry=""
      >
        {t('ui.catalogue-control.repeat')}
      </Button>
    </div>
  );
};
