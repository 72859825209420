import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Skip first effect (which is right after mount)
 * @internal
 */
export const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current) {
      effect();
    }

    isInitialRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
