import React from 'react';
import { Icon } from 'fronton-react';
import { MobileAppLogo } from 'components/icons';
import { logoWrap } from './styles';

export const Logo: React.FC = () => (
  <Icon className={logoWrap} data-qa-header-mobile-app-logo="">
    <MobileAppLogo />
  </Icon>
);
