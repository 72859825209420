import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const mobileApp = css`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: var(--space-100) var(--space-200);
  background-color: var(--background-secondary);

  @media (--from-tablet) {
    display: none;
  }
`;

export const logoTextWrap = css`
  display: flex;
  gap: var(--space-150);

  div {
    width: 40px !important;
  }
`;

export const installCloseWrap = css`
  display: flex;
  margin: auto 0 !important;
  align-items: center;
  gap: var(--space-200);

  ${breakpoints.only.smMobile} {
    gap: var(--space-100);
  }
`;

export const close = css`
  width: 16px;
  height: 16px;
  color: var(--text-primary);
  opacity: 0.4 !important;
  border: none;
`;

export const logoWrap = css`
  height: 40px;
`;

export const description = css`
  margin: auto 0 !important;
  letter-spacing: 0.2px;
  color: var(--text-primary);
`;

export const install = css`
  width: 102px;
  height: 32px;
  align-self: center;
  justify-self: end;

  ${breakpoints.only.smMobile} {
    width: 92px;
  }
`;
