import { css } from 'linaria';

export const footer = css`
  padding-right: 0;
  padding-left: 0;
  z-index: 1;
  pointer-events: none;

  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 1) 100%
  );

  @media (--to-tablet) {
    padding-left: 0 !important;
    padding-left: 0 !important;
  }
`;

export const content = css`
  padding: 0 !important;

  @media (--to-tablet) {
    display: flex;
    flex-direction: column;
  }
`;

export const title = css`
  padding-bottom: var(--space-200);
`;
