import React, { forwardRef } from 'react';
import { HeaderModelData } from 'types/model';
import { patchUrlByLocale } from 'utils';
import { useRegions } from 'components/header/hooks';
import { useSearchBarLogic } from './hooks';
import { Searchbar } from './components/searchbar';
import { SearchHistoryContextProvider } from './components/search-history-provider';

export interface SearchBarRootProps {
  model: HeaderModelData;
  hasForm?: boolean;
}

export const SearchBarRoot = forwardRef<HTMLDivElement, SearchBarRootProps>((props, containerRef) => {
  const { model, hasForm } = props;
  const {
    header: { linkToSearch, logoImage },
  } = model;

  const { currentRegion } = useRegions(model);

  const { searchSuggestions, getSuggestions, resetServices, productRedirection } = useSearchBarLogic();

  const { suggestions, loading, error } = searchSuggestions;

  return (
    <SearchHistoryContextProvider>
      <Searchbar
        getSuggestions={getSuggestions}
        suggestions={suggestions}
        suggestionsLoading={loading}
        error={error}
        resetServices={resetServices}
        searchHref={patchUrlByLocale({ url: linkToSearch })}
        logoUrl={logoImage}
        productRedirectionUrl={productRedirection.redirectUrl}
        productRedirectionUrlLoading={productRedirection.loading}
        currentRegionId={currentRegion?.regionId}
        notFoundMessage="" // TODO
        ref={containerRef}
        hasForm={hasForm}
      />
    </SearchHistoryContextProvider>
  );
});
