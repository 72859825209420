import { useCallback, useEffect, useRef, useState } from 'react';
import { showRegionsNotification } from '../../../selectors';

export const REGION_SET = 'region:set';

export const useRegionSubmitLogic = (cookies: Record<string, string>, search: string, regionId?: string) => {
  const regionIdRef = useRef(regionId);
  const [showSubmitNotification, setShowSubmitNotification] = useState(
    regionId ? showRegionsNotification(cookies, regionId, search) : false,
  );

  useEffect(() => {
    document.body.dispatchEvent(new CustomEvent(REGION_SET, { detail: regionIdRef.current }));
  }, []);

  const submitRegion = useCallback(() => {
    setShowSubmitNotification(false);
  }, []);

  return { showSubmitNotification, submitRegion };
};
