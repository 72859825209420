import React, { useState } from 'react';
import { RegularButton, Tooltip } from 'fronton-react';
import { UserIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { useHeaderAnalyticsEvent } from 'components/header/hooks';
import { EntryMenu } from './entry-menu';
import { tooltip } from '../../styles/tooltip';

export interface SignInProps {
  login: () => void;
  loginPageUrl: string;
  b2bLoginPageUrl: string;
  loginB2B: () => void;
  onOrderStatusModalOpen: () => void;
  isOrderStatusOn: boolean;
  isB2BEnabled: boolean;
}

export const SignIn = (props: SignInProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [tooltipShown, setTooltipShown] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleMouseEnter = () => {
    if (!open) {
      setTooltipShown(() => true);
    }
  };
  const handleMouseLeave = () => {
    if (!open) {
      setTooltipShown(() => false);
    }
  };

  const onAccountEvent = useHeaderAnalyticsEvent('account');

  const handleClick = () => {
    setTooltipShown(() => false);
    setOpen((prev) => !prev);

    if (!open) {
      onAccountEvent();
    }
  };

  return (
    <Tooltip title={t('header.main.profile')} open={tooltipShown} placement="bottom">
      <div>
        <Tooltip
          className={tooltip}
          open={open}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title={open ? <EntryMenu {...props} onClose={() => setOpen(false)} /> : ''}
        >
          <RegularButton
            aria-label={t('header.main.profile')}
            aria-pressed={open}
            data-qa="signin"
            size="l"
            variant="secondary"
            rounded
            iconOnly
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disabled={open}
            onClick={handleClick}
          >
            <UserIcon />
          </RegularButton>
        </Tooltip>
      </div>
    </Tooltip>
  );
};
