import { RefObject, useEffect, useRef } from 'react';
import { useBreakpoints, useWindowSize } from 'common/hooks';
import { useIsCheckout } from 'components/header/hooks/use-is-checkout';
import { hasBodyBottomPadding } from '../utils';

export const PADDING_BOTTOM_STYLE = 'padding-bottom';

export const useMobileBottomPadding = (menuRef: RefObject<HTMLElement>) => {
  const { mobile, tablet } = useBreakpoints() || {};
  const { width } = useWindowSize();
  const isBottomPaddingAdded = useRef(false);
  const isCheckout = useIsCheckout();

  useEffect(() => {
    if ((mobile || tablet) && !isCheckout) {
      if (!hasBodyBottomPadding()) {
        const menuHeight = menuRef.current?.clientHeight;
        document.body.style.setProperty(PADDING_BOTTOM_STYLE, `${menuHeight}px`);
        isBottomPaddingAdded.current = true;
      }
    } else if (isBottomPaddingAdded.current) {
      document.body.style.setProperty(PADDING_BOTTOM_STYLE, '');
      isBottomPaddingAdded.current = false;
    }
  }, [isCheckout, menuRef, mobile, tablet, width]);
};
