import { DeliveryInfo } from 'types/order-status';
import { formatDayAndMonth, formatHoursAndMinutes } from 'utils/date-time-formatter';

export const getDeliveryDateText = (deliveryInfo?: DeliveryInfo) => {
  if (!deliveryInfo?.deliveryDate) {
    return null;
  }
  const { deliveryDate, deliveryTimeFrom, deliveryTimeTo } = deliveryInfo;

  let result = `Вы выбрали доставку ${formatDayAndMonth(new Date(deliveryDate))}`;

  if (deliveryTimeFrom && deliveryTimeTo) {
    const formattedTimeFrom = formatHoursAndMinutes(new Date(deliveryTimeFrom));
    const formattedTimeTo = formatHoursAndMinutes(new Date(deliveryTimeTo));

    result = `${result} с ${formattedTimeFrom} до ${formattedTimeTo}`;
  }

  return result;
};
