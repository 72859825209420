import {
  PageSuggestion,
  ProductSuggestion,
  ServiceSuggestion,
  SlicedSuggestions,
  CategorySuggestion,
} from 'types/suggestions';

const MAX_SUGGESTIONS_NUMBER = 6;

export function getSlicedSuggestions(
  products: ProductSuggestion[],
  services: ServiceSuggestion[],
  categories: CategorySuggestion[],
): SlicedSuggestions {
  const slicedProducts =
    services.length <= MAX_SUGGESTIONS_NUMBER / 2
      ? products.slice(0, MAX_SUGGESTIONS_NUMBER - services.length)
      : products.slice(0, MAX_SUGGESTIONS_NUMBER / 2);
  const slicedServices =
    products.length <= MAX_SUGGESTIONS_NUMBER / 2
      ? services.slice(0, MAX_SUGGESTIONS_NUMBER - products.length)
      : services.slice(0, MAX_SUGGESTIONS_NUMBER / 2);
  const slicedCategories = categories.slice(0, MAX_SUGGESTIONS_NUMBER / 2);

  return [slicedProducts || [], slicedServices || [], [], slicedCategories];
}

export function getDefaultSlicedSuggestions(products: ProductSuggestion[]): SlicedSuggestions {
  const slicedProducts = products.slice(0, MAX_SUGGESTIONS_NUMBER);
  const defaultPages: PageSuggestion[] = [{ categoryUrl: '/promo/' }, { categoryUrl: '/uslugi/' }];
  return [slicedProducts || [], [], defaultPages, []];
}
