import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { getDomain, UTM_LABELS_COOKIE } from 'utils';
import { createOpenlinkUrl, filterSearchParamsByUtm } from 'utils/onelink-url';
import { DeviceOS, DeviceType } from 'utils/onelink-url/types';
import { CookiesEvents } from 'actions';
import { useSearch } from '@okapi/router-react';
import Cookies from 'universal-cookie';
import { ONE_DAY_COOKIE_EXPIRED_DATE } from '../constants';

export const VALIDATION_RULES: Record<string, string>[] = [
  { utm_medium: 'performance_cpc' },
  { utm_source: 'stroyportal_performance_cpc' },
  { utm_source: 'regmarkets.ru' },
];

const validateUtmLabels = (utmLabels: string[][]) =>
  utmLabels.some(([label, value]) => VALIDATION_RULES.some((rule) => rule[label] === value));

const tryParseJson = (json: string) => {
  try {
    return JSON.parse(json) as string[][];
  } catch (e) {
    return [];
  }
};

export const useOpenlinkUrl = (sampleUrl: string, os: DeviceOS, src: DeviceType) => {
  const { cookies, dispatch } = useStoreon<State>('cookies');
  const clientCookies = new Cookies();
  const [searchParams] = useSearch();
  let utmLabels =
    cookies && cookies[UTM_LABELS_COOKIE]
      ? (tryParseJson(cookies[UTM_LABELS_COOKIE]) ?? [])
      : (clientCookies.get(UTM_LABELS_COOKIE) ?? []);
  if (!utmLabels.length) {
    const params = Object.entries(searchParams) as string[][];
    utmLabels = filterSearchParamsByUtm(params);
    if (!__IS_SERVER__ && utmLabels.length && validateUtmLabels(utmLabels)) {
      dispatch(CookiesEvents.update, {
        key: UTM_LABELS_COOKIE,
        value: JSON.stringify(utmLabels),
        params: {
          path: '/',
          domain: getDomain(window.location.hostname),
          expires: new Date(Date.now() + ONE_DAY_COOKIE_EXPIRED_DATE),
        },
      });
    }
  }
  return utmLabels.length || !sampleUrl ? createOpenlinkUrl(os, src, utmLabels) : sampleUrl;
};
