import { Store } from 'store/types';
import { ProductRedirectionEvents } from 'actions';
import { getProductRedirectUrl } from 'api';
import { createCookies, getLangFromCookies } from '../utils';

export const productRedirectionUrl = (store: Store) => {
  store.on('@init', (state) => ({ ...state, productRedirection: { redirectUrl: null, loading: false } }));

  store.on(ProductRedirectionEvents.loading, (state, data) => ({
    ...state,
    productRedirection: { ...data, loading: true },
  }));

  store.on(ProductRedirectionEvents.request, (state, { lmcode, regionId }) => {
    const { orchestratorHost, useCredentials, apiKey, requestID, cookies } = store.get();
    const lang = getLangFromCookies(createCookies(cookies));

    store.dispatch(ProductRedirectionEvents.loading);

    getProductRedirectUrl(
      { lmcode, regionId, lang },
      {
        orchestratorHost,
        useCredentials,
        apiKey,
        requestID,
      },
    )
      .then((data) => store.dispatch(ProductRedirectionEvents.success, data))
      .catch(() => store.dispatch(ProductRedirectionEvents.failure, null));
  });

  store.on(ProductRedirectionEvents.success, (state, { redirectUrl }) => ({
    ...state,
    productRedirection: { redirectUrl, loading: false },
  }));

  store.on(ProductRedirectionEvents.refresh, (state, data) => ({
    ...state,
    productRedirection: { ...data, redirectUrl: null, loading: state.productRedirection.loading },
  }));

  store.on(ProductRedirectionEvents.failure, (state, data) => ({
    ...state,
    productRedirection: { ...data, loading: false },
  }));
};

export type ProductRedirectionStore = {
  productRedirection: {
    redirectUrl: string | null;
    loading: boolean;
  };
};
