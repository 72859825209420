import { css } from 'linaria';
import { globalOverlay } from 'styles/z-indexs';

export const wrapper = css`
  display: flex;
  column-gap: var(--space-400);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const root = css`
  display: flex;
  column-gap: var(--space-400);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (--only-mobile) {
    display: grid;
    grid-column-gap: var(--space-150);
    grid-row-gap: var(--space-150);
    grid-template-columns: var(--space-900) 1fr;
  }
`;

export const rootHiddenRegions = css`
  @media (--only-mobile) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const logoHiddenRegions = css`
  @media (--only-mobile) {
    align-self: center;
  }
`;

export const subRoot = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media (--only-mobile) {
    grid-column: span 2;
  }
`;

export const searchBarWrap = css`
  flex: 1 1 auto;
  @media (--from-tablet) {
    margin-right: var(--space-400);
  }
`;

export const searchBarWrapHiddenRegions = css`
  @media (--from-tablet) {
    margin-right: var(--space-100);
  }
  @media (--from-desktop) {
    margin-right: var(--space-200);
  }
`;

export const headerFullScreen = css`
  position: relative;
  width: 100% !important;

  padding-top: var(--space-200) !important;
  padding-bottom: var(--space-200) !important;

  transition: top 400ms ease-out;

  @media (--to-tablet) {
    width: 100vw !important;
  }
`;

export const headerBackground = css`
  background: rgb(255, 255, 255);

  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  min-width: 320px;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1;
`;

export const userGroup = css`
  display: flex;
  column-gap: var(--space-150);
`;

export const sticky = css`
  position: fixed;
  top: 0;
  background-color: var(--background-primary);
  z-index: ${globalOverlay};
  transition: transform 400ms;
  transform: translateY(-100%);
  border-bottom: 1px solid var(--control-minor);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
  }
`;

export const isVisible = css`
  transform: translateY(0);
`;
