import { composeURLWithQueries, patchLinkWithShopPath } from 'common/utils';
import { Region } from 'types/model';
import { useStoreon } from 'storeon/react';
import { useRouter } from '@okapi/router-react';
import { useCallback } from 'react';
import { useRegions } from './use-regions';

export type UseGetRegionLinkReturnType = (currentPageLink: Region['currentPageLink']) => string;

/**
 * @description Возвращает функцию для получения ссылки региона
 * @returns { UseGetRegionLinkReturnType }
 */
export const useGetRegionLink = (): UseGetRegionLinkReturnType => {
  const { model } = useStoreon('model');
  const { currentRegion } = useRegions(model);
  const router = useRouter();
  const search = router.getSearch();

  return useCallback(
    (currentPageLink: Region['currentPageLink']) =>
      patchLinkWithShopPath(
        composeURLWithQueries(currentPageLink, search, true, currentRegion?.regionId).toString(),
      ),
    [currentRegion?.regionId, search],
  );
};
