import { MutableRefObject, useEffect } from 'react';
import { BodyScrollOptions, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export type Options = {
  ref: MutableRefObject<HTMLElement | null>;
  /**
   * Меняет управление блокировкой скролла с автоматического(на mount или unmount)
   * на ручное
   */
  locked?: boolean;
} & BodyScrollOptions;

export function useBodyScrollLock(options: Options) {
  const { ref, locked, allowTouchMove, reserveScrollBarGap = true }: Options = options;

  useEffect(() => {
    const { current } = ref;

    if (document?.documentElement?.style) {
      document.documentElement.style.overflow = '';
    }

    if (current && locked) {
      disableBodyScroll(current, { allowTouchMove, reserveScrollBarGap });
      if (document?.documentElement?.style) {
        document.documentElement.style.overflow = 'hidden';
      }
      return () => {
        if (document?.documentElement?.style) {
          document.documentElement.style.overflow = '';
        }
        enableBodyScroll(current);
      };
    }
    return () => {};
  }, [locked, ref, allowTouchMove, reserveScrollBarGap]);
}
