import { Properties } from 'types/model';
import type { MutableRefObject } from 'react';

export const getRegionId = (properties: Properties | undefined): string | undefined => {
  const { regionId } = properties ?? {};
  return regionId;
};

export type ForwardRefInstance<T extends HTMLElement = HTMLElement> =
  | ((instance: T | null) => void)
  | MutableRefObject<T | null>
  | null;

export const isElement = <T extends HTMLElement = HTMLElement>(
  ref: ForwardRefInstance<T>,
): ref is MutableRefObject<T> => Boolean(ref && 'current' in ref);

export const elementIsEqualActiveElement = <T extends HTMLElement>(current: T | null) =>
  Boolean(current && current === document.activeElement);
