import React from 'react';
import { RegularButton } from 'fronton-react';
import { Region } from 'types/model';
import { useTranslation } from 'react-i18next';
import { content, buttonGroup } from './styles';
import { onSnackClickAnalyticsEvent } from './utils';

export interface RegionSnackContentProps {
  region: Region | null;
  onChangeClick: () => void;
  submitRegion: () => void;
}
export const RegionSnackContent = (props: RegionSnackContentProps) => {
  const { region, onChangeClick, submitRegion } = props;

  const { t } = useTranslation();

  const onChange = () => {
    onChangeClick();
    onSnackClickAnalyticsEvent('Изменить');
  };

  const onSuccess = () => {
    submitRegion();
    onSnackClickAnalyticsEvent('Всё верно');
  };

  return (
    <div className={content}>
      {t('header.main.region-snack')} {region?.regionName || ''}?
      <div className={buttonGroup}>
        <RegularButton data-qa="change-region-button" size="s" onClick={onChange}>
          {t('header.main.region-popup.change')}
        </RegularButton>
        <RegularButton data-qa="apply-region-button" size="s" invert onClick={onSuccess}>
          {t('header.main.region-popup.agree')}
        </RegularButton>
      </div>
    </div>
  );
};
