import { useEffect, useState } from 'react';
import { isBrowser } from 'fronton-react';

export const getWindowSize = () => {
  const { innerWidth, outerWidth } = isBrowser() ? window : { innerWidth: 0, outerWidth: 0 };

  return {
    innerWidth,
    outerWidth,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
