import { LangType } from 'common/constants';

export interface GetInitAuthUrlParams {
  authUrl: string;
  curPage: string;
  path: string;
  lang?: LangType;
  isKzDomain?: boolean;
}

export function getInitAuthUrl({ authUrl, curPage, path, isKzDomain, lang = 'ru' }: GetInitAuthUrlParams) {
  try {
    const { origin: authOrigin } = new URL(authUrl);
    const { origin } = new URL(curPage);

    // хардкод для страниц со своим роутингом на nginx
    if (path.includes('/main-page')) {
      path = '/';
    }

    // хардкод для страниц со своим роутингом на nginx
    if (path.includes('/brandslist')) {
      path = '/brands/';
    }

    if (isKzDomain) {
      return `${authOrigin}/?state=${origin}${path}&lang=${lang}`;
    }

    return `${authOrigin}/?state=${origin}${path}`;
  } catch (error) {
    return authUrl;
  }
}
