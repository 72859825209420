import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useIsomorphicLayoutEffect } from 'fronton-react';
import { SlicedSuggestions } from 'types/suggestions';
import { suggestionsContainer, suggestionsRoot, suggestionsActive } from './styles';
import { Suggestions } from './suggestions';
import { SuggestionPreloader } from './suggestions-preloader';
import { Error } from './suggestions-error';

export type SearchbarModalProps = {
  searchQuery: string;
  suggestions: SlicedSuggestions | null;
  onMouseEnter: (idx: number) => void;
  onMouseLeave: () => void;
  fetchSuggestions: () => void;
  suggestionsLoading?: boolean;
  onSelectSuggestion?: () => void;
  logoUrl: string;
  searchHref: string;
  error?: boolean;
  expanded: boolean;
  focusGroup: string;
  collapse: () => void;
  suggestionSelector: string;
  index: number;
  isSrp: boolean;
  notFoundMessage: string | false | undefined;
};

export const SearchbarModal = forwardRef<HTMLDivElement, SearchbarModalProps>(
  (
    {
      searchQuery,
      suggestions,
      searchHref,
      onMouseEnter,
      fetchSuggestions,
      onMouseLeave,
      expanded,
      error,
      suggestionSelector,
      index,
      isSrp,
      onSelectSuggestion,
      notFoundMessage,
      suggestionsLoading,
      collapse,
    },
    ref,
  ) => {
    const suggestionsArePresent = suggestions && (suggestions[0].length || suggestions[1].length);

    const hideSuggestions = error || (!suggestionsArePresent && notFoundMessage === false);
    const showSuggestions = !hideSuggestions;

    useIsomorphicLayoutEffect(() => {
      if (expanded && !suggestions && !suggestionsLoading && !error) {
        fetchSuggestions();
      }
    });

    return (
      <div className={clsx(suggestionsRoot, { [suggestionsActive]: expanded })} role="dialog">
        <div ref={ref} className={suggestionsContainer}>
          {error && <Error fetchSuggestions={fetchSuggestions} />}
          {suggestionsLoading && <SuggestionPreloader />}
          {showSuggestions && !suggestionsLoading && (
            <Suggestions
              searchQuery={searchQuery}
              onSelectSuggestion={onSelectSuggestion}
              isSrp={isSrp}
              suggestions={suggestions}
              suggestionSelector={suggestionSelector}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              searchHref={searchHref}
              index={index}
              notFoundMessage={notFoundMessage}
              collapse={collapse}
            />
          )}
        </div>
      </div>
    );
  },
);
