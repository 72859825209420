import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { LinkButton } from 'fronton-react';
import { Region } from 'types/model';
import { analytics } from 'utils';
import { UseGetRegionLinkReturnType } from 'components/header/hooks';
import clsx from 'clsx';
import { regionLink } from './styles';
import { stretchedLink } from '../../main-menu/styles';
import { prependRegionToUrl, deleteFacetQuery } from './utils';
import { ELIGIBILITY_BY_STORES_FACET } from './constants';

export interface RegionLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  region: Region;
  hasRegionNumberChanged?: boolean;
  focused?: boolean;
  getRegionLink: UseGetRegionLinkReturnType;
}

export const RegionLink = (props: RegionLinkProps) => {
  const { region, hasRegionNumberChanged, focused = false, getRegionLink, ...rest } = props;
  const { regionId, regionName, regionCode } = region;

  const elementRef = useRef<HTMLAnchorElement | null>();

  const sendGa = () => {
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: hasRegionNumberChanged === undefined ? 'chooseRegionSuggest' : 'chooseRegionHint',
      elementText: regionName,
    });
  };

  const hrefWithRegion = prependRegionToUrl(window.location.href ?? '', regionCode);

  const resultHref = deleteFacetQuery(getRegionLink(hrefWithRegion), ELIGIBILITY_BY_STORES_FACET);

  useEffect(() => {
    if (focused && elementRef.current) {
      elementRef.current.focus();
    }
  }, [focused]);

  return (
    <LinkButton
      ref={elementRef as React.RefObject<HTMLAnchorElement>}
      role="listitem"
      as="a"
      onClick={sendGa}
      href={resultHref}
      data-qa={`region-link-${regionId}`}
      className={clsx(regionLink, stretchedLink)}
      {...rest}
    >
      {regionName}
    </LinkButton>
  );
};
