import React from 'react';
import { IconButton } from 'fronton-react';
import { XCircleIcon } from '@fronton/icons-react';
import { Suggestion } from 'types/suggestions';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { searchClear, searchClearDecorateTransition } from './styles';

export interface ClearButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'aria-label'> {
  classNames?: string;
  value: string;
  selectedItem: NonNullable<Suggestion> | null;
  translated?: boolean;
}

export const ClearButton = (props: ClearButtonProps) => {
  const { value, selectedItem, translated, classNames, ...rest } = props;

  const { t } = useTranslation();

  return (
    <IconButton
      {...rest}
      form="headersearchform"
      size="m"
      label={t('header.searchbar.clear-search')}
      withExtraClickZone
      variant="secondary"
      onMouseDown={(e) => e.preventDefault()}
      className={clsx(
        searchClear,
        { show: value },
        { [searchClearDecorateTransition]: translated },
        classNames,
      )}
    >
      <XCircleIcon type="fill" size="l" color="text-primary" />
    </IconButton>
  );
};
