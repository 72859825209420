import { useCallback, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { EXECUTE_LOGOUT } from 'actions';
import { loadRefreshScript } from './load-refresh-script';
import { useProfileAnalyticsEvent } from '../analytics';
import { useAuthNavigate } from './use-login-navigate';

export const useAuthLogic = () => {
  const { dispatch, model } = useStoreon<State>('model');

  useAuthNavigate(model);

  const login = useProfileAnalyticsEvent('login');
  const loginB2B = useProfileAnalyticsEvent('b2blogin');

  const onLogoutEvent = useProfileAnalyticsEvent('logout');

  const logout = useCallback(() => {
    if (!model) return;

    onLogoutEvent();
    dispatch(EXECUTE_LOGOUT.request);
  }, [model, dispatch, onLogoutEvent]);

  // loadRefreshScript has check to avoid adding script multiple times
  useEffect(() => loadRefreshScript(model?.header?.refreshUrl), [model]);

  return {
    authPopup: false,
    login,
    logout,
    loginB2B,
  };
};
