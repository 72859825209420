import { RegionSet, LangType, RegionType, LANG_TO_REGION } from 'common/constants';
import { normalizeTrailingSlash } from '../../utils/link-helpers';

export function isLangMatchDomain(host: string, regions?: RegionSet | null) {
  if (!regions) return false;

  let region = '';

  if (host.endsWith('.ru') || host.endsWith('lmru.tech')) {
    region = 'ru';
  }

  if (host.endsWith('.kz') || host.endsWith('lmkz.tech')) {
    region = 'kz';
  }

  if (host.includes('local')) {
    region = 'local';
  }

  return regions.has(region as RegionType);
}

export function updateLocation(region: RegionType, nextLang: LangType, isChangeLocation = false) {
  if (isChangeLocation) {
    const nextRegion = LANG_TO_REGION[nextLang];
    const currentUrl = new URL(window.location.href);

    const regionPrefix = `/${region}`;

    if (currentUrl.pathname.startsWith(regionPrefix)) {
      currentUrl.pathname = currentUrl.pathname.slice(regionPrefix.length);
    }

    if (nextLang === 'kk') {
      currentUrl.pathname = normalizeTrailingSlash(`${nextRegion}${currentUrl.pathname}`);
    } else {
      currentUrl.pathname = normalizeTrailingSlash(`${currentUrl.pathname}`);
    }

    window.location.assign(currentUrl);
  } else {
    window.location.reload();
  }
}
