import { useCallback } from 'react';

const dataPropName = 'data-focus-on-ignore';

export const useFocusOnIgnore = () => {
  const isTargetIgnored = useCallback(
    (target?: HTMLElement | EventTarget | null) =>
      Boolean((target as HTMLElement | undefined)?.closest(`[${dataPropName}]`)),
    [],
  );

  const documentMouseDown = useCallback(
    (event: MouseEvent) => {
      if (isTargetIgnored(event.target)) {
        event.preventDefault();
      }
    },
    [isTargetIgnored],
  );

  return {
    focusOnIgnoreProps: {
      [dataPropName]: true,
    },
    dataPropName,
    isTargetIgnored,
    documentMouseDown,
  };
};
