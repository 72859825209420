import { useEffect } from 'react';

export function useHandleEscPress(cb: () => void, enabled = true) {
  useEffect(() => {
    if (enabled && cb) {
      const handleEscPress = (e: HTMLElementEventMap['keyup']) => {
        if (e.key === 'Escape') cb();
      };

      document.addEventListener('keyup', handleEscPress, { passive: true });
      return () => document.removeEventListener('keyup', handleEscPress);
    }

    return () => {};
  }, [cb, enabled]);
}
