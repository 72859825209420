import { useStoreon } from 'storeon/react';
import { Experiment } from 'types/model';
import { LANG_REGION, RegionSet, LANG_EXPERIMENT_INTERNAL_ID } from 'common/constants';

export type LangExperimentConfig = {
  langDomains?: RegionSet | null;
  changeLangPath?: boolean;
};

export function useLangPickerExperiment(): LangExperimentConfig {
  const { model } = useStoreon('model');
  const { externalId } =
    model?.experiments?.find(
      (experiment: Experiment) => experiment.internalId === LANG_EXPERIMENT_INTERNAL_ID,
    ) ?? {};

  if (externalId) {
    return {
      langDomains: getDomains(externalId),
      changeLangPath: isChangePath(externalId),
    };
  }

  return {};
}

export function getDomains(str: string): RegionSet | null {
  const domains = str
    .split(';')
    ?.find((i) => i.includes('domains'))
    ?.split(':')[1];

  if (!domains) return null;

  const data = domains.split(',')?.filter((lang: string) => LANG_REGION[lang]);

  if (data.length) {
    return new Set(data) as RegionSet;
  }

  return null;
}

export function isChangePath(str = '') {
  return str.includes('changePath:true');
}
