import React, { createContext, useState } from 'react';
import { Suggestion } from 'types/suggestions';
import { useLocalStorage } from '../hooks/use-local-storage';
import { getHistoryItemTitle, getHistoryItemUrl } from './utils/history';

const MAX_ITEMS_COUNT = 5;
const RECORD_NAME = 'srch.history'; // {prefix}.key: value

export type SearchHistoryItem = {
  title: string;
  url: string;
};

export type Context = {
  history: SearchHistoryItem[];
  addHistoryFromSubmit: (value: string, sanitizedValue: string, suggestion: Suggestion | null) => void;
  addHistoryItem: (item: SearchHistoryItem) => void;
  clear: () => void;
};

export const SearchHistoryContext = createContext<Context | null>(null);

type Props = {
  children: React.ReactNode;
};

export const SearchHistoryContextProvider: React.FC<Props> = ({ children }) => {
  const [lsHistory, setLsHistory] = useLocalStorage<SearchHistoryItem[]>(RECORD_NAME, []);
  const [history, setHistory] = useState(lsHistory);

  const set = (value: SearchHistoryItem[]) => {
    setHistory(value);
    setLsHistory(value);
  };

  const addExistingItem = (item: SearchHistoryItem) => {
    const newValue = [
      { ...item },
      ...lsHistory.filter((hi) => hi.title !== item.title && hi.url !== item.url),
    ];
    set(newValue);
  };

  const addNewItem = (item: SearchHistoryItem) => {
    const newValue = [
      {
        ...item,
      },
      ...lsHistory.slice(0, 4),
    ];
    set(newValue);
  };

  const addHistoryItem = (item: SearchHistoryItem) => {
    const duplicate = lsHistory.find((hi) => hi.title === item.title && hi.url === item.url);
    if (duplicate) {
      addExistingItem(item);
    } else {
      addNewItem(item);
    }
  };

  const addHistoryFromSubmit = (value: string, sanitizedValue: string, suggestion: Suggestion | null) => {
    addHistoryItem({
      title: getHistoryItemTitle(value, suggestion),
      url: getHistoryItemUrl(sanitizedValue, suggestion),
    });
  };

  const clear = () => {
    set([]);
  };

  return (
    <SearchHistoryContext.Provider
      value={{ history: history.slice(0, MAX_ITEMS_COUNT), addHistoryFromSubmit, addHistoryItem, clear }}
    >
      {children}
    </SearchHistoryContext.Provider>
  );
};
