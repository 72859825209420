import React, { forwardRef, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { DrawerFooterProps } from './types';
import { ScrollContext } from './context';
// import styles from './styles.module.scss';
import { footer } from './styles';

/**
 * `<DrawerFooter>` - Обёртка для контента подвала Drawer.
 * Предоставляет стили и логику для подвала`.
 */
export const DrawerFooter = forwardRef<HTMLDivElement, DrawerFooterProps>(
  ({ className, children, sticky, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const testId = props?.['data-testid'] || 'drawer-footer';

    const { bottom } = useContext(ScrollContext);
    const classNameFooter = useMemo(
      () =>
        clsx(
          footer,

          {
            sticky: !!sticky,
            shadow: !!bottom,
          },
          className,
        ),
      [className, sticky, bottom],
    );

    return (
      <footer {...props} className={classNameFooter} ref={ref} data-testid={testId}>
        {children}
      </footer>
    );
  },
);
