import React from 'react';
import { UseCityPickerStateReturnType, useRegions } from 'components/header/hooks';
import { HeaderModelData } from 'types/model';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter } from 'common/components/left-drawer';
import { useCitiesSuggestions } from 'common/hooks';
import { Typography } from 'fronton-react';
import { headerBackBtn } from 'components/header/styles/common';
import { useTranslation } from 'react-i18next';
import { footer, content, title } from './styles';
import { RegionsSearch } from './region-search';
import { MenuContentLayer } from '../menu-content-layer';

export interface RegionsMenuProps extends UseCityPickerStateReturnType {
  model: HeaderModelData;
}

export const RegionsMenu = (props: RegionsMenuProps) => {
  const { show, onMenuClose, model } = props;
  const { regions } = useRegions(model);

  const { t } = useTranslation();

  const [suggestions, setSuggestions, hasRegionNumberChanged, filteredCitiesList] =
    useCitiesSuggestions(regions);

  return (
    <Drawer onClose={onMenuClose} show={show} data-qa="region-menu">
      <DrawerHeader className={headerBackBtn} title="" onClickBack={onMenuClose} />
      <DrawerContent className={content}>
        <MenuContentLayer horizontal className={title}>
          <Typography as="h3" variant="h3">
            {t('header.main.region-popup.choose')}
          </Typography>
        </MenuContentLayer>
        <RegionsSearch
          regions={regions}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          hasRegionNumberChanged={hasRegionNumberChanged}
          filteredCitiesList={filteredCitiesList}
        />
      </DrawerContent>
      <DrawerFooter sticky className={footer} />
    </Drawer>
  );
};
