import { useState } from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

type WindowSize = {
  width: number;
  height: number;
};
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: __IS_SERVER__ ? 0 : window?.innerWidth,
    height: __IS_SERVER__ ? 0 : window?.innerHeight,
  });

  useIsomorphicLayoutEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
