import React from 'react';

export const SuggestionListItemServicesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      opacity="0.4"
      d="M15 16.0005H18V20.5005C18 21.3289 17.3284 22.0005 16.5 22.0005C15.6716 22.0005 15 21.3289 15 20.5005V16.0005Z"
      fill="#089FB0"
    />
    <path
      d="M15 16.0005H18V20.5005C18 21.3289 17.3284 22.0005 16.5 22.0005V22.0005C15.6716 22.0005 15 21.3289 15 20.5005V16.0005Z"
      stroke="#464C5C"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.5 9V16" stroke="#464C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.5 3.00049H17.5L18.5 6.50049L16.5 9.50049L14.5 6.50049L15.5 3.00049Z"
      stroke="#464C5C"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M7.5 22C6.67157 22 6 21.3284 6 20.5L6 10L9 10L9 20.5C9 21.3284 8.32843 22 7.5 22Z"
      fill="#089FB0"
    />
    <path
      d="M7.5 22C6.67157 22 6 21.3284 6 20.5L6 10L9 10L9 20.5C9 21.3284 8.32843 22 7.5 22V22Z"
      stroke="#464C5C"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 2.29076L9.23451 1.71142C9.04185 1.63344 8.82302 1.65633 8.65067 1.7725C8.47832 1.88866 8.375 2.08291 8.375 2.29076L9 2.29076ZM9 5.5L9.34669 6.02003C9.52056 5.90411 9.625 5.70897 9.625 5.5L9 5.5ZM7.5 6.5L7.15331 7.02003C7.36325 7.15999 7.63675 7.15999 7.84669 7.02003L7.5 6.5ZM6 5.5L5.375 5.5C5.375 5.70897 5.47944 5.90412 5.65331 6.02003L6 5.5ZM6 2.29076L6.625 2.29076C6.625 2.08291 6.52168 1.88867 6.34933 1.7725C6.17698 1.65633 5.95815 1.63344 5.76549 1.71142L6 2.29076ZM10.875 6C10.875 7.86396 9.36396 9.375 7.5 9.375L7.5 10.625C10.0543 10.625 12.125 8.55432 12.125 6L10.875 6ZM8.76549 2.8701C10.0035 3.37123 10.875 4.58459 10.875 6L12.125 6C12.125 4.05803 10.9283 2.39706 9.23451 1.71142L8.76549 2.8701ZM8.375 2.29076L8.375 5.5L9.625 5.5L9.625 2.29076L8.375 2.29076ZM8.65331 4.97997L7.15331 5.97997L7.84669 7.02003L9.34669 6.02003L8.65331 4.97997ZM7.84669 5.97997L6.34669 4.97997L5.65331 6.02003L7.15331 7.02003L7.84669 5.97997ZM6.625 5.5L6.625 2.29076L5.375 2.29076L5.375 5.5L6.625 5.5ZM4.125 6C4.125 4.58459 4.99649 3.37123 6.23451 2.87009L5.76549 1.71142C4.07168 2.39706 2.875 4.05803 2.875 6L4.125 6ZM7.5 9.375C5.63604 9.375 4.125 7.86396 4.125 6L2.875 6C2.875 8.55432 4.94568 10.625 7.5 10.625L7.5 9.375Z"
      fill="#464C5C"
    />
  </svg>
);
