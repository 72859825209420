import React from 'react';
import { RegularButton, Tooltip, Badge } from 'fronton-react';
import { ShoppingCartSimpleIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { patchUrlByLocale } from 'utils';
import { useBasketApi } from 'components/header/hooks/use-basket-api';
import { useHeaderAnalyticsEvent } from 'components/header/hooks';
import { wrapper, counter } from 'components/header/styles/header-hero-button';

export const BasketButton = () => {
  const { path: pathname } = useStoreon<State>('path');
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');

  const { basketCount } = useBasketApi();
  const hasProductsInBasket = basketCount > 0;

  const { t } = useTranslation();

  const onBasketClickEvent = useHeaderAnalyticsEvent('basket');

  return (
    <Tooltip title={t('header.main.basket')}>
      <div className={wrapper}>
        <RegularButton
          onClick={onBasketClickEvent}
          rounded
          aria-label={t('header.main.basket')}
          variant={hasProductsInBasket ? 'primary' : 'secondary'}
          iconOnly
          as="a"
          data-qa="header-basket-button"
          href={patchUrlByLocale({ url: '/basket/', pathname, lang })}
          size="l"
        >
          <ShoppingCartSimpleIcon type="outline" size="l" color="text-primary" />
        </RegularButton>
        {hasProductsInBasket && (
          <Badge size="s" data-qa="header-basket-count" value={basketCount} className={counter} />
        )}
      </div>
    </Tooltip>
  );
};
