import { useOpenlinkUrl } from 'common/hooks';

export interface GetByOsTypeParams {
  androidTitle: string;
  androidLink: string;
  iosTitle: string;
  iosLink: string;
}

export const useByOsType = (params: GetByOsTypeParams) => {
  const { androidTitle, androidLink, iosLink, iosTitle } = params;

  return {
    android: {
      title: androidTitle,
      link: useOpenlinkUrl(androidLink, 'android', 'mobile'),
    },
    ios: {
      title: iosTitle,
      link: useOpenlinkUrl(iosLink, 'os', 'mobile'),
    },
  };
};
