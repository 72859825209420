export const CATALOG_OVERLAY_OPEN = 'catalog:overlay:open';
export const CATALOG_OVERLAY_CLOSE = 'catalog:overlay:close';
export const CATALOG_OVERLAY_TOGGLE = 'catalog:overlay:toggle';
export const CATALOG_OVERLAY_CHANGED = 'catalog:overlay:changed';

export const defaultCataloguePath = '/catalogue.navigation.json';

export const CATALOG_PAGE_PATH = '/catalogue/';
export const SERVICES_PAGE_PATH = '/uslugi/';
export const APRO_PAGE_PATH = '/biznes/';

export const CLIENT_SUPPORT_PAGE_PATH = '/company/obratnaya-svyaz/';

export const B2B_PERSONAL_ACCOUNT_HOST = '/lkb2b/rbaco/api/v1/oauth/sign-in';
