import React, { useEffect } from 'react';
import { MaskedInput, RegularButton, Caption, Input } from 'fronton-react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetOrderStatus, useOrderStatusAnalyticEvent, useVirtualKeyboard } from '../hooks';
import { beforeMaskedStateChangeFunc, getClientOrderDTO, validationSchema } from '../utils';
import { OrderStatusFormProps, FormValues, GetErrorInputConfigFn, OnChangeFieldFn } from './types';
import { fieldsWrapper, form, submitButton, whiteBackground } from './styles';
import { ErrorInfoBar } from './error-info-bar';

export const OrderStatusForm = ({
  isMobile,
  isShowErrorInfoBar,
  closeErrorInfoBar,
}: OrderStatusFormProps) => {
  const { getOrderStatus, isLoading } = useGetOrderStatus();
  const { sendErrorOrderStatusEvent } = useOrderStatusAnalyticEvent();
  const { t } = useTranslation();

  useVirtualKeyboard();

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      orderNumber: '',
      phoneNumber: '',
    },
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const fieldErrors = Object.values(errors);

    if (fieldErrors.length) {
      sendErrorOrderStatusEvent(fieldErrors.map((error) => error.message ?? ''));
    }
  }, [errors, sendErrorOrderStatusEvent]);

  const getErrorInputConfig: GetErrorInputConfigFn = (fieldState) => {
    const { error } = fieldState;
    return error?.message ? { caption: <Caption error message={error.message} />, error: true } : {};
  };

  const onSubmit = async (values: FormValues) => {
    const dto = getClientOrderDTO(values);
    getOrderStatus(dto);
  };

  const onChangeField: OnChangeFieldFn = (fieldName, fieldState, value, onChange) => {
    const { error } = fieldState;

    if (error) {
      clearErrors(fieldName);
    }

    if (isShowErrorInfoBar) {
      closeErrorInfoBar();
    }

    onChange(value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={form} data-qa="order-status-form">
      <div className={fieldsWrapper}>
        <Controller
          name="orderNumber"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={whiteBackground}
              data-qa="order-number"
              mobile={isMobile}
              fullWidth
              label="Номер заказа"
              placeholder="Введите номер заказа"
              type="text"
              {...getErrorInputConfig(fieldState)}
              {...field}
              onChange={(value) => onChangeField(field.name, fieldState, value, field.onChange)}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => (
            <MaskedInput
              className={whiteBackground}
              mask="+7 (999) 999 99 99"
              beforeMaskedStateChange={beforeMaskedStateChangeFunc}
              data-qa="phone-number"
              mobile={isMobile}
              fullWidth
              label="Номер телефона"
              placeholder="Введите номер телефона"
              type="tel"
              {...getErrorInputConfig(fieldState)}
              {...field}
              onChange={(value) => onChangeField(field.name, fieldState, value, field.onChange)}
            />
          )}
        />
      </div>

      <ErrorInfoBar isShow={isShowErrorInfoBar} onClose={closeErrorInfoBar} />

      <RegularButton loading={isLoading} type="submit" wide size="l" className={submitButton}>
        {t('header.orderStatus.find-order')}
      </RegularButton>
    </form>
  );
};
