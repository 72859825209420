import React from 'react';
import { RegularButton } from 'fronton-react';
import { MagnifyingGlassIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';

export interface SubmitButtonProps {
  onSubmitRequest: () => void;
}

export const SubmitButton = ({ onSubmitRequest }: SubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <RegularButton
      data-qa="search-submit-button"
      type="submit"
      iconOnly
      variant="accent"
      onMouseDown={onSubmitRequest}
      label={t('header.searchbar.search')}
    >
      <MagnifyingGlassIcon />
    </RegularButton>
  );
};
