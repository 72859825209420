import React from 'react';
import { Badge, Divider } from 'fronton-react';
import { patchUrlByLocale } from 'utils';
import {
  PackageIcon,
  CreditCardIcon,
  LayoutIcon,
  UserFocusIcon,
  BookmarkSimpleIcon,
} from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';

import { useProfileAnalyticsEvent } from 'components/header/hooks';
import { useHideProjects } from 'common/hooks/use-hide-projects';
import { UserMenuItem } from '../menu-item';
import { UserMenuProps } from './types';

export const UserMenuContent = (props: Omit<UserMenuProps, 'show' | 'onClose'>) => {
  const { shoppingListUrl: shoppingList, shoppingListCount, logout } = props;

  const { t } = useTranslation();

  const onPurchasesClick = useProfileAnalyticsEvent('purchases');
  const onLoyaltyClick = useProfileAnalyticsEvent('loyalty');
  const onProjectsClick = useProfileAnalyticsEvent('projects');
  const onShoppingListClick = useProfileAnalyticsEvent('shoppingList');
  const onInfoClick = useProfileAnalyticsEvent('info');
  const isHideProjects = useHideProjects();

  return (
    <div data-qa="user-menu-list">
      <UserMenuItem
        icon={PackageIcon}
        data-qa="user-purchase-history"
        href={patchUrlByLocale({ url: '/lk/history/' })}
        onClick={onPurchasesClick}
      >
        {t('header.main.user-menu.purchases')}
      </UserMenuItem>

      <UserMenuItem
        icon={CreditCardIcon}
        data-qa="user-loyalty-program"
        href={patchUrlByLocale({ url: '/lk/loyalty/' })}
        onClick={onLoyaltyClick}
      >
        {t('header.main.user-menu.loyal')}
      </UserMenuItem>

      {!isHideProjects && (
        <UserMenuItem
          icon={LayoutIcon}
          data-qa="user-projects"
          href={patchUrlByLocale({ url: '/lk/projects/' })}
          onClick={onProjectsClick}
        >
          {t('header.main.user-menu.projects')}
        </UserMenuItem>
      )}

      <UserMenuItem
        icon={BookmarkSimpleIcon}
        data-qa="user-shopping-list"
        href={patchUrlByLocale({ url: shoppingList })}
        onClick={onShoppingListClick}
      >
        {t('header.main.user-menu.shopping-list')}
        {shoppingListCount > 0 && (
          <Badge data-qa="user-shopping-list-count" size="m" value={shoppingListCount} />
        )}
      </UserMenuItem>

      <UserMenuItem
        icon={UserFocusIcon}
        data-qa="user-lk-info"
        href={patchUrlByLocale({ url: '/lk/info/' })}
        onClick={onInfoClick}
      >
        {t('header.main.user-menu.private')}
      </UserMenuItem>

      <Divider />

      <UserMenuItem data-qa="user-logout" as="button" onClick={logout}>
        {t('header.main.user-menu.exit')}
      </UserMenuItem>
    </div>
  );
};
