import { IconProps } from '@fronton/icons-react';

/**
 * @param location - location path
 * @param href - link href
 * @returns { boolean }
 */
export const matchRoute = (location: string, href: string): boolean => {
  if (href === '/' || href === '') {
    return location === href;
  }

  const regexp = new RegExp(`^${href.replace(/\/$/g, '')}($|\\/|\\?)`);

  return location.search(regexp) > -1;
};

export type IconActiveParams = Pick<IconProps, 'type' | 'color'>;

export const activeIconProps: IconActiveParams = {
  type: 'fill',
  color: 'text-primary',
};

export const notActiveIconProps: IconActiveParams = {
  type: 'outline',
  color: 'text-minor',
};
