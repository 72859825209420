import React from 'react';

type Props = {
  type?: 'outline' | 'fill' | 'duotone';
  color?: string;
};

export const NAStore = ({ type = 'outline', color = 'currentColor' }: Props) => (
  <>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <div style={{ '--i-color': `var(--${color})` }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        {type === 'fill' ? (
          <path
            d="M20.9001 9.63625V19.6471C20.9001 20.1994 20.4524 20.6471 19.9001 20.6471H8.49368L9.50145 16.0694H15.5065V11.7938L12.7043 9.05689L15.8004 4.65373L20.9001 9.63625ZM15.8004 4.65373L8.49368 11.7938V20.6471H4.1001C3.54781 20.6471 3.1001 20.1994 3.1001 19.6471V9.63625L11.3005 1.62286C11.6891 1.24316 12.3097 1.24314 12.6983 1.6228L15.8004 4.65373Z"
            fill="var(--i-color)"
          />
        ) : (
          <path
            d="M15.2 5L12.3535 2.10905C12.1587 1.91121 11.84 1.90997 11.6437 2.1063L3.89645 9.85355C3.80268 9.94732 3.75 10.0745 3.75 10.2071V19.5179C3.75 19.794 3.97386 20.0179 4.25 20.0179H8.20001M16.2 6L20.1014 9.85318C20.1965 9.94713 20.25 10.0752 20.25 10.2089V19.5179C20.25 19.794 20.0261 20.0179 19.75 20.0179H9.70001"
            stroke="var(--i-color)"
            strokeWidth="1.25"
          />
        )}
      </svg>
    </div>
  </>
);
