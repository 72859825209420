import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { Typography } from 'fronton-react';
// import styles from './styles.module.scss';
import { content } from './styles';
import { DrawerContentProps } from './types';

/**
 * `<DrawerContent>` - Обёртка для контента Drawer.
 * Предоставляет стили для контента.
 */
export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  ({ children, className, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const testId = props['data-testid'] || 'drawer-content';
    const contentClassName = useMemo(() => clsx(content, className), [className]);

    return (
      <Typography
        variant="m"
        size="body_long"
        {...props}
        color="text-primary"
        className={contentClassName}
        ref={ref}
        data-testid={testId}
      >
        {children}
      </Typography>
    );
  },
);
