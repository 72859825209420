import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CookieIcon } from '@fronton/icons-react';
import { useSnackbar, LinkButton } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { CookiesEvents } from 'actions';
import { cookieAccepted } from 'selectors';
import { analytics, getDomain, patchUrlByLocale } from 'utils';
import { ONE_DAY_COOKIE_EXPIRED_DATE } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useSiteDomain } from 'components/header/components/searchbar/hooks/use-site-domain';
import { link } from './styles';

export const useCookieNotification = () => {
  const [snackID] = useState('cookie-notification-snack');

  const { addSnack: useAddSnack, removeSnack: useRemoveSnack } = useSnackbar();

  const { current: addSnack } = useRef(useAddSnack);
  const { current: removeSnack } = useRef(useRemoveSnack);

  const { cookies, dispatch, model } = useStoreon<State>('cookies', 'model');
  const { path: pathname } = useStoreon<State>('path');
  const { lang } = cookies;

  const isCookieAccepted = cookieAccepted(cookies);

  const { t } = useTranslation();

  const domain = getDomain(__IS_SERVER__ ? '' : window.location.hostname);
  const { isKzDomain } = useSiteDomain(domain);

  const acceptCookie = useCallback(() => {
    dispatch(CookiesEvents.update, {
      key: 'cookie_accepted',
      value: 'true',
      params: {
        path: '/',
        domain,
        expires: new Date(Date.now() + 1024 * ONE_DAY_COOKIE_EXPIRED_DATE),
      },
    });
  }, [dispatch, domain]);

  const onAcceptCookie = useCallback(() => {
    if (isKzDomain) acceptCookie();

    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: 'cookieSnackbar',
      elementText: t('header.popups.cookie.accept'),
    });
  }, [acceptCookie, isKzDomain, t]);

  useEffect(() => {
    if (!isCookieAccepted) {
      if (!isKzDomain) acceptCookie();
      setTimeout(() => {
        addSnack({
          id: snackID,
          icon: <CookieIcon type="outline" size="l" color="text-invert" />,
          variant: 'primary',
          button: t('header.popups.cookie.accept'),
          testId: 'cookie-notification',
          content: (
            <span>
              {t('header.popups.cookie.agree')}{' '}
              <LinkButton
                className={link}
                as="a"
                invert
                size="s"
                variant="underline"
                href={patchUrlByLocale({ url: model?.header?.cookieLink, pathname, lang })}
                data-qa="cookie-notification-link"
              >
                {t('header.popups.cookie.cookie')}
              </LinkButton>
              {isKzDomain && <>{t('header.popups.cookie.analytics')}</>}
            </span>
          ),
          action: onAcceptCookie,
        });
      }, 1000);
    }

    return () => {
      removeSnack(snackID);
    };
  }, [
    t,
    isCookieAccepted,
    onAcceptCookie,
    addSnack,
    snackID,
    removeSnack,
    model?.header?.cookieLink,
    acceptCookie,
    isKzDomain,
    lang,
    pathname,
  ]);
};
