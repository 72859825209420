import { useEffect } from 'react';
import { useSnackbar } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { OrderStatusError, OrderStatusErrorText, OrderStatusInfoRequest } from 'types/order-status';
import { useOrderStatusAnalyticEvent } from './use-order-status-analytic-event';
import { useRecaptchaToken } from './use-recaptcha-token';
import { FETCH_ORDER_STATUS } from '../../../actions';
import { getErrorSnack } from '../utils/snack';
import { snackId } from '../constants/snack';

export const useGetOrderStatus = () => {
  const { dispatch, orderStatus } = useStoreon<State>('orderStatus');
  const { error, info, loading } = orderStatus;
  const { addSnack, removeSnack } = useSnackbar();
  const { sendSuccessResponseOrderStatusEvent, sendErrorOrderStatusEvent } = useOrderStatusAnalyticEvent();
  const { addRecaptchaToken } = useRecaptchaToken();

  useEffect(() => {
    if (error === OrderStatusError.INTERNAL_ERROR) {
      addSnack(getErrorSnack(snackId));
    }
  }, [addSnack, error]);

  useEffect(
    () => () => {
      removeSnack(snackId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (info && !loading) {
      sendSuccessResponseOrderStatusEvent(info.status.displayedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, loading]);

  useEffect(() => {
    if (error) {
      sendErrorOrderStatusEvent(OrderStatusErrorText[error]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const getOrderStatus = (props: Omit<OrderStatusInfoRequest, 'recaptchaToken'>) => {
    removeSnack(snackId);
    dispatch(FETCH_ORDER_STATUS.loading);

    addRecaptchaToken({
      action: 'get_order_status',
      data: props,
    })
      .then((data) => dispatch(FETCH_ORDER_STATUS.request, data))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Recaptcha is not available');
        dispatch(FETCH_ORDER_STATUS.failure, OrderStatusError.INTERNAL_ERROR);
      });
  };

  return {
    getOrderStatus,
    isLoading: orderStatus.loading,
  };
};
