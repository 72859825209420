import React, { useState } from 'react';
import clsx from 'clsx';
import {
  preloader,
  preloaderContent,
  skeleton,
  preloaderIcon,
  preloaderContentRow,
} from './suggestions-preloader-styles';

export const SuggestionPreloader = () => {
  const [items] = useState(Array.from({ length: 7 }, (_, i) => i));

  return (
    <div data-qa="suggestion-preloader">
      {items.map((_, i) => (
        <div key={`sp_${i}`} className={preloader}>
          <div className={clsx(preloaderIcon, skeleton)} />
          <div className={preloaderContent}>
            <div className={clsx(preloaderContentRow, skeleton)} />
            <div className={clsx(preloaderContentRow, skeleton)} />
          </div>
        </div>
      ))}
    </div>
  );
};
