import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

/** General hook to save changes to local/session storage. */
export function useStorage<T>(
  key: string,
  initialValue: T,
  storage: Storage,
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item !== null ? JSON.parse(item) : initialValue;
    } catch {
      return initialValue;
    }
  });

  const valueRef = useRef(storedValue);

  const setValue = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      valueRef.current = value instanceof Function ? value(valueRef.current) : value;
      try {
        storage.setItem(key, JSON.stringify(valueRef.current));
      } catch {
        /* empty */
      }
      setStoredValue(valueRef.current);
    },
    [key, storage],
  );

  return [storedValue, setValue];
}
