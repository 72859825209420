import Cookies from 'universal-cookie';
import { LANG, LangType } from 'common/constants';
import { CookiesStore } from 'reducers';

export const UTM_LABELS_COOKIE = 'utm_labels';

export const RESPONSE_HEADER_COOKIE = 'Set-Cookie';

export type CookiesData = string | CookiesStore['cookies'] | null;

export const createCookies = (data: CookiesData) => new Cookies(data);

export const getQaCookies = (cookie: string): string => {
  if (typeof cookie === 'string') {
    return cookie
      .split('; ')
      .filter((value) => value.includes('testId'))
      .join('; ');
  }

  return '';
};

export const getLangFromCookies = (cookies: Cookies): LangType => {
  const cookieLang = cookies?.get('lang')?.toLowerCase() ?? 'ru';

  return LANG[cookieLang] ? cookieLang : 'ru';
};
