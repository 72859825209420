import { css } from 'linaria';

export const wrapper = css`
  position: relative;
`;

export const counter = css`
  position: absolute;
  top: calc(0px - var(--space-75));
  right: calc(0px - var(--space-100));
`;
