import { styled } from 'linaria/react';
import '../../styles/base.css';

export const CssBaseline = styled.div`
  --layout-gap: 16px;
  --layout-inner-gap: 12px;
  --layout-columns-count: 24;
  --layout-min-width: 320px;
  --layout-width: 100%;
  --layout-max-width: 1180px;
  --layout-ears: calc(-1 * calc((100vw - var(--layout-width)) / 2));

  @media (min-width: 768px) {
    --layout-gap: 54px;
    --layout-width: 660px;
  }
  @media (min-width: 1024px) {
    --layout-gap: 42px;
    --layout-inner-gap: 20px;
    --layout-width: 940px;
  }

  @media (min-width: 1200px) {
    --layout-gap: 10px;
    --layout-width: var(--layout-max-width);
  }

  font-family:
    Roboto,
    apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
`;
