import { css } from 'linaria';

/*
  Fix for buttons, as they have different sizing rules in according to
  https://www.w3.org/TR/CSS22/visudet.html#block-replaced-width
 */
export const suggestionButton = css`
  width: calc(100% + var(--space-100));
  text-align: start;
`;

export const suggestion = css`
  display: block !important;
  padding: var(--space-75) var(--space-200) !important;
  border-width: 0;

  border-radius: 0 !important;

  &.active {
    --button-bg: var(--background-secondary);
    --button-hover-bg: var(--background-secondary);
  }
`;

export const suggestionText = css`
  white-space: normal !important;
`;

export const suggestionCaption = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

export const suggestionContentWrap = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: var(--space-200);
`;

export const suggestionIconWrap = css`
  display: flex;
  width: 40px;
  height: 40px;
  padding: var(--space-100);
  justify-content: center;
  align-items: center;
  border-radius: rounding(--rounding-m);
  border: 1px solid var(--control-minor);
`;

export const servicesSuggestionIconWrap = css`
  background-color: var(--service-primary);
`;
export const servicesSuggestionIcon = css`
  background-color: var(--service-light);
  align-self: flex-start;
`;

export const suggestionPromoIconWrap = css`
  padding: 0 !important;
`;

export const suggestionTextContentWrap = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--space-25);
  min-width: 0;
`;
