import { useState, useEffect, useCallback } from 'react';
import { Region } from 'types/model';

export type SetSuggestionsType = (regions: Region[]) => void;

export interface WithCitiesSuggestions {
  suggestions: Region[];
  setSuggestions: SetSuggestionsType;
  hasRegionNumberChanged: boolean;
  filteredCitiesList: (value: string) => Region[];
}

export type UseCitiesSuggestionsReturnType = [
  suggestions: WithCitiesSuggestions['suggestions'],
  setSuggestions: WithCitiesSuggestions['setSuggestions'],
  hasRegionNumberChanged: WithCitiesSuggestions['hasRegionNumberChanged'],
  filteredCitiesList: WithCitiesSuggestions['filteredCitiesList'],
];

/**
 *
 * @param cities Region[]
 * @returns { UseCitiesSuggestionsReturnType }
 */
export const useCitiesSuggestions = (cities: Region[]): UseCitiesSuggestionsReturnType => {
  const [suggestions, setSuggestions] = useState<Region[]>([]);
  const [hasRegionNumberChanged, setHasRegionNumberChanged] = useState(false);

  useEffect(() => {
    const isNumberOfRegionsDifferent = suggestions.length !== cities.length && !!suggestions.length;
    setHasRegionNumberChanged(isNumberOfRegionsDifferent);
  }, [cities, suggestions]);

  const filteredCitiesList = useCallback(
    (newVal: string) =>
      cities.filter((city) => city.regionName?.toLowerCase().startsWith(newVal.toLowerCase().trim())),
    [cities],
  );

  return [suggestions, setSuggestions, hasRegionNumberChanged, filteredCitiesList];
};
