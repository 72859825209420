import { analytics } from 'utils';

export const useAkinatorAnalyticsEvent = (elementText: string, searchQuery: string) => () => {
  analytics.sendEvent('GA:clickButton', {
    elementType: 'button',
    elementBlock: 'header', // блок, в котором расположен элемент
    elementName: 'searchAkinator', // условное обозначение
    elementText,
    eventVariant: searchQuery,
  });
};
