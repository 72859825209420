import type { FormValues } from '../components/types';

export const getClientOrderDTO = (values: FormValues) => {
  const { orderNumber, phoneNumber } = values;
  const formattedOrderNumber = orderNumber.replace(/^(mp|мр)/i, 'MP');
  const isMarketplaceOrder = !!formattedOrderNumber.match(/^MP/);
  const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

  return {
    isMarketplaceOrder,
    orderNumber: formattedOrderNumber,
    phone: formattedPhoneNumber,
  };
};
