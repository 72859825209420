import { HFModelData } from '../types/model';

export type ModelEvents = {
  'pageModel/FETCH_MODEL/init': { host: string; url: string };
  'pageModel/FETCH_MODEL/request': undefined;
  'pageModel/FETCH_MODEL/success': HFModelData;
  'pageModel/FETCH_MODEL/failure': Error;
  'pageModel/FETCH_MODEL/cancel': undefined;
  'pageModel/RESET': undefined;
};

export const FETCH_MODEL = {
  init: 'pageModel/FETCH_MODEL/init',
  request: 'pageModel/FETCH_MODEL/request',
  success: 'pageModel/FETCH_MODEL/success',
  failure: 'pageModel/FETCH_MODEL/failure',
  cancel: 'pageModel/FETCH_MODEL/cancel',
} as const;

export const MODEL_RESET = 'pageModel/RESET' as const;
