import { useCallback, useState } from 'react';
import { StoreonDispatch } from 'storeon';
import { osType } from 'selectors';
import { CookiesEvents, CookiesEventsInterface } from 'actions';
import { ONE_DAY_COOKIE_EXPIRED_DATE } from 'common/constants';

const cookieName = 'daysToHide';

export const useMobileAppLogic = (
  userAgent: string,
  daysToHide: string | undefined,
  cookies: Record<string, string>,
  dispatch: StoreonDispatch<CookiesEventsInterface>,
) => {
  const [showMobileBanner, setShowMobileBanner] = useState(!cookies[cookieName]);

  const hideMobileBanner = useCallback(() => {
    if (!daysToHide) return;

    setShowMobileBanner(false);

    dispatch(CookiesEvents.update, {
      key: cookieName,
      value: daysToHide,
      params: {
        expires: new Date(Date.now() + Number(daysToHide) * ONE_DAY_COOKIE_EXPIRED_DATE),
        sameSite: true,
      },
    });
  }, [daysToHide, dispatch]);

  return { showMobileBanner, hideMobileBanner, os: osType(userAgent) };
};
