import { Store } from '../store/types';
import { CITY_PICKER_MENU, HAMBURGER_MENU } from '../actions';

const initialState = {
  hamburgerMenuVisible: false,
  cityPickerVisible: false,
};

export const headerDrawers = (store: Store) => {
  store.on('@init', (state) => ({
    ...state,
    headerDrawers: initialState,
  }));

  store.on(HAMBURGER_MENU.openDrawer, (state) => ({
    ...state,
    headerDrawers: {
      hamburgerMenuVisible: true,
      cityPickerVisible: false,
    },
  }));

  store.on(HAMBURGER_MENU.closeDrawer, (state) => ({
    ...state,
    headerDrawers: { ...initialState },
  }));

  store.on(CITY_PICKER_MENU.openDrawer, (state) => ({
    ...state,
    headerDrawers: {
      hamburgerMenuVisible: false,
      cityPickerVisible: true,
    },
  }));

  store.on(CITY_PICKER_MENU.closeDrawer, (state) => ({
    ...state,
    headerDrawers: { ...initialState },
  }));
};

export type HeaderDrawersStore = {
  headerDrawers: {
    hamburgerMenuVisible: boolean;
    cityPickerVisible: boolean;
  };
};
