import React from 'react';
import { RegularButton } from 'fronton-react';
import { ListIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { useHeaderAnalyticsEvent } from '../../hooks';
import { menuButton } from './styles';

type Props = {
  onOpenMenuClick: () => void;
};

export const MenuButton = ({ onOpenMenuClick }: Props) => {
  const onMenuClickEvent = useHeaderAnalyticsEvent('sideMenu');

  const { t } = useTranslation();

  const onMenuClick = () => {
    onOpenMenuClick();
    onMenuClickEvent();
  };
  return (
    <RegularButton
      data-qa="header-menu"
      size="l"
      className={menuButton}
      variant="accent"
      withExtraClickZone
      iconOnly
      onClick={onMenuClick}
      label={t('header.main.menu-label')}
    >
      <ListIcon size="l" />
    </RegularButton>
  );
};
