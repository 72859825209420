import { InputState } from 'fronton-react/masked-input/types';

export const beforeMaskedStateChangeFunc = ({
  nextState,
  currentState,
}: {
  nextState: InputState;
  currentState?: InputState;
}) => {
  const currStateValue = currentState?.value;
  const enteredValue = nextState?.enteredString;

  // autofill with +7
  if (currStateValue?.length === 10) {
    return {
      value: `+7${currStateValue}`,
      selection: currentState?.selection,
    };
  }

  // autofill with 8
  if (currStateValue?.length === 11 && currStateValue.startsWith('8')) {
    return {
      value: currStateValue.replace('8', '+7'),
      selection: currentState?.selection,
    };
  }

  // paste value from buffer without +7 or 8
  if (enteredValue?.length === 10) {
    return {
      value: `+7${enteredValue}`,
      selection: currentState?.selection,
    };
  }

  // paste value from buffer with +7
  if (enteredValue?.startsWith('+7')) {
    return {
      value: enteredValue,
      selection: currentState?.selection,
    };
  }

  // paste value from buffer with 7
  if (enteredValue?.startsWith('7') && enteredValue?.replace(/\D/g, '')?.length === 11) {
    return {
      value: enteredValue.replace('7', '+7'),
      selection: currentState?.selection,
    };
  }

  // paste value from buffer with 8
  if (enteredValue?.startsWith('8') && enteredValue?.replace(/\D/g, '')?.length === 11) {
    return {
      value: enteredValue.replace('8', '+7'),
      selection: currentState?.selection,
    };
  }

  return {
    value: nextState.value,
    selection: nextState.selection,
  };
};
