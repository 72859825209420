export const SCRIPT_SRC =
  'https://storage.yandexcloud.net/cft/microfronts/header/footer/back/auth/refreshAuth.min.js';

export const loadRefreshScript = (refreshUrl?: string) => {
  if (refreshUrl) {
    window.authConfig = {
      endpointUrl: refreshUrl,
    };
    const { head } = document;
    if (head) {
      const exists = head.querySelector(`script[src='${SCRIPT_SRC}']`);
      if (!exists) {
        let el = document.createElement('script');
        el.async = true;
        el.src = SCRIPT_SRC;
        head.appendChild(el);
      }
    }
  }
};
