type GetScrollDirectionThis = {
  prev?: number;
};

export type Direction = 'top' | 'bottom';

export function createDirectionFn() {
  const context = {};
  return getScrollDirection.bind(context);
}

export function getScrollDirection(this: GetScrollDirectionThis, next: number): Direction {
  const { prev = 0 } = this;
  this.prev = next;
  return prev > next ? 'top' : 'bottom';
}

/**
 * Далее утилиты, которые упрощают тестирование
 */

export const getElementHeight = (element: HTMLElement) => element.getBoundingClientRect().height;
export const getVisibleCondition = (scrollY: number, height: number): boolean => scrollY <= height;
export const getHasClassName = (element: HTMLElement, className: string): boolean =>
  element.classList.contains(className);
export const addClassName = (element: HTMLElement, className: string) => {
  element.classList.add(className);
};

export const removeClassName = (element: HTMLElement, className: string) => {
  element.classList.remove(className);
};
