export function getLogo(logoUrl = '') {
  let url;

  try {
    url = new URL(logoUrl).href;
  } catch (e) {
    url = logoUrl ? `https://lemanapro.ru${logoUrl}` : '';
  }

  return url;
}
