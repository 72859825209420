import React, { forwardRef, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { ArrowLeftIcon } from '@fronton/icons-react';

import { Typography, RegularButton } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { DrawerHeaderProps } from './types';
import { header, back, adornment } from './styles';
import { ScrollContext } from './context';

/**
 * `<DrawerHeader>` - Обёртка для контента хэдера Drawer.
 * Предоставляет стили и логику для хедера.
 */
export const DrawerHeader = forwardRef<HTMLDivElement, DrawerHeaderProps>(
  ({ title, onClickBack, className, children, sticky, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const testId = props['data-testid'] || 'drawer-header';
    const { t } = useTranslation();
    const { top } = useContext(ScrollContext);
    const classNameHeader = useMemo(
      () =>
        clsx(
          header,
          {
            withAdornment: React.Children.count(children) > 0,
            sticky: !!sticky,
            shadow: !!top,
          },
          className,
        ),
      [className, sticky, children, top],
    );

    return (
      <header {...props} className={classNameHeader} ref={ref} data-testid={testId}>
        <RegularButton
          variant="pseudo"
          label={t('ui.drawer.back')}
          size="l"
          iconOnly
          className={back}
          rounded
          color="text-primary"
          data-testid={`${testId}-back`}
          onClick={onClickBack}
        >
          <ArrowLeftIcon />
        </RegularButton>

        <Typography variant="h3" data-testid={`${testId}-title`}>
          {title}
        </Typography>

        <div className={adornment} data-testid={`${testId}-adornment`}>
          {children}
        </div>
      </header>
    );
  },
);
