import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { useEffect } from 'react';
import { addCdpScript } from './add-cdp-script';

export function extractCdpParams(model: State['model']) {
  const cdpExperiment = model?.experiments?.find(({ internalId }) => internalId === 'cdp_script') || {
    externalId: '',
  };
  return cdpExperiment.externalId?.split(',') ?? [];
}

export const useCdpScript = () => {
  const { model } = useStoreon<State>('model');
  const [kitName, id, host] = extractCdpParams(model);

  useEffect(() => addCdpScript(kitName, id, host), [model, host, id, kitName]);
};
