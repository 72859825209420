import * as yup from 'yup';
import { orderRegExp, phoneRegExp } from 'common/constants/regexp';

export const validationSchema = yup.object().shape({
  orderNumber: yup
    .string()
    .required('Введите номер заказа')
    .matches(orderRegExp, 'Введите номер заказа в формате «MP1234567» или «111122223333»'),
  phoneNumber: yup
    .string()
    .trim()
    .required('Введите номер телефона')
    .matches(phoneRegExp, 'Введите корректный номер телефона'),
});
