import React from 'react';
import { SnackCloseButton, SnackView } from 'fronton-react';
import { ORDER_NOT_FOUND } from 'components/order-status/constants';
import { infoBar } from './styles';

type ErrorInfoBarProps = {
  isShow: boolean;
  onClose: () => void;
};
export const ErrorInfoBar = ({ isShow, onClose }: ErrorInfoBarProps) =>
  isShow ? (
    <SnackView
      className={infoBar}
      closeButton={<SnackCloseButton onClick={onClose} data-qa="error-info-bar-close-button" />}
      paragraph={ORDER_NOT_FOUND}
      data-qa="error-info-bar"
    />
  ) : null;
