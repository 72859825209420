import { RefObject, useEffect } from 'react';

/**
 * @description Фокусируется на элементе когда он полностью виден на экране. Исправляет баг автофокуса на Input.
 *  Если инпут не виден при автофокусе то при появлении нельзя сразу начать печатать
 * @param elementRef
 */
export const useOnIntersectionFocus = (elementRef: RefObject<HTMLElement>, prevent = false) => {
  const { current } = elementRef;

  useEffect(() => {
    let observer: IntersectionObserver;
    if (current && !prevent) {
      const options = {
        root: document.body,
        rootMargin: '0px',
        threshold: 1.0,
      };

      const callback = () => {
        current.focus();
      };

      observer = new globalThis.IntersectionObserver(callback, options);
      observer.observe(current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [current, prevent]);
};
