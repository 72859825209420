import React from 'react';
import clsx from 'clsx';
import { Loader, LoaderProps } from 'fronton-react';
import { ORDER_STATUS_ICON, OrderStatusType } from 'components/order-status/constants';
import { iconWrapper } from './styles';

type StatusIconProps = {
  type: OrderStatusType;
};

export const StatusIcon = ({ type }: StatusIconProps) => {
  const {
    backgroundColor,
    icon: Icon,
    iconColor,
    withBorder,
  } = ORDER_STATUS_ICON[type] ?? ORDER_STATUS_ICON[OrderStatusType.UNKNOWN];

  return (
    <div
      className={clsx(iconWrapper, { withBorder })}
      style={{ background: `var(--${backgroundColor})` }}
      data-qa="order-status-icon-wrapper"
    >
      {Icon ? <Icon color={iconColor} /> : <Loader size="s" variant={iconColor as LoaderProps['variant']} />}
    </div>
  );
};
