import React from 'react';
import { RegularButton } from 'fronton-react';
import { TrashIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { useAkinatorAnalyticsEvent } from './hooks/use-akinator-analytics-event';
import { expandButton } from './styles';
import { useSearchHistoryContext } from '../../hooks/use-search-history-context';

export const AkinatorClearHistoryButton: React.FC = () => {
  const { t } = useTranslation();
  const { clear } = useSearchHistoryContext();
  const sendAnalytics = useAkinatorAnalyticsEvent(`Очистить/весь`, '');

  return (
    <RegularButton
      variant="pseudo"
      size="s"
      iconLeft={<TrashIcon />}
      className={expandButton}
      onClick={() => {
        clear();
        sendAnalytics();
      }}
    >
      {t('header.searchbar.clear-history')}
    </RegularButton>
  );
};
