import React from 'react';
import { BaseButton } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { cx } from 'linaria';
import { useHeaderAnalyticsEvent } from 'components/header/hooks';
import { useTranslation } from 'react-i18next';
import { patchUrlByLocale } from 'utils';
import { root } from './styles';
import { LogoChanging } from '../logo-changing';

export interface LogoButtonProps {
  home: string;
  className?: string;
  showMenuButton?: boolean;
}

export const LogoButton = ({ home, className }: LogoButtonProps) => {
  const { path: pathname } = useStoreon<State>('path');
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');

  const onLogoClick = useHeaderAnalyticsEvent('logo');
  const { t } = useTranslation();

  // todo: make animated logo with gif

  return (
    <div
      className={cx(root, className)}
      itemScope
      itemType="https://schema.org/Organization"
      data-qa="header-logo"
    >
      <BaseButton
        as={home ? 'a' : 'div'}
        href={patchUrlByLocale({ url: home, pathname, lang })}
        onClick={onLogoClick}
        withExtraClickZone
        itemProp="url"
        data-position="header"
        data-qa="logo-button"
      >
        <div itemScope itemType="https://schema.org/Brand">
          <div data-qa="hidden-name" className="visually-hidden" itemProp="name">
            {t('ui.logo.new-name')}
          </div>
          <LogoChanging />
        </div>
      </BaseButton>
    </div>
  );
};
