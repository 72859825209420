import React, { HTMLAttributes, ReactElement, useEffect, useMemo, useState } from 'react';
import { LinkButton } from 'fronton-react';
import { IconComponent } from '@fronton/icons-react';
import clsx from 'clsx';
import { notActiveIconProps, activeIconProps } from './utils';
import { navButton, caption, iconWrapper } from './styles';

export interface MobileMenuLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  icon?: IconComponent | null;
  customIcon?: ReactElement | IconComponent | null;
  getIsActive: (href: string) => boolean;
  href: string;
  text: string;
  topBadge?: ReactElement;
}

export const MobileMenuLink = (props: MobileMenuLinkProps) => {
  const { icon: Icon, customIcon: CustomIcon, href, text, getIsActive, topBadge, ...rest } = props;
  const isActive = getIsActive(href);

  /**
   * WARN
   * Не работает на SSR
   * useLocation на сервере и клиенте возвращает разные результаты
   * что приводик к ошибке гидрации
   * init нужен для того, чтобы обеспечить
   * изменение параметров после гидрации
   */
  const [init, setInit] = useState(false);
  const [iconProps, setIconProps] = useState(notActiveIconProps);

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      setIconProps(isActive ? activeIconProps : notActiveIconProps);
    }
  }, [init, isActive]);

  const textClassNames = useMemo(
    () => (init ? clsx(caption, { active: isActive }) : caption),
    [init, isActive],
  );

  return (
    <LinkButton as="a" aria-label={text} className={navButton} href={href} {...rest}>
      <span className={iconWrapper}>
        {topBadge}
        {Icon && <Icon {...iconProps} size="l" />}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {CustomIcon && <CustomIcon {...iconProps} />}
      </span>
      <span className={textClassNames}>{text}</span>
    </LinkButton>
  );
};
