import React, { useRef } from 'react';
import { Modal, ModalHeader, ModalContent } from 'fronton-react';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { OrderStatusForm } from './order-status-form';
import { OrderStatusSnackbar } from './order-status-snackbar';
import { OrderStatusInfo } from './order-status-info';
import { useInfoBars, useOrderStatusModal } from '../hooks';
import { modalContent, modalBlock } from './styles';

export const OrderStatus = () => {
  const snackbarRef = useRef<HTMLDivElement>(null);
  const { isShow, closeOrderStatusModal } = useOrderStatusModal();
  const { isShowErrorInfoBar, closeErrorInfoBar } = useInfoBars();
  const match = useBreakpoints();
  const isMobile = match ? match.mobile : false;

  const handleOnClose = (event?: MouseEvent) => {
    if (snackbarRef.current?.lastElementChild?.contains(event?.target as Node)) {
      return;
    }

    closeOrderStatusModal();
  };

  return (
    <div ref={snackbarRef} data-qa="order-status">
      <Modal
        data-testid="order-status-modal"
        variant="curtain"
        size="s"
        show={isShow}
        onClose={handleOnClose}
        className={modalBlock}
      >
        <ModalHeader title="Проверить статус заказа" />
        <ModalContent className={modalContent} data-qa="order-status-modal-content">
          <OrderStatusForm
            isMobile={isMobile}
            isShowErrorInfoBar={isShowErrorInfoBar}
            closeErrorInfoBar={closeErrorInfoBar}
          />
          <OrderStatusInfo />
        </ModalContent>
      </Modal>
      {isShow && <OrderStatusSnackbar verticalPosition={isMobile ? 'top' : 'bottom'} />}
    </div>
  );
};
