import { css } from 'linaria';

export const wrapper = css`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 1040;
`;

export const root = css`
  display: grid;
  padding: var(--space-50) var(--space-100) calc(var(--space-50) + env(safe-area-inset-bottom));
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  background-color: var(--background-primary);
`;

export const dividerFix = css`
  margin: 0;
`;

export const navButton = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  text-decoration: none;
`;

export const caption = css`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02px;
  text-align: center;
  color: var(--text-minor);

  &.active {
    color: var(--text-primary);
  }
`;

export const iconWrapper = css`
  position: relative;
`;

export const topBadge = css`
  position: absolute;
  top: calc(0px - var(--space-50));
  left: 14px;
  z-index: 1;
`;

export const menuContent = css`
  padding-top: var(--space-400);
  padding-bottom: var(--space-200);
`;

export const entryMenuTitle = css`
  padding: var(--space-50) var(--space-200) !important;
`;
