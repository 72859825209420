import { css } from 'linaria';
import { drawerTransitionTime, drawerWidth, paddingDesktop, paddingMobile } from './ui-utils';

export * from './styles-transition';

export const header = css`
  display: inline-grid;
  align-items: center;
  padding-top: var(--space-250);
  margin-bottom: var(--space-400);

  grid-template-areas: '. back . title .';
  grid-template-columns:
    var(--space-250) auto
    calc(${paddingDesktop} / 2) 1fr calc(${paddingDesktop} / 2);

  background-color: var(--background-primary);

  &.sticky {
    position: sticky;
    top: 0;
  }

  &.withAdornment {
    grid-template-areas: '. back . title . adornment .';
    grid-template-columns:
      var(--space-250) auto calc(${paddingDesktop} / 2) 1fr calc(${paddingDesktop} / 2) auto
      calc(${paddingDesktop} / 2);

    .mobile & {
      grid-template-columns: ${paddingMobile} auto ${paddingMobile} 1fr ${paddingMobile} auto ${paddingMobile};
    }
  }

  &.shadow {
    @include shadowScroll(--box-shadow-m);
  }

  .mobile & {
    grid-template-columns: ${paddingMobile} auto ${paddingMobile} 1fr ${paddingMobile};
  }
`;

export const back = css`
  grid-area: back;
`;

export const footer = css`
  display: flex;
  justify-content: space-between;

  padding: calc(${paddingDesktop} * 0.75) ${paddingDesktop};
  background-color: var(--background-primary);

  &.sticky {
    position: sticky;
    bottom: 0;
  }

  .mobile & {
    padding: ${paddingMobile};
  }
`;

export const adornment = css`
  grid-area: adornment;

  &:empty {
    display: none;
  }
`;

export const drawer = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;

    background: var(--background-accent);
    opacity: var(--opacity-4);

    transition: opacity ${drawerTransitionTime} ease-out;
  }
`;

export const handler = css`
  display: flex;
  justify-content: flex-start;
  /* justify-content: flex-end; */
  flex: 1 0 0;
  width: 100%;
  height: 100%;
`;

export const modal = css`
  z-index: 10000;
  margin: 0;
  background-color: var(--background-primary);
  width: calc(100% - var(--space-600));
  @media (--from-tablet) {
    width: ${drawerWidth};
  }
`;

export const scrollWrapper = css`
  height: 100vh;
  height: -webkit-fill-available;
  overflow-y: auto;
`;

export const contentWrapper = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-width: -moz-available;
`;

export const content = css`
  flex: 1 0 0;
  padding: 0 ${paddingMobile};

  @media (--from-tablet) {
    padding: 0 ${paddingDesktop};
  }
`;
