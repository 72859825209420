import { useContext } from 'react';
import { SearchHistoryContext } from '../components/search-history-provider';

export type SearchHistoryItem = {
  title: string;
  url: string;
};

export const useSearchHistoryContext = () => {
  const context = useContext(SearchHistoryContext);

  if (!context) {
    throw new Error('useSearchHistoryContext has to be used within <SearchHistoryContext.Provider>');
  }

  return context;
};
