/* eslint-disable max-lines */
import React, { useCallback, useRef, useState, forwardRef } from 'react';
import { useUniqID } from 'fronton-react';
import clsx from 'clsx';
import { focusWithoutScrolling } from 'utils/focus-without-scroll';

import { useBodyScrollLock } from 'common/hooks/use-lock-body-scroll';
import { useHeaderAnalyticsEvent } from 'components/header/hooks';
import { Suggestions } from 'types/suggestions';
import { ProductRedirectionUrl } from 'types/product-url';
import { Region } from 'types/model';
import { useTranslation } from 'react-i18next';
import { searchBar, searchbarForm } from './styles';
import { SearchbarModal } from './searchbar-modal';

import { useModernSearchBarTransition } from '../hooks/use-modern-search-bar-transition';
import { PseudoInput } from './pseudo-input';
import { ModalOverlay } from './modal-overlay';
import { SearchBarInputPortal } from './searchbar-input-portal';
import { useSearchbarLogic } from '../hooks/use-searchbar-logic';

export type SearchbarProps = {
  logoUrl: string;
  searchHref: string;
  suggestions: Suggestions | null;
  getSuggestions: (searchString: string) => void;
  resetServices: () => void;
  className?: string;
  suggestionsLoading?: boolean;
  error?: boolean;
  notFoundMessage?: string | false;
  productRedirectionUrl?: ProductRedirectionUrl['redirectionUrl'];
  productRedirectionUrlLoading?: boolean;
  currentRegionId?: Region['regionId'];
  hasForm?: boolean;
};

export const Searchbar = forwardRef<HTMLDivElement, SearchbarProps>(
  (
    {
      className,
      searchHref,
      getSuggestions,
      resetServices,
      suggestionsLoading,
      error,
      suggestions,
      logoUrl,
      notFoundMessage,
      productRedirectionUrl,
      productRedirectionUrlLoading,
      currentRegionId,
      hasForm,
    },
    containerRef,
  ) => {
    const [expanded, setExpanded] = useState(false);
    const searchbarRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const suggestionsRef = useRef<HTMLDivElement>(null);

    const focusGroup = 'searchfield';

    const suggestionSelector = useUniqID();

    const pseudoInputRef = useRef<HTMLButtonElement>(null);

    const { t } = useTranslation();

    const expand = useCallback(() => setExpanded(true), []);
    const collapse = useCallback(() => {
      setExpanded(false);
      searchInputRef.current?.blur();
    }, [searchInputRef]);

    const [transitionState] = useModernSearchBarTransition({
      expanded,
      searchbarRef,
      containerRef: containerRef as React.MutableRefObject<HTMLElement | null>,
      suggestionsRef,
      suggestionSelector,
      inputSizeReference: pseudoInputRef,
    });

    const {
      onSubmit,
      isSrp,
      value,
      updateValue,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      index,
      selectedSuggestion: selectedItem,
      slicedSuggestions,
    } = useSearchbarLogic({
      suggestionsLoading,
      suggestions,
      collapse,
      transitionState,
      error,
      getSuggestions,
      resetServices,
      productRedirectionUrlLoading,
      productRedirectionUrl,
      currentRegionId,
    });

    useBodyScrollLock({
      ref: suggestionsRef,
      locked:
        transitionState === 'expanding' ||
        transitionState === 'expanded' ||
        transitionState === 'needsExpand',
    });

    const onPseudoInputEvent = useHeaderAnalyticsEvent('search');

    const onPseudoInputClick = () => {
      expand();
      if (searchInputRef.current) focusWithoutScrolling(searchInputRef.current);
      onPseudoInputEvent();
    };

    const onPseudoInputClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      updateValue('');
      expand();
      if (searchInputRef.current) focusWithoutScrolling(searchInputRef.current);
    };

    const formRef = useRef<HTMLFormElement>(null);

    const onSubmitRequest = () => {
      if (formRef && formRef.current) {
        // TODO: The requestSubmit method fires the onsubmit event
        formRef.current.requestSubmit();
      }
    };

    return (
      <div className={clsx(searchBar, className)} onKeyDown={onKeyDown} data-qa-header-searchbar="">
        <PseudoInput
          ref={pseudoInputRef}
          expanded={expanded}
          onClick={onPseudoInputClick}
          onClearClick={onPseudoInputClearClick}
          selectedItem={selectedItem}
          value={value}
          placeholder={t('header.main.search-placeholder')}
        />
        {hasForm && (
          <>
            <form
              ref={formRef}
              data-qa="search-form"
              id="headersearchform"
              onSubmit={onSubmit}
              className={searchbarForm}
              method="get"
              role="search"
            >
              {selectedItem && <input type="hidden" name="suggest" value="true" />}
            </form>
            <SearchBarInputPortal
              onSubmitRequest={onSubmitRequest}
              ref={searchbarRef}
              focusGroup={focusGroup}
              expanded={expanded}
              suggestions={suggestions}
              searchInputRef={searchInputRef}
              value={value}
              updateValue={updateValue}
              selectedItem={selectedItem}
              collapse={collapse}
              suggestionsLoading={suggestionsLoading}
              productRedirectionUrlLoading={productRedirectionUrlLoading}
              transitionState={transitionState}
            />
            <ModalOverlay expanded={expanded} onClose={collapse} />
            <SearchbarModal
              searchQuery={value}
              onSelectSuggestion={isSrp ? collapse : undefined}
              isSrp={isSrp}
              ref={suggestionsRef}
              suggestions={slicedSuggestions}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              fetchSuggestions={() => getSuggestions(value)}
              error={error}
              logoUrl={logoUrl}
              searchHref={searchHref}
              expanded={expanded}
              focusGroup={focusGroup}
              collapse={collapse}
              suggestionSelector={suggestionSelector}
              index={index}
              notFoundMessage={notFoundMessage}
              suggestionsLoading={suggestionsLoading}
            />
          </>
        )}
      </div>
    );
  },
);
