import { analytics } from 'utils/analytics';

export type HeaderAnalyticsElementText =
  | 'logo'
  | 'sideMenu'
  | 'search'
  | 'account'
  | 'shoppingList'
  | 'basket';

export const headerAnalyticsEventDetailBase = {
  elementType: 'button',
  elementBlock: 'header',
  elementName: 'header',
};

export const useHeaderAnalyticsEvent = (elementText: HeaderAnalyticsElementText) => () =>
  analytics.sendEvent('GA:clickButton', {
    ...headerAnalyticsEventDetailBase,
    elementText,
  });
