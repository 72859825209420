import React, { memo, useCallback, useMemo } from 'react';
import { RegularButton, Typography, IconButton } from 'fronton-react';
import { useEffectOnce } from 'common/hooks';
import { analytics } from 'utils/analytics';
import { XIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { close, description, install, installCloseWrap, logoTextWrap, mobileApp } from './styles';
import { Logo } from './logo';
import { useByOsType } from './hooks/use-by-os-type';

export type MobileAppContentProps = {
  title: string;
  androidTitle: string;
  androidLink: string;
  androidRating: string;
  iosTitle: string;
  iosLink: string;
  iosRating: string;
  shouldDisplay: boolean;
  hide: () => void;
  os: 'ios' | 'android' | null;
};

export const MobileAppContent = memo<MobileAppContentProps>(
  ({ androidLink, androidTitle, iosLink, hide, shouldDisplay, iosTitle, os }) => {
    const byOsType = useByOsType({
      androidLink,
      androidTitle,
      iosLink,
      iosTitle,
    });

    const { t } = useTranslation();

    const promotions = useMemo(
      () => ({
        position: 'not set',
        creative: os ?? 'not set',
      }),
      [os],
    );

    useEffectOnce(() => {
      if (shouldDisplay) analytics.sendEvent('GA:view', { ...promotions, name: 'go-to-mobile-app-sticker' });
    });

    const handleClose = useCallback(() => {
      hide();
      analytics.sendEvent('GA:click', { ...promotions, name: 'close-mobile-app-sticker' });
    }, [hide, promotions]);

    const onInstall = useCallback(() => {
      hide();
      analytics.sendEvent('GA:clickButton', {
        elementType: 'button',
        elementBlock: 'header',
        elementName: 'smartAppBanner',
        elementText: 'Установить',
      });
    }, [hide]);

    if (!shouldDisplay || !os) return null;

    return (
      <div className={mobileApp} data-qa-header-mobile-app="">
        <div className={logoTextWrap}>
          <Logo />
          <Typography
            color="text-primary"
            className={description}
            variant="caption_accent"
            data-qa-header-mobile-app-caption=""
          >
            {t('header.app.message')}
          </Typography>
        </div>

        <div className={installCloseWrap}>
          <RegularButton
            as="a"
            onClick={onInstall}
            href={byOsType[os].link}
            className={install}
            data-qa="mobile-app-install"
            data-qa-header-mobile-app-install=""
            variant="accent"
            rounded
          >
            {t('header.app.action')}
          </RegularButton>

          <IconButton
            onClick={handleClose}
            className={close}
            size="s"
            aria-label={t('header.app.close')}
            data-qa="close-mobile-app"
            data-qa-header-mobile-app-close=""
            withExtraClickZone
          >
            <XIcon type="outline" size="l" color="text-primary" />
          </IconButton>
        </div>
      </div>
    );
  },
);
