import { useState } from 'react';
import { isBrowser } from 'fronton-react/utils';
import { useIsomorphicLayoutEffect } from 'fronton-react';
import { strictBreakpoints } from '../../styles/breakpoints';
import { capitalize } from '../../utils/capitalize';

export type Match = {
  smMobile: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  largeDesktop: boolean;
};

/**
 * Хук, который возвращает match по брейкпойнтам, и следит за их изменением.
 *
 * @return {Match | null} null - если рендер происходит в ssr режиме
 */
export function useBreakpoints() {
  const [match, setMatch] = useState<Match | null>(
    !isBrowser()
      ? null
      : getMediaQueries().reduce((acc, { key, query }) => ({ ...acc, [key]: query.matches }), {} as Match),
  );

  useIsomorphicLayoutEffect(() => {
    const unsubs = getMediaQueries().map(({ key, subscribe }) =>
      subscribe(({ matches }) => setMatch((prev) => ({ ...(prev ?? initialMatch), [key]: matches }))),
    );

    return () => unsubs.forEach((v) => v());
  }, []);

  return match;
}

let cachedMediaQuery:
  | {
      key: MatchKey;
      query: MediaQueryList;
      subscribe: (listener: (event: MediaQueryListEvent) => void) => () => void;
    }[]
  | null = null;

const getMediaQueries = () => {
  if (cachedMediaQuery === null) {
    cachedMediaQuery = Object.entries(strictBreakpoints).map(([key, value]) => {
      const query = window.matchMedia(value);

      return {
        /**
         * Ключ из объекта Matches
         */
        key: createMatchKeyFromBreakpointKey(key),
        /**
         * Объект MediaQuery
         */
        query,
        /**
         * Функция подписки на изменения брейкпойнтов
         */
        subscribe: (listener: (event: MediaQueryListEvent) => void): (() => void) => {
          let supportModernApi;

          // Safari makes MediaQueryList object private, so we can't check support of modern api
          try {
            supportModernApi = 'addEventListener' in MediaQueryList.prototype;
          } catch (e) {
            supportModernApi = false;
          }

          if (supportModernApi) {
            query.addEventListener('change', listener, { passive: true });
            return () => query.removeEventListener('change', listener);
          }

          query.addListener(listener);
          return () => query.removeListener(listener);
        },
      };
    });
  }
  return cachedMediaQuery;
};

const initialMatch: Match = {
  smMobile: false,
  mobile: false,
  tablet: false,
  desktop: false,
  largeDesktop: false,
};

type MatchKey = keyof Match;

/**
 * Трансформирует ключ вида "--only-large-desktop" в largeDesktop
 */
function createMatchKeyFromBreakpointKey(breakpointKey: string): MatchKey {
  const maybeMatchKey = breakpointKey
    .replace('--only-', '')
    .split('-')
    .map((v, index) => (index > 0 ? capitalize(v) : v))
    .join('');

  if (isMatchKey(maybeMatchKey)) {
    return maybeMatchKey;
  }

  throw new Error(
    `Invalid parsing: ${breakpointKey} -> ${maybeMatchKey}. ${maybeMatchKey} doesn't exists in ${JSON.stringify(
      initialMatch,
    )}`,
  );
}

const isMatchKey = (key: string): key is MatchKey => key in initialMatch;
