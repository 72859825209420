export type GlobalEventsInterface = {
  'globalEvents/setRequestParams': {
    orchestratorHost: string;
    orchestratorHostKz: string;
    useCredentials: boolean;
    apiKey?: string;
  };
  'globalEvents/setRequestID': { requestID: string };
  'globalEvents/isBot': boolean;
  'globalEvents/userAgent': string;
  'globalEvents/pathName': string;
  'globalEvents/logoProUrl': string;
  'globalEvents/setHost': string;
};

export const GlobalEvents = {
  setRequestParams: 'globalEvents/setRequestParams',
  setRequestID: 'globalEvents/setRequestID',
  setUserAgent: 'globalEvents/userAgent',
  setIsBot: 'globalEvents/isBot',
  setPathname: 'globalEvents/pathName',
  setLogoProUrl: 'globalEvents/logoProUrl',
  setHost: 'globalEvents/Host',
} as const;
