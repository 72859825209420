/* eslint-disable max-lines */
import { css } from 'linaria';
import { searchOverlay, aboveTooltip } from 'styles/z-indexs';

export const pseudoInputContainer = css`
  width: 100%;
  position: relative;
`;

export const pseudoInputContent = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.05px;
`;

export const pseudoInputClear = css`
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: var(--space-125);
`;

export const pseudoInput = css`
  display: flex;
  justify-content: flex-start !important;
  column-gap: var(--space-75);
  padding: var(--space-125) !important;
  border-radius: var(--rounding-m) !important;
  width: 100%;
  background-color: var(--control-minor) !important;
  color: var(--text-primary_opacity-8);

  @media (hover: hover) {
    &:hover {
      background-color: var(--control-hover-minor) !important;
    }
  }

  &[hidden] {
    opacity: 0;
  }
`;

export const searchbarInputContainer = css`
  width: 100%;
  top: var(--space-150);
  left: var(--space-150);
`;

export const searchbarInputIconDecoration = css`
  position: absolute !important;
  top: var(--space-150);
  left: var(--space-150);
  transform: translateY(var(--space-25));
`;

export const textWrap = css`
  display: inline-grid;
  text-align: start;
`;

export const searchbarInput = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05px;
  padding: var(--space-150) var(--space-925) var(--space-150) var(--space-475);

  width: 100%;
  font-weight: 400;
  font-family: inherit;
  border: none;
  border-radius: var(--rounding-m);
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out,
    transform 0.3s cubic-bezier(0.24, 0.81, 0.2, 0.96);
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-primary);
  background-color: var(--control-minor) !important;
  caret-color: var(--brand-dark);

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    font-family: inherit;
    line-height: 24px;
    color: var(--text-primary);
    transition: color 0.3s ease-out;
  }

  &::-moz-placeholder {
    font-weight: 400;
    font-size: 16px;
    font-family: inherit;
    line-height: 24px;
    color: var(--text-primary);
    transition: color 0.3s ease-out;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &:-ms-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    font-family: inherit;
    line-height: 24px;
    color: var(--text-primary);
    transition: color 0.3s ease-out;
  }

  &:focus {
    outline: none;
  }
`;

export const searchbarInputExpanded = css`
  background-color: var(--control-primary-invert) !important;
  padding-left: var(--space-150);
  box-shadow: var(--box-shadow-l);
  &::placeholder {
    color: var(--text-minor) !important;
  }

  @media (--only-mobile) {
    background-color: var(--background-primary) !important;
    box-shadow: none !important;
    padding: var(--space-150) 100px var(--space-150) 0 !important;
  }
`;

export const searchClear = css`
  display: none !important;
  align-items: center;

  &.show {
    display: flex !important;
  }

  @media (hover: hover) {
    &:hover {
      background: none;
    }
  }
`;

export const searchClearDecorateTransition = css`
  transform: translateX(-8px);
`;

export const pseudoInputIcon = css`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 0 0 auto;
`;

export const modalOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  width: 100vw;
  height: 100vh;

  background: var(--background-accent);
  z-index: ${searchOverlay};
`;

export const active = css`
  opacity: var(--opacity-4);
  display: block;
`;

export const searchBar = css`
  position: relative;
  height: 48px;
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const searchbarForm = css``;

export const searchbarInputWrap = css`
  position: fixed;
  /* to disable scroll on focus in safari */
  top: 145px;
  left: -9999px;
  z-index: -1;
  display: flex;
  align-items: center;
  will-change: left, top, width, transform;

  @media (--from-desktop) {
    top: 20px;
  }
`;

export const suggestionsContainer = css`
  position: fixed;
  z-index: ${aboveTooltip + 1};
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  background: var(--background-primary);
  border-radius: var(--rounding-m);
  box-shadow: var(--box-shadow-m);
  padding-top: var(--space-100);
  padding-bottom: var(--space-100);

  @media (--only-mobile) {
    bottom: 0;
    padding-top: var(--space-900);
    border-radius: unset;
    height: 100vh;
  }
`;

export const inputButtonsPlacement = css`
  position: absolute;
  right: var(--space-50) !important;
  display: flex;
  column-gap: var(--space-100);
  align-items: stretch;
`;

export const suggestionsRoot = css`
  display: none;
  background: var(--background-primary);
`;
export const suggestionsActive = css`
  display: block;
`;

export const errorContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--space-900);
  color: var(--text-secondary);
`;

export const errorTitle = css`
  margin-bottom: var(--space-200);
  text-align: center;
`;
