import { replaceSimbol } from 'common/utils';

export const getNextSearchInputValue = (prev: string, valueFromEvent: string): string => {
  if (prev.length < valueFromEvent.length) {
    const lastChar = valueFromEvent.slice(-1);
    return prev + (replaceSimbol(lastChar) || lastChar).toLocaleLowerCase();
  }

  return valueFromEvent;
};
