import React from 'react';
import { Typography } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { StatusIcon } from './components/status-icon';
import { descriptionBlock, statusBlock, wrapper } from './styles';
import { ORDER_STATUS_TEXT } from '../../constants';
import { getDeliveryDateText, getOrderTitle } from '../../utils';

export const OrderStatusInfo = () => {
  const { orderStatus } = useStoreon<State>('orderStatus');
  const { info } = orderStatus;

  if (!info) {
    return null;
  }

  const { type: statusType, displayedText } = info.status;
  const { textColor } = ORDER_STATUS_TEXT[statusType] ?? {};

  const orderTitle = getOrderTitle(info.createdDate);
  const orderDeliveryDateText = getDeliveryDateText(info.deliveryInfo);

  return (
    <div className={wrapper}>
      <Typography size="subtitle" variant="l" data-qa="order-status-title">
        {orderTitle}
      </Typography>
      <div className={statusBlock}>
        <StatusIcon type={statusType} />
        <Typography size="body_accent" variant="m" color={textColor} data-qa="order-displayed-status-text">
          {displayedText}
        </Typography>
      </div>
      <div className={descriptionBlock}>
        {orderDeliveryDateText && (
          <Typography size="body_long" variant="s" data-qa="order-status-delivery-date">
            {orderDeliveryDateText}
          </Typography>
        )}
      </div>
    </div>
  );
};
