import { debounce } from 'debounce';
import { useMemo } from 'react';

export type Params = {
  interval?: number;
  immediate?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounced = <A extends (...args: any) => ReturnType<A>>(
  func: A,
  params: Params = { immediate: false, interval: 0 },
) =>
  useMemo(
    () => debounce(func, params.interval, params.immediate),
    [params.interval, params.immediate, func],
  );
