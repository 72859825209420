import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@fronton/icons-react';
import { Suggestion } from 'types/suggestions';
import {
  searchbarInput,
  searchbarInputContainer,
  searchbarInputExpanded,
  searchbarInputIconDecoration,
} from './styles';
import { useMobileCollapse } from '../hooks';

export interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef: React.RefObject<HTMLInputElement>;
  expanded: boolean;
  value: string;
  selectedItem: NonNullable<Suggestion> | null;
  collapse: () => void;
}

export const SEARCH_INPUT_QA = 'search-input';

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, innerRef) => {
  const { expanded, selectedItem, value, collapse, autoFocus, inputRef, ...rest } = props;

  const { t } = useTranslation();

  useMobileCollapse({
    collapse,
    ref: inputRef,
  });

  return (
    <div className={searchbarInputContainer} ref={innerRef}>
      {!expanded && <MagnifyingGlassIcon className={searchbarInputIconDecoration} color="text-primary" />}
      <input
        ref={inputRef}
        form="headersearchform"
        className={clsx(searchbarInput, { [searchbarInputExpanded]: expanded })}
        type="search"
        name="q"
        value={value}
        {...rest}
        placeholder={t('header.searchbar.search')}
        aria-hidden={!expanded}
        tabIndex={expanded ? 0 : -1}
        aria-label={t('header.searchbar.search')}
        aria-autocomplete="both"
        aria-controls="srp_search-listbox"
        id="srp_search-input"
        data-qa-header-search-input=""
        aria-activedescendant={selectedItem?.id ?? ''}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck={false}
        data-qa={SEARCH_INPUT_QA}
      />
    </div>
  );
});
