export enum OrderStatusType {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  ACCEPTED = 'ACCEPTED',
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  PICKING_IN_PROGRESS = 'PICKING_IN_PROGRESS',
  PICKED_WAITING_FOR_PAYMENT = 'PICKED_WAITING_FOR_PAYMENT',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  ON_DELIVERY = 'ON_DELIVERY',
  ON_COURIER_DELIVERY = 'ON_COURIER_DELIVERY',
  DELIVERED = 'DELIVERED',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
  RESTOCKING = 'RESTOCKING',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  DELIVERED_TO_PUP = 'DELIVERED_TO_PUP',
  READY_FOR_GIVEAWAY = 'READY_FOR_GIVEAWAY',
  GIVEN_AWAY = 'GIVEN_AWAY',
  PARTIALLY_GIVEN_AWAY = 'PARTIALLY_GIVEN_AWAY',
  IN_EXECUTION = 'IN_EXECUTION',
  PICKED = 'PICKED',
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN',
}
