import { HFModelData } from 'types/model';
import { getDomain } from 'utils';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { useSiteDomain } from '../components/searchbar/hooks/use-site-domain';

export const ORDER_STATUS_ON_EXPERIMENT = 'pcp_order_status';

export const useOrderStatusOn = (model: HFModelData): boolean => {
  const { host } = useStoreon<State>('host');
  const domain = getDomain(host);
  const { isRuDomain } = useSiteDomain(domain);
  return (
    isRuDomain &&
    Boolean(model.experiments?.find((experiment) => experiment.internalId === ORDER_STATUS_ON_EXPERIMENT))
  );
};
