import { isBrowser } from 'fronton-react/utils';

if (isBrowser() && typeof Element.prototype.animate !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('web-animations-js');
}

export function animate(
  element: Element,
  keyframes: Keyframe[] | PropertyIndexedKeyframes | null,
  options: number | KeyframeAnimationOptions,
) {
  const animation = element.animate(keyframes, options);

  if (!animation.finished) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    animation.finished = new Promise((res) => {
      animation.addEventListener('finish', res, { once: true });
    });
  }

  return animation;
}
