import { useStoreon } from 'storeon/react';
import { Experiment } from 'types/model';
import { HIDE_REGION_INTERNAL_ID } from 'common/constants';

export function useHideRegions() {
  const { model } = useStoreon('model');

  return (
    model?.experiments?.some(
      (experiment: Experiment) => experiment.internalId === HIDE_REGION_INTERNAL_ID,
    ) ?? false
  );
}
