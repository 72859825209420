import React, { forwardRef } from 'react';
import { Adaptive, Portal } from 'fronton-react';
import FocusLock from 'react-focus-lock';
import { Suggestion, Suggestions } from 'types/suggestions';
import { focusWithoutScrolling } from 'utils/focus-without-scroll';
import { searchbarInputWrap, inputButtonsPlacement } from './styles';
import { TransitionState } from '../types';
import { SearchInput } from './search-input';
import { ClearButton } from './clear-button';
import { SubmitButton } from './submit-button';
import { CloseButton } from './close-button';

export interface SearchBarInputPortalProps {
  focusGroup: string;
  expanded: boolean;
  suggestions: Suggestions | null;
  searchInputRef: React.RefObject<HTMLInputElement>;
  value: string;
  updateValue: (value: string) => void;
  selectedItem: NonNullable<Suggestion> | null;
  collapse: () => void;
  suggestionsLoading?: boolean;
  productRedirectionUrlLoading?: boolean;
  transitionState: TransitionState;
  onSubmitRequest: () => void;
}

export const SearchBarInputPortal = forwardRef<HTMLDivElement, SearchBarInputPortalProps>(
  (props, searchbarRef) => {
    const {
      focusGroup,
      expanded,
      suggestions,
      searchInputRef,
      value,
      updateValue,
      selectedItem,
      collapse,
      onSubmitRequest,
      transitionState,
    } = props;

    return (
      <Portal>
        <FocusLock
          group={focusGroup}
          disabled={!expanded}
          autoFocus={false}
          returnFocus
          lockProps={{ role: 'dialog', 'aria-modal': 'true', 'aria-label': 'Поиск по сайту' }}
        >
          <div
            role="combobox"
            aria-controls="srp_search-listbox"
            aria-expanded={
              expanded && suggestions
                ? suggestions.products.length > 0 || suggestions.services.length > 0
                : false
            }
            aria-owns="srp_search-listbox"
            aria-haspopup="listbox"
            id="srp_search-combobox"
            ref={searchbarRef}
            data-qa-header-search-combobox=""
            className={searchbarInputWrap}
          >
            <SearchInput
              inputRef={searchInputRef}
              expanded={expanded}
              value={value}
              onChange={(e) => updateValue(e.target.value)}
              selectedItem={selectedItem}
              collapse={collapse}
            />

            <div className={inputButtonsPlacement}>
              <ClearButton
                onClick={(e) => {
                  e.preventDefault();
                  updateValue('');
                  if (searchInputRef.current) focusWithoutScrolling(searchInputRef.current);
                }}
                translated={!expanded}
                selectedItem={selectedItem}
                value={value}
                data-qa-header-search-clear=""
                data-qa="clear-button"
              />

              <Adaptive only="mobile">{expanded && <CloseButton onClick={collapse} />}</Adaptive>
              <Adaptive from="tablet">
                {transitionState !== 'collapsing' && <SubmitButton onSubmitRequest={onSubmitRequest} />}
              </Adaptive>
            </div>
          </div>
        </FocusLock>
      </Portal>
    );
  },
);
