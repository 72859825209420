import { StoreonDispatch } from 'storeon';
import { useCallback } from 'react';
import { FETCH_SUGGESTIONS, SUGGESTIONS_RESET_SERVICES, SuggestionsEvents } from 'actions';
import { SuggestionsRequest } from 'types/suggestions';

export const useSearchbarLogic = (dispatch: StoreonDispatch<SuggestionsEvents>) => {
  const getSuggestions = useCallback(
    (props: SuggestionsRequest) => {
      dispatch(FETCH_SUGGESTIONS.init, props);
    },
    [dispatch],
  );

  const resetServices = useCallback(() => {
    dispatch(SUGGESTIONS_RESET_SERVICES);
  }, [dispatch]);

  return { getSuggestions, resetServices };
};
