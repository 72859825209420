import { useEffect } from 'react';

/**
 * @name useVirtualKeyboard
 * @description Хук для виртуальной клавиатуры на iOS и Android,
 * чтобы не перекрывался контент при использовании аналогичных хуков для скрытия контента под клавиатуру.
 */
export const useVirtualKeyboard = () => {
  useEffect(() => {
    let savedOverlaysContentValue: boolean | null = null;
    const hasVirtualKeyboard = navigator && 'virtualKeyboard' in navigator;

    if (hasVirtualKeyboard) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      savedOverlaysContentValue = navigator.virtualKeyboard.overlaysContent;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.virtualKeyboard.overlaysContent = false;
    }

    return () => {
      if (hasVirtualKeyboard) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigator.virtualKeyboard.overlaysContent = savedOverlaysContentValue;
      }
    };
  }, []);
};
