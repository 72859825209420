import React, { RefCallback } from 'react';

type Ref<T> = React.MutableRefObject<T | null> | RefCallback<T | null> | null;

export function mergeRefs<T extends HTMLElement>(refs: Ref<T>[]) {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref !== null) {
        ref.current = instance;
      }
    });
  };
}
