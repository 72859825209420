import { css } from 'linaria';

export const captionBlock = css`
  margin-top: var(--space-300);
`;

export const form = css`
  display: flex;
  flex-direction: column;
`;

export const fieldsWrapper = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--space-400);
  margin-top: var(--space-100);
`;

export const submitButton = css`
  margin-top: var(--space-400);
`;

export const whiteBackground = css`
  background-color: var(--background-primary);
`;

export const modalContent = css`
  margin-bottom: var(--space-500);
`;

export const modalBlock = css`
  height: auto !important;
`;
