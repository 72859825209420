import { useCallback } from 'react';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';
import { HAMBURGER_MENU } from 'actions';

export interface UseHamburgerMenuStateReturnType {
  show: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
}

export const useHamburgerMenuState = (): UseHamburgerMenuStateReturnType => {
  const { dispatch, headerDrawers } = useStoreon<State>('headerDrawers');

  const onMenuOpen = useCallback(() => dispatch(HAMBURGER_MENU.openDrawer), [dispatch]);
  const onMenuClose = useCallback(() => dispatch(HAMBURGER_MENU.closeDrawer), [dispatch]);

  return {
    show: headerDrawers.hamburgerMenuVisible,
    onMenuClose,
    onMenuOpen,
  };
};
