import { useStoreon } from 'storeon/react';
import { Experiment } from 'types/model';

// TODO: Проверяем, существует ли эксперимент с входящим experimentId в списке экспериментов
export const useHideSomeExperiment = (experimentId: string): boolean => {
  const { model } = useStoreon('model');
  const isHaveExperiment = ({ internalId }: Experiment) => internalId === experimentId;

  return !!model?.experiments?.some(isHaveExperiment);
};
