import { useCallback, useEffect } from 'react';
import { ForwardRefInstance } from './utils';

export interface UseMobileCollapseParams<T extends HTMLElement> {
  ref: ForwardRefInstance<T>;
  collapse: () => void;
}

/**
 * @name useMobileCollapse
 * @description Хук для события скрытия виртуальной клавиатуры на iOS и Android
 * @param { UseMobileCollapseParams<T extends HTMLElement> }
 */
export const useMobileCollapse = <T extends HTMLElement = HTMLElement>({
  ref,
  collapse,
}: UseMobileCollapseParams<T>) => {
  // Обработка закрытия виртуальной клавиатуры на андроиде
  const onVirtualKeyboardCollapse = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (event) => {
      const { height } = event.target.boundingRect;
      if (height === 0) {
        collapse();
      }
    },
    [collapse],
  );

  useEffect(() => {
    if (navigator && 'virtualKeyboard' in navigator) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.virtualKeyboard.overlaysContent = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.virtualKeyboard.addEventListener('geometrychange', onVirtualKeyboardCollapse);
    }

    return () => {
      if (navigator && 'virtualKeyboard' in navigator) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigator.virtualKeyboard.overlaysContent = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigator.virtualKeyboard.removeEventListener('geometrychange', onVirtualKeyboardCollapse);
      }
    };
  }, [ref, collapse, onVirtualKeyboardCollapse]);
};
