import { isBrowser } from 'fronton-react/utils';

const STORAGE_KEY = 'enabledLogAnalytics';

/**
 * Send events to analytics
 * @class
 * @public
 */

export class Analytics {
  hasFlagLogAnalytics: string | null = null;

  constructor() {
    if (isBrowser()) {
      this.hasFlagLogAnalytics = localStorage.getItem(STORAGE_KEY);

      if (!this.hasFlagLogAnalytics) {
        localStorage.setItem(STORAGE_KEY, '');
      }
    }
  }

  /**
   * Send event with params
   * @param {string} event name of event
   * @param {*} params any data
   * @public
   */

  public sendEvent(event: string, params: unknown) {
    if (!!this.hasFlagLogAnalytics) {
      // eslint-disable-next-line no-console
      console.log(event, params);
    }

    document.dispatchEvent(new CustomEvent(event, { detail: params }));
  }
}

export const analytics = new Analytics();
