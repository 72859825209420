import React from 'react';
import { RegularButton, Typography } from 'fronton-react';
import clsx from 'clsx';
import { capitalize } from 'utils/capitalize';
import { ProductSuggestion } from 'types/suggestions';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@fronton/icons-react';
import { patchUrlByLocale } from 'utils';
import { useSuggestionsButtonAction } from '../../hooks/use-suggestions-button-action';
import {
  suggestion,
  suggestionButton,
  suggestionText,
  suggestionContentWrap,
  suggestionIconWrap,
} from './suggestion-list-item-styles';

export interface SuggestionListItemProductProps {
  elementIndex: number;
  navigationIndex: number;
  suggestionSelector: string;
  isSrp: boolean;
  productSuggestion: ProductSuggestion;
  searchHref: string;
  buttonAction: ReturnType<typeof useSuggestionsButtonAction>;
}

export const SuggestionListItemProduct: React.FC<SuggestionListItemProductProps> = ({
  elementIndex,
  navigationIndex,
  suggestionSelector,
  isSrp,
  productSuggestion,
  searchHref,
  buttonAction,
}) => {
  const { phrase, id } = productSuggestion;

  const { t } = useTranslation();

  const link = patchUrlByLocale({ url: `${searchHref}?q=${phrase}&suggest=true` });

  const buttonParams = {
    ...buttonAction(
      elementIndex,
      link,
      {
        block: t('header.searchbar.products'),
        index: elementIndex,
        text: phrase,
      },
      capitalize(phrase),
      link,
    ),
  };

  return (
    <div
      key={id}
      role="option"
      id={id}
      aria-selected={elementIndex === navigationIndex}
      data-qa-header-search-suggestion=""
    >
      <RegularButton
        variant="pseudo"
        tabIndex={-1}
        className={clsx(suggestionSelector, suggestion, {
          active: elementIndex === navigationIndex,
          [suggestionButton]: isSrp,
        })}
        key={id}
        data-qa="suggestion-button"
        {...buttonParams}
        {...(!isSrp && { href: link })}
      >
        <div className={suggestionContentWrap}>
          <div className={suggestionIconWrap}>
            <MagnifyingGlassIcon type="outline" size="l" color="text-minor" />
          </div>
          <Typography variant="m" size="body_short" className={suggestionText} color="text-primary">
            {capitalize(phrase)}
          </Typography>
        </div>
      </RegularButton>
    </div>
  );
};
