import React from 'react';
import { useSnackbar, Snackbar, Snack, InputSnackProps, RegularButton } from 'fronton-react';
import { orderStatusSnackIdRegexp } from 'components/order-status/constants/snack';

type SnackActionType = () => void;

const isAction = (action: unknown): action is SnackActionType =>
  Boolean(action) && typeof action === 'function';

export interface SnackProps extends InputSnackProps {
  action: SnackActionType;
  button?: string;
  buttonClassName?: string;
}

export const HeaderSnackbar = () => {
  const { state, removeSnack } = useSnackbar();

  return (
    <Snackbar>
      {(state as SnackProps[])
        .filter(({ id }) => !orderStatusSnackIdRegexp.test(id))
        .map(
          ({ id, title, content, icon, variant, button, time, wide, action, buttonClassName, testId }) => (
            <Snack
              data-qa={testId}
              key={id}
              id={id}
              header={title}
              paragraph={content}
              icon={icon}
              variant={variant}
              wideButton={wide}
              autoHideTimer={time ? 3000 : undefined}
            >
              {button && (
                <RegularButton
                  size="s"
                  invert
                  className={buttonClassName}
                  onClick={() => {
                    if (isAction(action)) {
                      action();
                    }
                    removeSnack(id);
                  }}
                >
                  {button}
                </RegularButton>
              )}
            </Snack>
          ),
        )}
    </Snackbar>
  );
};
