import { GlobalRouter, Router } from '@okapi/router-react';
import React from 'react';
import { ThemeProvider } from 'fronton-react';
import { StoreContext } from 'storeon/react';
import { CssBaseline } from 'components/css-baseline';
import { Store } from 'store/types';
import { HeaderComponent } from 'components/header';

export type HeaderProps = {
  router: GlobalRouter;
  store: Store;
  disableSticky?: boolean;
};

export const Header: React.FC<HeaderProps> = ({ router, store, disableSticky }) => (
  <Router router={router}>
    <CssBaseline>
      <ThemeProvider>
        <StoreContext.Provider value={store}>
          <HeaderComponent disableSticky={!!disableSticky} />
        </StoreContext.Provider>
      </ThemeProvider>
    </CssBaseline>
  </Router>
);
