export const osType = (userAgent: string): 'ios' | 'android' | null => {
  if (!userAgent) return null;

  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return null;
};
