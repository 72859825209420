import { useEffect, useState } from 'react';

export function useIsScrollLocked() {
  const [hasStyles, setHasStyles] = useState(false);

  useEffect(() => {
    const checkStyles = () => {
      const computedStyles = getComputedStyle(document.documentElement);

      const stylesMatch = computedStyles.overflow === 'hidden';

      setHasStyles(stylesMatch);
    };

    checkStyles();

    const observer = new MutationObserver(() => checkStyles());
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['style'] });

    return () => observer.disconnect();
  }, []);

  return hasStyles;
}
