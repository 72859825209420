import type { Store } from '../store/types';
import { GlobalEvents } from '../actions';

const globalParams = (store: Store) => {
  store.on('@init', (state) => ({
    ...state,
    orchestratorHost: '',
    useCredentials: true,
    requestID: '',
    isBot: false,
    userAgent: '',
    path: '',
    host: '',
  }));

  store.on(
    GlobalEvents.setRequestParams,
    (state, { orchestratorHost, orchestratorHostKz, useCredentials, apiKey }) => ({
      ...state,
      orchestratorHost,
      orchestratorHostKz,
      useCredentials,
      apiKey,
    }),
  );

  store.on(GlobalEvents.setRequestID, (state, { requestID }) => ({ ...state, requestID }));

  store.on(GlobalEvents.setIsBot, (state, next) => ({
    ...state,
    isBot: next,
  }));

  store.on(GlobalEvents.setUserAgent, (state, userAgent) => ({ ...state, userAgent }));

  store.on(GlobalEvents.setPathname, (state, path) => ({ ...state, path }));

  store.on(GlobalEvents.setHost, (state, host) => ({ ...state, host }));

  store.on(GlobalEvents.setLogoProUrl, (state, logoProUrl) => ({ ...state, logoProUrl }));
};

export const globalState = [globalParams];
export type GlobalState = {
  orchestratorHost: string;
  orchestratorHostKz?: string;
  useCredentials: boolean;
  requestID: string;
  apiKey?: string;
  isBot: boolean;
  userAgent: string;
  path: string;
  host: string;
  logoProUrl?: string;
};
