import { Match } from 'common/hooks/use-breakpoints';
import { animate } from 'components/header/components/searchbar/hooks/utils/animate';
import { TransitionState } from '../../types';

export const getAnimationParams = (state: TransitionState) => ({
  easing: 'cubic-bezier(0.24, 0.81, 0.2, 0.96)',
  duration: state === 'needsExpand' || state === 'needsCollapse' ? 450 : 0,
});

export type AnimationTuple = [string, string];

export type SearchBarAnimationParams = {
  width: AnimationTuple;
  left: AnimationTuple;
  top: AnimationTuple;
};

export type ElementReference = { getBoundingClientRect: () => DOMRect };

export interface GetSearchBarAnimationFromToParams {
  ownReference: HTMLElement | ElementReference;
  referenceFrom: HTMLElement | ElementReference;
  referenceTo: HTMLElement | ElementReference;
  reverse?: boolean;
  breakpoints?: Match | null;
}

/**
 * @description Функция возвращает параметры анимации позиционирования поисковой строки.
 *  Для появления возвращает width и left ОТ которых нужно начать анимацию, для скрытия ДО.
 * @param reference { HTMLElement }
 * @returns { SearchBarAnimationParams }
 */
export const getSearchBarAnimationFromTo = (
  params: GetSearchBarAnimationFromToParams,
): SearchBarAnimationParams => {
  const { ownReference, referenceFrom, referenceTo, reverse = false, breakpoints } = params;
  const from = referenceFrom.getBoundingClientRect();
  const to = referenceTo.getBoundingClientRect();
  const own = ownReference.getBoundingClientRect();

  const isMobile = breakpoints ? breakpoints.mobile : false;

  const width: AnimationTuple = [`${from.width}px`, `${to.width}px`];
  const left: AnimationTuple = [`${from.x}px`, `${to.x}px`];
  const top: AnimationTuple = [`${own.top}px`, isMobile ? '16px' : `${to.top}px`];

  if (reverse) {
    width.reverse();
    left.reverse();
    top.reverse();
    if (isMobile) {
      top[1] = `${from.top}px`;
    }
  }

  return {
    width,
    left,
    top,
  };
};

export type SuggestionsAnimationParams = {
  width: AnimationTuple;
  left: AnimationTuple;
  top: AnimationTuple;
  opacity: AnimationTuple;
};

export interface GetSuggestionsAnimationFromToParams {
  ownReference: HTMLElement | ElementReference;
  referencePosition: HTMLElement | ElementReference;
  isMobile: boolean;
  animationSearchBarParams: SearchBarAnimationParams;
}

export const getSuggestionsAnimationFromTo = (
  params: GetSuggestionsAnimationFromToParams,
): SuggestionsAnimationParams => {
  const { ownReference, referencePosition, isMobile, animationSearchBarParams } = params;

  const [, finishedWidth] = animationSearchBarParams.width;
  const [, finishedLeft] = animationSearchBarParams.left;
  const suggestionsWidth = isMobile ? '100%' : finishedWidth;

  const positionRect = referencePosition.getBoundingClientRect();

  const suggestionsTop = isMobile ? 0 : positionRect.top + positionRect.height + 12;
  const suggestionsFinishedLeft = isMobile ? '0px' : finishedLeft;

  const ownRect = ownReference.getBoundingClientRect();

  return {
    opacity: ['0', '1'],
    left: [`${ownRect.left}px`, suggestionsFinishedLeft],
    top: [`${ownRect.top}px`, `${suggestionsTop}px`],
    width: [`${ownRect.width}px`, suggestionsWidth],
  };
};

export interface ApplyAnimationParams<T> {
  animationRef: React.MutableRefObject<Animation | null>;
  element: HTMLElement;
  onProcessed?: () => void;
  animationProps: T;
  animationSettings: ReturnType<typeof getAnimationParams>;
  withFinished?: boolean;
}

export function applySearchBarAnimation(params: ApplyAnimationParams<SearchBarAnimationParams>) {
  const {
    animationRef,
    element,
    onProcessed,
    animationProps,
    animationSettings,
    withFinished = true,
  } = params;

  animationRef.current = animate(element, animationProps, {
    duration: animationSettings.duration,
    easing: animationSettings.easing,
    fill: 'forwards',
  });

  if (onProcessed) {
    onProcessed();
  }

  if (withFinished) {
    const [, finishedLeft] = animationProps.left;
    const [, finishedTop] = animationProps.top;
    const [, finishedWidth] = animationProps.width;

    animationRef.current.finished
      .then(() => {
        element.style.top = finishedTop;
        element.style.left = finishedLeft;
        element.style.width = finishedWidth;
        animationRef.current?.cancel();
      })
      .catch(() => {});
  }
}

export function applySuggestionAnimation(params: ApplyAnimationParams<SuggestionsAnimationParams>) {
  const { animationRef, element, animationProps, animationSettings } = params;

  const [, finishedSgLeft] = animationProps.left;
  const [, finishedSgTop] = animationProps.top;
  const [, finishedSgOpacity] = animationProps.opacity;
  const [, finishedSgWidth] = animationProps.width;

  animationRef.current = animate(element, animationProps, {
    duration: 0,
    delay: animationSettings.duration,
  });

  animationRef.current.finished
    .then(() => {
      element.style.top = finishedSgTop;
      element.style.left = finishedSgLeft;
      element.style.width = finishedSgWidth;
      element.style.opacity = finishedSgOpacity;
      animationRef.current?.cancel();
    })
    .catch(() => {});
}
