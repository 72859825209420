import { useEffect, useState } from 'react';

const EVENT = 'basket:update';

export const useBasketApi = () => {
  const [basket, setBasket] = useState<Basket[]>([]);

  useEffect(() => {
    setBasket(window.layers.basket.getBasket());

    const cb = (e: Event) => {
      setBasket((e as CustomEvent<Basket[]>).detail);
    };

    document.body.addEventListener(EVENT, cb, { passive: true });

    return () => {
      document.body.removeEventListener(EVENT, cb);
    };
  }, []);

  return { basket, basketCount: basket.length };
};
