import React, { FC } from 'react';
import type { IconComponent } from '@fronton/icons-react';
import { LinkButton, LinkButtonProps } from 'fronton-react';
import clsx from 'clsx';
import { item, itemIcon, itemIconRight } from './styles';

export interface UserMenuItemProps extends LinkButtonProps {
  icon?: IconComponent;
  rightIcon?: IconComponent;
  as?: React.ElementType;
}

export const UserMenuItem: FC<UserMenuItemProps> = (props) => {
  const { icon: Icon, rightIcon: IconRight, children, as, ...rest } = props;

  return (
    <LinkButton as={as || 'a'} {...rest} className={item} size="s">
      {Icon && (
        <span data-qa="user-menu-icon" className={itemIcon}>
          <Icon size="m" />
        </span>
      )}
      {children}
      {IconRight && (
        <span data-qa="user-menu-icon-right" className={clsx(itemIcon, itemIconRight)}>
          <IconRight size="m" />
        </span>
      )}
    </LinkButton>
  );
};
