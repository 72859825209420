export interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | 'none' | 'lax' | 'strict';
  encode?: (value: string) => string;
}

export type CookieUpdate = {
  key: string;
  value: string;
  params?: CookieSetOptions;
};

export type CookiesEventsInterface = {
  'cookiesEvents/set': string;
  'cookiesEvents/update': CookieUpdate;
};

export const CookiesEvents = {
  set: 'cookiesEvents/set',
  update: 'cookiesEvents/update',
} as const;
