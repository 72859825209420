import React from 'react';
import { List, ListItem } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchHistoryContext } from '../hooks';

export interface NotFoundSuggestionProps {
  searchQuery: string;
  searchHref: string;
  notFoundMessage?: string | false;
  collapse: () => void;
}

export const NotFoundSuggestion = (props: NotFoundSuggestionProps) => {
  const { searchQuery, searchHref, notFoundMessage, collapse } = props;
  const { addHistoryItem } = useSearchHistoryContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const url = `${searchHref}?q=${searchQuery}&suggest=true`;

  return (
    <div data-qa-header-search-not-found-suggestion="">
      <List>
        <ListItem
          value=""
          text={notFoundMessage || `${t('header.searchbar.find')} «${searchQuery}»`}
          onClick={() => {
            addHistoryItem({
              title: searchQuery,
              url,
            });
            navigate(url);
            collapse();
          }}
        />
      </List>
    </div>
  );
};
