export const isInnerLink = (link: string): boolean | undefined => {
  const innerDomains = ['lemanapro.ru', 'lemanapro.kz', 'apps.lmru.tech'];
  try {
    const url = new URL(link);
    return innerDomains.some((domain) => url.host.indexOf(domain) > -1);
  } catch (e) {
    return true;
  }
};

export const normalizeTrailingSlash = (srcLink: string) => {
  try {
    const url = new URL(srcLink);
    if (isInnerLink(srcLink) && !url.pathname.endsWith('/')) {
      url.pathname += '/';
    }
    return url.toString();
  } catch (e) {
    if (!srcLink.endsWith('/')) {
      srcLink += '/';
    }
    return srcLink;
  }
};
