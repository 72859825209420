import { analytics } from 'utils/analytics';

export const sendCitiesInputGAEvents = (eventName: string, searchString?: string) => {
  switch (eventName) {
    case 'inputFocus':
      analytics.sendEvent('GA:focus', {
        elementType: 'input',
        elementBlock: 'regionPopup',
        elementName: 'chooseRegion',
        elementText: 'Выберите город',
      });
      break;
    case 'inputUnfocus':
      analytics.sendEvent('GA:unfocus', {
        elementType: 'input',
        elementBlock: 'regionPopup',
        elementName: 'chooseRegion',
        elementText: searchString,
      });
      break;
    case 'inputClean':
      analytics.sendEvent('GA:clickButton', {
        elementType: 'button',
        elementBlock: 'regionPopup',
        elementName: 'chooseRegionCleanInput',
      });
      break;
  }
};
