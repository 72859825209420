import React, { useState } from 'react';
import { withAuth, WithAuthProps } from 'components/header/hoc/with-auth';
import { UserMenuContent } from 'components/header/components/profile/components/user/user-menu-content';
import { useOrderStatusModal } from 'components/order-status/hooks';
import { useStoreon } from 'storeon/react';
import { isUserAuth } from 'selectors/cookie';
import { State } from 'store/types';
import { UserCircleIcon, UserIcon } from '@fronton/icons-react';
import { Modal } from 'fronton-react';
import { useShoppingList, useMobileMenuAnalyticsEvent } from 'components/header/hooks';
import { useTranslation } from 'react-i18next';
import { patchUrlByLocale } from 'utils';
import { MobileMenuLink } from './mobile-menu-link';
import { menuContent } from './styles';
import { EntryUserMenu } from './entry-user-menu';

export interface MobileUserMenuProps extends WithAuthProps {
  href: string;
  shoppingListUrl: string;
  isOrderStatusOn: boolean;
  isB2BEnabled: boolean;
}

export const MobileUserMenuBase = (props: MobileUserMenuProps) => {
  const { href, shoppingListUrl, isOrderStatusOn, isB2BEnabled, ...rest } = props;
  const { cookies } = useStoreon<State>('cookies');
  const { openOrderStatusModal } = useOrderStatusModal();

  const isAuth = isUserAuth(cookies);

  const { t } = useTranslation();

  const { shoppingListCount } = useShoppingList();

  const [show, setShow] = useState<boolean>(false);

  const onEnterClick = useMobileMenuAnalyticsEvent('Вход');
  const onProfileClick = useMobileMenuAnalyticsEvent('Профиль');

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setShow(true);
    if (isAuth) {
      onProfileClick();
    } else {
      onEnterClick();
    }
  };

  const handleOrderStatusModalOpen = () => {
    setShow(false);
    openOrderStatusModal();
  };

  return (
    <>
      <MobileMenuLink
        icon={isAuth ? UserCircleIcon : UserIcon}
        href={href}
        text={isAuth ? t('header.main.profile') : t('header.main.enter')}
        getIsActive={() => false}
        data-qa="mobile-menu-auth-link"
        onClick={onClick}
      />
      <Modal show={show} onClose={() => setShow(false)} variant="curtain">
        <div className={menuContent}>
          {isAuth ? (
            <UserMenuContent
              shoppingListUrl={patchUrlByLocale({ url: shoppingListUrl })}
              shoppingListCount={shoppingListCount}
              {...rest}
            />
          ) : (
            <EntryUserMenu
              loginPageUrl={href}
              onOrderStatusModalOpen={handleOrderStatusModalOpen}
              isOrderStatusOn={isOrderStatusOn}
              isB2BEnabled={isB2BEnabled}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export const MobileUserMenu = withAuth(MobileUserMenuBase);
