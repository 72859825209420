import React, { ComponentType } from 'react';
import { SnackbarProvider } from 'fronton-react';
import { HeaderSnackbar } from '../header-snackbar';

/**
 * @description Оборачивает компонент в необходимые провайдеры: SnackbarProvider
 * @param Component - react component
 * @returns react component
 */
export const withContextSetup =
  <T extends Record<string, unknown>>(Component: ComponentType<T>) =>
  (props: T) => (
    <SnackbarProvider>
      <Component {...props} />
      <HeaderSnackbar />
    </SnackbarProvider>
  );
