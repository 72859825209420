import { analytics } from 'utils/analytics';

export type SideMenuAnalyticsElementText =
  | 'products'
  | 'services'
  | 'region'
  | 'shops'
  | 'b2b'
  | 'clientService';

const headerAnalyticsEventDetailBase = {
  elementType: 'button',
  elementBlock: 'header',
  elementName: 'headerSideMenu',
};

export const useSideMenuAnalyticsEvent = (elementText: SideMenuAnalyticsElementText) => () =>
  analytics.sendEvent('GA:clickButton', {
    ...headerAnalyticsEventDetailBase,
    elementText,
  });
