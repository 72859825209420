import { Http, HttpRequestConfig, HttpInstance, HttpCancelToken } from '@okapi/http';
import log from 'loglevel';
import { ErrorWithStatus } from './error-with-status';

export type ApiRequestConfig = HttpRequestConfig & {
  orchestratorHost: string;
  useCredentials: boolean;
  requestID: string;
  apiKey?: string;
};
export type ApiCancelToken = HttpCancelToken;

export class Api {
  http: HttpInstance;

  constructor(http: HttpInstance) {
    this.http = http;
  }

  public abortController = () => this.http.abortController();

  post = <Response = unknown, Data extends Record<string, string> = Record<string, string>>(
    url: string,
    data: Data,
    config: ApiRequestConfig,
  ) =>
    this._doFetch<Response>(buildUrl(config.orchestratorHost, url), {
      method: 'POST',
      data,
      ...config,
    });

  get = <Response = unknown, Data extends Record<string, string> = Record<string, string>>(
    url: string,
    data: Data,
    config: ApiRequestConfig,
  ) => {
    const params = new URLSearchParams(data).toString();
    const resultUrl = `${buildUrl(config.orchestratorHost, url)}?${params}`;

    return this._doFetch<Response>(resultUrl, { method: 'GET', ...config });
  };

  private _doFetch<Response>(url: string, config: ApiRequestConfig): Promise<Response> {
    const resultUrl = /^\/\//.test(url) ? `https:${url}` : url;
    const { useCredentials, requestID, apiKey, ...init } = config;

    init.withCredentials = useCredentials;

    if (requestID) {
      init.headers = { ...init.headers, 'x-request-id': requestID };
    }

    if (apiKey) {
      init.headers = { ...init.headers, 'x-api-key': apiKey };
    }

    return this.http
      .request<Response>(resultUrl, init)
      .then((response) =>
        response.status >= 200 && response.status <= 299 ? response : Promise.reject(response),
      )
      .then((response) => response.data)
      .catch((e) => {
        if (__IS_SERVER__) {
          let data = e.response?.data;

          try {
            // we want to prettify data object. But if it's not an object(maybe a cut of HTML code) this statement will fail.
            // that's why we should wrap `JSON.stringify` with `try {}` block
            // If this statement will fail, we will output `data` field as-is
            data = JSON.stringify(data, null, 2);
            // eslint-disable-next-line no-empty
          } catch {}
          log.log({
            status: e.response?.status,
            statusText: e.response?.statusText,
            requestID: init?.headers?.['x-request-id'],
            date: e.response?.headers?.date,
            url: e.response?.config?.url,
            data,
            message: e.message,
            stack: e.stack,
          });
        }

        if (e.status >= 400) {
          throw new ErrorWithStatus(e.data?.message, e.status);
        }

        return Promise.reject(e.response);
      });
  }
}

const client = new Http({
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const api = new Api(client);

function buildUrl(base: string, part: string) {
  return new URL(part, base).toString();
}
