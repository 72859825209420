import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Typography, LinkButton } from 'fronton-react';
import { ChevronRightIcon } from '@fronton/icons-react';
import { HeaderModelData } from 'types/model';
import { getShortenRegionName } from 'utils';
import { useTranslation } from 'react-i18next';
import { useRegions, useSideMenuAnalyticsEvent } from '../../hooks';

interface CityPickerProps {
  model: HeaderModelData;
  onRequestRegionChange: () => void;
}

export const CityPicker = ({ model, onRequestRegionChange }: CityPickerProps) => {
  const { t } = useTranslation();
  const { defaultRegion, currentRegion } = useRegions(model);
  const onRegionsClickEvent = useSideMenuAnalyticsEvent('region');
  const formattedRegionName = getShortenRegionName(currentRegion?.regionName || defaultRegion.regionName);

  const handleButtonClick = () => {
    onRegionsClickEvent();
    onRequestRegionChange();
  };

  return (
    <CityPickerRoot>
      <LinkButton
        size="s"
        withExtraClickZone
        onClick={handleButtonClick}
        iconRight={<ChevronRightIcon />}
        variant="accent"
        data-qa="region-modal-button"
      >
        <div>
          <Typography variant="caption" color="text-secondary" className={cityPickerLabel}>
            {t('header.main.region')}
          </Typography>
          {formattedRegionName}
        </div>
      </LinkButton>
    </CityPickerRoot>
  );
};

const CityPickerRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  button {
    white-space: nowrap;
    align-items: flex-end;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
  }

  @media (--only-mobile) {
    padding-left: var(--space-150);
    button {
      transform: translateY(1px);
    }
  }
`;

const cityPickerLabel = css`
  transform: translateY(-1px);
`;
