import { useCallback, useEffect } from 'react';
import { HFModelData } from 'types/model';
import { DOMAuthEvents } from './dom-events';

export const useAuthNavigate = (model: HFModelData | null) => {
  const navigateToLoginUrl = useCallback(() => {
    if (model) window.location.href = model?.header?.initUrl || '';
  }, [model]);

  useEffect(() => {
    const { hash } = window.location;

    if (hash === '#auth') {
      navigateToLoginUrl();
    }
  }, [navigateToLoginUrl]);

  useEffect(() => {
    const cb = () => navigateToLoginUrl();
    document.addEventListener(DOMAuthEvents.FORM_AUTH_OPEN, cb, { passive: true });

    return () => document.removeEventListener(DOMAuthEvents.FORM_AUTH_OPEN, cb);
  }, [navigateToLoginUrl]);
};
