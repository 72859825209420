import { Store } from 'store/types';
import { CookiesEvents } from 'actions';
import { createCookies } from 'utils';
import { rrHelper } from 'utils/retail-rocket';

export const cookies = (store: Store) => {
  store.on('@init', (state) => ({ ...state, cookies: {} }));

  store.on(CookiesEvents.set, (state, data) => ({
    ...state,
    cookies: createCookies(data).getAll({ doNotParse: true }),
  }));

  store.on(CookiesEvents.update, (state, { key, value, params }) => {
    const cookie = createCookies(state.cookies);
    cookie.set(key, value, params);

    if (key === 'cookie_accepted' && value === 'true') {
      rrHelper.dispatchSetAcceptedCookie();
    }

    return { ...state, cookies: cookie.getAll({ doNotParse: true }) };
  });
};

export type CookiesStore = {
  cookies: Record<string, string>;
};
