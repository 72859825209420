import { styled } from 'linaria/react';

export const Container = styled.div`
  width: 72px;
  height: 56px;
  svg {
    position: absolute;
    &:nth-child(1) {
      opacity: 1;
      animation: animateFirstLayer 8.4s linear infinite;
    }
    &:nth-child(2) {
      opacity: 0;
      animation: animateSecondLayer 8.4s linear infinite;
    }
    &:nth-child(3) {
      opacity: 0;
      animation: animateLayerLayer 8.4s linear infinite;
    }
  }

  @keyframes animateFirstLayer {
    from {
      opacity: 1;
    }
    29.4285714286% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
    96.4285714286% {
      opacity: 0;
    }
  }
  @keyframes animateSecondLayer {
    from {
      opacity: 0;
    }
    29.4285714286% {
      opacity: 0;
    }
    33% {
      opacity: 1;
    }
    62.4285714286% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
  }
  @keyframes animateLayerLayer {
    from {
      opacity: 0;
    }
    62.4285714286% {
      opacity: 0;
    }
    66% {
      opacity: 1;
    }
    96.4285714286% {
      opacity: 1;
    }
  }
`;
