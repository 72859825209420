import { css } from 'linaria';
import { drawerTransitionTime } from './ui-utils';

export const drawerTransitionEnter = css`
  &.transition-drawer:before {
    opacity: 0;
  }

  .transition-modal {
    transform: translate(-100%, 0);
  }
`;

export const drawerTransitionEnterActive = css`
  &.transition-drawer:before {
    opacity: var(--opacity-4);
  }

  .transition-modal {
    transform: translate(0, 0);
    transition: transform ${drawerTransitionTime} ease-out;
  }
`;

export const drawerTransitionExit = css`
  &.transition-drawer:before {
    opacity: var(--opacity-4);
  }

  .transition-modal {
    transform: translate(0, 0);
  }
`;

export const drawerTransitionExitActive = css`
  &.transition-drawer:before {
    opacity: 0;
  }

  .transition-modal {
    transform: translate(-100%, 0);
    transition: transform ${drawerTransitionTime} ease-out;
  }
`;
