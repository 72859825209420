import { css } from 'linaria';

export const root = css`
  position: relative;
`;

export const menu = css`
  display: flex;
  flex-direction: column;
  width: 280px;
`;
