import { useEffect, useState } from 'react';

const WISHLIST_UPDATE = 'wishlist:update';

export const useShoppingList = () => {
  const [shoppingList, setShoppingList] = useState<ShoppingList[]>([]);

  useEffect(() => {
    setShoppingList(window.layers.shoppingList.getShoppingList());

    const cb = () => {
      setShoppingList(window.layers.shoppingList.getShoppingList());
    };

    document.body.addEventListener(WISHLIST_UPDATE, cb, { passive: true });

    return () => {
      document.body.removeEventListener(WISHLIST_UPDATE, cb);
    };
  }, []);

  return {
    shoppingList,
    shoppingListCount: shoppingList.length,
  };
};
