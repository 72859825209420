import { css } from 'linaria';

export const suggestionsList = css`
  width: 100%;
`;

export const suggestionsTitle = css`
  padding: var(--space-150) var(--space-200) var(--space-50) var(--space-200) !important;

  font-size: 14px;
  line-height: 18px;
  color: var(--text-minor);

  @media (--only-mobile) {
    flex: auto;
  }
`;

export const suggestionsWrapper = css`
  display: flex;
  flex-direction: column;
  @media (--only-mobile) {
    max-width: 100dvw;
  }
`;

export const dividerTop = css`
  position: relative;
  margin-top: var(--space-50);
  padding-top: var(--space-50);

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% + var(--space-400));
    border-top: 1px solid var(--control-minor);
    left: var(--space-900);
    top: 0;
  }
`;
