import React, { ComponentType } from 'react';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';
import { HeaderModelData } from 'types/model';

export interface WithModelProps {
  model?: HeaderModelData;
}

export const withModel =
  <T extends Record<string, unknown>>(Component: ComponentType<T & WithModelProps>) =>
  (props: T) => {
    const { model } = useStoreon<State>('model');
    if (!model) return null;
    return <Component {...props} model={model as HeaderModelData} />;
  };
