import { useProfileAnalyticsEvent } from 'components/header/hooks';
import { useState } from 'react';
import { analytics } from 'utils';

export const useOrderStatusAnalyticEvent = () => {
  const [errorList, setErrorList] = useState<string[]>([]);

  const sendOrderStatusOpeningEvent = useProfileAnalyticsEvent('orderStatus');

  const sendOrderStatusClosingEvent = () => {
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: 'orderStatus',
      elementText: 'close',
    });
  };

  const sendSuccessResponseOrderStatusEvent = (status: string) => {
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: 'orderStatus',
      elementText: 'success',
      elementVariant: status,
    });
  };

  const sendErrorOrderStatusEvent = (errorText: string | string[]) => {
    const errorArray = Array.isArray(errorText) ? errorText : [errorText];

    const newErrorTexts = errorArray.filter((error) => !errorList.includes(error));

    if (newErrorTexts.length === 0) {
      return;
    }

    setErrorList([...errorList, ...newErrorTexts]);

    newErrorTexts.forEach((text) => {
      analytics.sendEvent('GA:show', {
        elementType: 'error',
        elementBlock: 'header',
        elementName: 'orderStatus',
        elementText: text,
      });
    });
  };

  return {
    sendOrderStatusClosingEvent,
    sendOrderStatusOpeningEvent,
    sendSuccessResponseOrderStatusEvent,
    sendErrorOrderStatusEvent,
  };
};
