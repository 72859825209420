import { OrderStatusError, OrderStatusInfo } from 'types/order-status';
import { Store } from '../store/types';
import { FETCH_ORDER_STATUS, ORDER_STATUS } from '../actions';
import { getOrderStatus } from '../api';

const initialState = { error: null, loading: false, info: null, isShowModal: false };

export const orderStatus = (store: Store) => {
  store.on('@init', (state) => ({
    ...state,
    orderStatus: initialState,
  }));

  store.on(
    FETCH_ORDER_STATUS.request,
    (state, { isMarketplaceOrder, orderNumber, phone, recaptchaToken }) => {
      const { orchestratorHost, useCredentials, apiKey, requestID } = store.get();

      getOrderStatus(
        { isMarketplaceOrder, orderNumber, phone, recaptchaToken },
        {
          orchestratorHost,
          useCredentials,
          apiKey,
          requestID,
        },
      )
        .then((info) => {
          store.dispatch(FETCH_ORDER_STATUS.success, info);
        })
        .catch((error) => {
          let orderStatusError: OrderStatusError = OrderStatusError.INTERNAL_ERROR;

          if (error?.status === 400 || error?.status === 404) {
            orderStatusError = OrderStatusError.NOT_FOUND;
          }

          store.dispatch(FETCH_ORDER_STATUS.failure, orderStatusError);
        });

      return {
        ...state,
        orderStatus: {
          ...state.orderStatus,
          error: null,
          info: null,
          loading: true,
        },
      };
    },
  );

  store.on(FETCH_ORDER_STATUS.loading, (state) => ({
    ...state,
    orderStatus: { ...state.orderStatus, loading: true },
  }));

  store.on(FETCH_ORDER_STATUS.success, (state, info) => ({
    ...state,
    orderStatus: { ...state.orderStatus, loading: false, info },
  }));

  store.on(FETCH_ORDER_STATUS.failure, (state, error) => ({
    ...state,
    orderStatus: { ...state.orderStatus, loading: false, error },
  }));

  store.on(ORDER_STATUS.openModal, (state) => ({
    ...state,
    orderStatus: { ...initialState, isShowModal: true },
  }));

  store.on(ORDER_STATUS.closeModal, (state) => ({
    ...state,
    orderStatus: { ...initialState },
  }));
};

export type OrderStatusStore = {
  orderStatus: {
    loading: boolean;
    error: OrderStatusError | null;
    info: OrderStatusInfo | null;
    isShowModal: boolean;
  };
};
