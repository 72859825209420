import React, { HTMLAttributes, FC } from 'react';
import clsx from 'clsx';
import { paddingX, paddingY, hoverLayout, focusLayout } from './styles';

export interface MenuContentLayerProps extends HTMLAttributes<HTMLElement> {
  vertical?: boolean;
  horizontal?: boolean;
  withHover?: boolean;
  focused?: boolean;
}

export const MenuContentLayer: FC<MenuContentLayerProps> = (props) => {
  const {
    vertical = false,
    horizontal = false,
    withHover = false,
    focused = false,
    children,
    className,
    ...rest
  } = props;

  const classNames = clsx(className, {
    [paddingX]: horizontal,
    [paddingY]: vertical,
    [hoverLayout]: withHover,
    [focusLayout]: focused,
  });

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};
