import { MutableRefObject, useEffect } from 'react';

export function useClickOutside(ref: MutableRefObject<HTMLElement | null>, cb: () => void) {
  useEffect(() => {
    const supportPointerEvents = !!window.PointerEvent;

    const handlePointerEvent = (e: MouseEvent | PointerEvent) => handleClickOutside(e, e.clientX, e.clientY);

    const handleTouchEvent = (e: TouchEvent) => {
      const { clientX, clientY } = e.touches[0];

      handleClickOutside(e, clientX, clientY);
    };

    const handleClickOutside = (e: Event, xPosition: number, yPosition: number) => {
      if (ref.current) {
        const elementBox = ref.current.getBoundingClientRect();

        if (
          xPosition > elementBox.left &&
          xPosition < elementBox.right &&
          yPosition > elementBox.top &&
          yPosition < elementBox.bottom
        ) {
          return;
        }

        e.stopPropagation();

        cb();
      }
    };

    if (supportPointerEvents) {
      document.addEventListener('pointerdown', handlePointerEvent, { capture: true });

      return () => document.removeEventListener('pointerdown', handlePointerEvent);
    }

    document.addEventListener('mousedown', handlePointerEvent, { capture: true });
    document.addEventListener('touchstart', handleTouchEvent, { capture: true });

    return () => {
      document.removeEventListener('mousedown', handlePointerEvent);
      document.removeEventListener('touchstart', handleTouchEvent);
    };
  }, [ref, cb]);
}
