import { Store } from '../store/types';
import { SubscriptionEvents } from '../actions';

export const subscription = (store: Store) => {
  store.on('@init', (state) => ({
    ...state,
    subscribersUrl: '',
    subscribersApiKey: '',
    recaptchaPublicKey: '',
  }));

  store.on(
    SubscriptionEvents.setRecaptchaParams,
    (state, { subscribersUrl, subscribersApiKey, recaptchaPublicKey }) => ({
      ...state,
      subscribersUrl,
      subscribersApiKey,
      recaptchaPublicKey,
    }),
  );
};

export type SubscriptionStore = {
  subscribersUrl: string;
  subscribersApiKey: string;
  recaptchaPublicKey: string;
};
