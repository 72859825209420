import React, { useRef } from 'react';
import { Divider, Typography } from 'fronton-react';
import {
  UserCircleIcon,
  IdentificationBadgeIcon,
  ChevronRightIcon,
  PackageIcon,
} from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'components/header/hooks/use-click-outside';
import { UserMenuItem } from '../menu-item';
import { entryMenuTitle, menuWrap } from './styles';

export interface EntryMenuProps {
  login: () => void;
  loginPageUrl: string;
  onClose: () => void;
  b2bLoginPageUrl: string;
  loginB2B: () => void;
  onOrderStatusModalOpen: () => void;
  isOrderStatusOn: boolean;
  isB2BEnabled: boolean;
}

export const EntryMenu = ({
  login,
  loginPageUrl,
  onClose,
  b2bLoginPageUrl,
  loginB2B,
  onOrderStatusModalOpen,
  isOrderStatusOn,
  isB2BEnabled,
}: EntryMenuProps) => {
  const tooltipContentRef = useRef(null);

  const { t } = useTranslation();

  useClickOutside(tooltipContentRef, onClose);

  const handleOrderStatusModalOpen = () => {
    onClose();
    onOrderStatusModalOpen();
  };

  return (
    <div ref={tooltipContentRef} className={menuWrap}>
      {isOrderStatusOn && (
        <Typography variant="overline" color="text-minor" className={entryMenuTitle}>
          {t('header.main.user-menu.entry')}
        </Typography>
      )}

      <UserMenuItem
        icon={UserCircleIcon}
        rightIcon={ChevronRightIcon}
        data-qa="signin-lk"
        href={loginPageUrl}
        onClick={login}
      >
        {t('header.main.user-menu.private-acc')}
      </UserMenuItem>

      {isB2BEnabled && (
        <UserMenuItem
          icon={IdentificationBadgeIcon}
          rightIcon={ChevronRightIcon}
          data-qa="signin-lk-b2b"
          href={b2bLoginPageUrl}
          onClick={loginB2B}
        >
          {t('header.main.user-menu.business-acc')}
        </UserMenuItem>
      )}
      {isOrderStatusOn && (
        <>
          <Divider />

          <UserMenuItem
            onClick={handleOrderStatusModalOpen}
            icon={PackageIcon}
            data-qa="order-status-button"
          >
            {t('header.main.user-menu.order-status')}
          </UserMenuItem>
        </>
      )}
    </div>
  );
};
