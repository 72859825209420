import React from 'react';
import { useHandleEscPress } from 'common/hooks';
import clsx from 'clsx';
import { modalOverlay, active } from './styles';

export interface ModalOverlayProps {
  onClose: () => void;
  expanded: boolean;
}

export const ModalOverlay = (props: ModalOverlayProps) => {
  const { onClose, expanded } = props;
  useHandleEscPress(onClose);

  return <div onClick={onClose} className={clsx(modalOverlay, { [active]: expanded })} />;
};
