import { ColorValueStrict } from 'fronton-react';
import { IconColors } from '@fronton/icons-react/types';
import {
  ArchiveBoxIcon,
  CheckIcon,
  CreditCardIcon,
  CheckSquareOffsetIcon,
  FlagIcon,
  PackageIcon,
  ProhibitIcon,
  QuestionIcon,
  TruckIcon,
  IconComponent,
} from '@fronton/icons-react';
import { OrderStatusType } from './order-status-type';

type OrderStatusIconFields = {
  [key in OrderStatusType]: {
    icon?: IconComponent;
    iconColor: IconColors;
    backgroundColor: ColorValueStrict;
    withBorder?: boolean;
  };
};

export const ORDER_STATUS_ICON: OrderStatusIconFields = {
  [OrderStatusType.AWAITING_PAYMENT]: {
    icon: CreditCardIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'secondary-orange-primary',
  },
  [OrderStatusType.ACCEPTED]: {
    icon: CheckSquareOffsetIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.CREATED]: {
    icon: CheckSquareOffsetIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.CONFIRMED]: {
    icon: CheckSquareOffsetIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.PICKING_IN_PROGRESS]: {
    icon: ArchiveBoxIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.PICKED_WAITING_FOR_PAYMENT]: {
    icon: PackageIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.READY_FOR_DELIVERY]: {
    icon: PackageIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.ON_DELIVERY]: {
    icon: TruckIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.ON_COURIER_DELIVERY]: {
    icon: TruckIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.DELIVERED]: {
    icon: CheckIcon,
    iconColor: 'success-primary',
    backgroundColor: 'success-light',
  },
  [OrderStatusType.PARTIALLY_DELIVERED]: {
    icon: CheckIcon,
    iconColor: 'success-primary',
    backgroundColor: 'success-light',
  },
  [OrderStatusType.RESTOCKING]: {
    icon: CheckIcon,
    iconColor: 'success-primary',
    backgroundColor: 'success-light',
  },
  [OrderStatusType.CANCELLED]: {
    icon: ProhibitIcon,
    iconColor: 'attention-primary',
    backgroundColor: 'attention-light',
  },
  [OrderStatusType.DELETED]: {
    icon: ProhibitIcon,
    iconColor: 'attention-primary',
    backgroundColor: 'attention-light',
  },
  [OrderStatusType.EXPIRED]: {
    icon: ProhibitIcon,
    iconColor: 'attention-primary',
    backgroundColor: 'attention-light',
  },
  [OrderStatusType.DELIVERED_TO_PUP]: {
    icon: FlagIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.READY_FOR_GIVEAWAY]: {
    icon: FlagIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.PICKED]: {
    icon: FlagIcon,
    iconColor: 'text-primary-invert',
    backgroundColor: 'success-primary',
  },
  [OrderStatusType.GIVEN_AWAY]: {
    icon: CheckIcon,
    iconColor: 'success-primary',
    backgroundColor: 'success-light',
  },
  [OrderStatusType.PARTIALLY_GIVEN_AWAY]: {
    icon: CheckIcon,
    iconColor: 'success-primary',
    backgroundColor: 'success-light',
  },
  [OrderStatusType.COMPLETED]: {
    icon: CheckIcon,
    iconColor: 'success-primary',
    backgroundColor: 'success-light',
  },
  [OrderStatusType.IN_EXECUTION]: {
    iconColor: 'primary',
    backgroundColor: 'background-primary',
    withBorder: true,
  },
  [OrderStatusType.UNKNOWN]: {
    icon: QuestionIcon,
    iconColor: 'text-minor',
    backgroundColor: 'background-primary',
    withBorder: true,
  },
};
