import { ColorValueStrict } from 'fronton-react';
import { OrderStatusType } from './order-status-type';

export const SERVICE_UNAVAILABLE = 'Сервис недоступен';
export const TRY_AGAIN_LATER = 'Повторите попытку позже';
export const ORDER_NOT_FOUND =
  'Заказ не найден. Проверьте правильность введенных данных и повторите попытку.';

type OrderStatusTextFields = {
  [key in OrderStatusType]?: {
    textColor?: ColorValueStrict;
  };
};

export const ORDER_STATUS_TEXT: OrderStatusTextFields = {
  [OrderStatusType.AWAITING_PAYMENT]: {
    textColor: 'secondary-orange-dark',
  },
};
