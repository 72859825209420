export type LangType = 'ru' | 'kk';

export type RegionType =
  | 'ru'
  | 'kz'
  // NOTE: значение local позволяет переключать язык на *.local хостах, в том числе на localhost,
  // для этого в externalId параметрах эксперимента нужно прописать local
  // пример:
  // {
  //   "experiments": [
  //     {
  //       "internalId": "header_lang_picker",
  //       "externalId": "domains:local;changePath:true"
  //     }
  //   ]
  // }
  | 'local';

export type RegionSet = Set<RegionType>;

export const LANG: { [key: string]: LangType } = {
  ru: 'ru',
  kk: 'kk',
};

export const LANG_REGION: { [key: string]: RegionType } = {
  ru: 'ru',
  kz: 'kz',
  local: 'local',
};

export const LANG_TO_REGION: { [key: string]: RegionType } = {
  ru: 'ru',
  kk: 'kz',
};
