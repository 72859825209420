import { createStoreon } from 'storeon';
import { storeonDevtools, storeonLogger } from 'storeon/devtools';
import {
  globalState,
  modelJson,
  suggestions,
  cookies,
  auth,
  catalogue,
  subscription,
  productRedirectionUrl,
  orderStatus,
  headerDrawers,
} from '../reducers';
import { State, Events } from './types';

export const createStore = () =>
  createStoreon<State, Events>([
    ...modelJson,
    ...globalState,
    suggestions,
    cookies,
    auth,
    catalogue,
    subscription,
    productRedirectionUrl,
    orderStatus,
    headerDrawers,
    !__IS_SERVER__ && process.env.NODE_ENV !== 'production' && storeonDevtools,
    !__IS_SERVER__ && process.env.NODE_ENV !== 'production' && storeonLogger,
  ]);
