import React, { useEffect, useRef } from 'react';
import { useStoreon } from 'storeon/react';
import { useRouter } from '@okapi/router-react';
import { MapPinIcon } from '@fronton/icons-react';
import { useSnackbar } from 'fronton-react';
import { State } from 'store/types';
import { useHideRegions } from 'common/hooks';
import { HeaderModelData } from 'types/model';
import { useRegions } from '../use-regions';
import { useRegionSubmitLogic } from '../use-region-submit-logic';
import { RegionSnackContent } from './region-snack-content';

export interface UseRegionSelectParams {
  onChangeClick: () => void;
  model: HeaderModelData;
  isSticky?: boolean;
}

const snackID = 'region-snack';

export const useRegionSelect = (params: UseRegionSelectParams) => {
  const { model, onChangeClick, isSticky } = params;
  const { cookies } = useStoreon<State>('cookies');
  const router = useRouter();
  const isHideRegions = useHideRegions();

  const { addSnack: useAddSnack, removeSnack: useRemoveSnack } = useSnackbar();

  const { current: addSnack } = useRef(useAddSnack);
  const { current: removeSnack } = useRef(useRemoveSnack);

  const { showSubmitNotification, submitRegion } = useRegionSubmitLogic(
    cookies,
    router.getSearch(),
    model?.properties.regionId,
  );

  const { currentRegion } = useRegions(model);

  useEffect(() => {
    if (showSubmitNotification && !isSticky && !isHideRegions) {
      addSnack({
        id: snackID,
        icon: <MapPinIcon type="outline" size="l" color="text-invert" />,
        variant: 'primary',
        testId: 'region-notification',
        content: (
          <RegionSnackContent
            onChangeClick={() => {
              removeSnack(snackID);
              onChangeClick();
            }}
            submitRegion={submitRegion}
            region={currentRegion}
          />
        ),
      });
    }

    return () => {
      removeSnack(snackID);
    };
  }, [
    onChangeClick,
    showSubmitNotification,
    submitRegion,
    currentRegion,
    addSnack,
    removeSnack,
    isSticky,
    isHideRegions,
  ]);
};
