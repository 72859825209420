import { analytics } from 'utils/analytics';

export type MobileMenuAnalyticsElementText =
  | 'Главная'
  | 'Мой список'
  | 'Магазины'
  | 'Вход'
  | 'Корзина'
  | 'Профиль';

export const mobileMenuAnalyticsEventDetailBase = {
  elementType: 'button',
  elementBlock: 'header',
  elementName: 'fixedMenu',
};

export const useMobileMenuAnalyticsEvent = (elementText: MobileMenuAnalyticsElementText) => () =>
  analytics.sendEvent('GA:clickButton', {
    ...mobileMenuAnalyticsEventDetailBase,
    elementText,
  });
