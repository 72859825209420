import React, { useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { Tooltip, TooltipProps } from 'fronton-react';
import { useClickOutside } from 'components/header/hooks/use-click-outside';
import { menu } from './styles';
import { UserMenuContent } from './user-menu-content';
import { UserMenuProps } from './types';
import { tooltip } from '../../styles/tooltip';

export interface UserMenuTooltipProps extends UserMenuProps, TooltipProps {}

export const UserMenu = (props: UserMenuTooltipProps) => {
  const { show, shoppingListCount, shoppingListUrl, logout, children, onClose, ...rest } = props;
  const contentRef = useRef(null);

  useClickOutside(contentRef, onClose);

  if (show) {
    return (
      <Tooltip
        placement="bottom"
        className={tooltip}
        open
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={
          <div className={menu} ref={contentRef}>
            <FocusLock>
              <UserMenuContent
                shoppingListCount={shoppingListCount}
                shoppingListUrl={shoppingListUrl}
                logout={logout}
              />
            </FocusLock>
          </div>
        }
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="bottom" {...rest}>
      {children}
    </Tooltip>
  );
};
