import { Store } from '../store/types';
import { CatalogueChunks } from '../types/catalogue';
import { FETCH_CATALOGUE } from '../actions';
import { getCatalogue } from '../api';
import { createCookies, getLangFromCookies } from '../utils';

export const catalogue = (store: Store) => {
  store.on('@init', (state) => ({
    ...state,
    catalogue: { chunks: {}, error: false, loading: false },
  }));

  store.on(FETCH_CATALOGUE.request, (state, { region, path }) => {
    const { orchestratorHost, useCredentials, apiKey, requestID, cookies } = store.get();
    const lang = getLangFromCookies(createCookies(cookies));

    getCatalogue(
      { region, path, lang },
      {
        orchestratorHost,
        useCredentials,
        apiKey,
        requestID,
      },
    )
      .then((data) => store.dispatch(FETCH_CATALOGUE.success, { data, path }))
      .catch(() => store.dispatch(FETCH_CATALOGUE.failure, path));

    return {
      ...state,
      catalogue: {
        chunks: { ...state.catalogue.chunks, [path]: null },
        loading: true,
        error: false,
      },
    };
  });

  store.on(FETCH_CATALOGUE.success, (state, { data, path }) => ({
    ...state,
    catalogue: { chunks: { ...state.catalogue.chunks, [path]: data }, loading: false, error: false },
  }));

  store.on(FETCH_CATALOGUE.failure, (state, path) => ({
    ...state,
    catalogue: { chunks: { ...state.catalogue.chunks, [path]: null }, loading: false, error: true },
  }));
};

export type CatalogueStore = {
  catalogue: {
    chunks: CatalogueChunks;
    loading: boolean;
    error: boolean;
  };
};
