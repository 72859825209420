import React from 'react';
import { RegularButton, Tooltip } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { BookmarkSimpleIcon } from '@fronton/icons-react';
import { useHeaderAnalyticsEvent } from 'components/header/hooks';
import { HFModelData } from 'types/model';
import { wrapper } from 'components/header/styles/header-hero-button';
import { useTranslation } from 'react-i18next';
import { patchUrlByLocale } from 'utils';

export interface ShoppingButtonProps {
  model: HFModelData;
}

export const ShoppingButton = (props: ShoppingButtonProps) => {
  const { path: pathname } = useStoreon<State>('path');
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');
  const { model } = props;
  const { shoppingList } = model.header || {};

  const { t } = useTranslation();

  const onClick = useHeaderAnalyticsEvent('shoppingList');

  const label = t('header.main.shopping-list');

  return (
    <Tooltip title={label}>
      <div className={wrapper}>
        <RegularButton
          onClick={onClick}
          rounded
          aria-label={label}
          variant="secondary"
          iconOnly
          as="a"
          data-qa="header-shopping-list-button"
          href={patchUrlByLocale({ url: shoppingList, pathname, lang })}
          size="l"
        >
          <BookmarkSimpleIcon />
        </RegularButton>
      </div>
    </Tooltip>
  );
};
