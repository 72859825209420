import { css } from 'linaria';

export const preloader = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: var(--space-200);
  height: var(--space-600);
  padding: var(--space-75) var(--space-200) var(--space-75) 0;
`;

export const preloaderContent = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: var(--space-25);
  justify-content: flex-start;
  align-items: center;
`;

export const preloaderContentRow = css`
  width: 100%;
  height: 22px;
`;

export const preloaderIcon = css`
  display: flex;
  --p-space: 40px;
  width: 40px;
  height: 40px;
  padding: var(--space-100);
  justify-content: center;
  align-items: center;
  border-radius: rounding(--rounding-m);
  background-size: 40px !important;
`;

export const skeleton = css`
  position: relative;
  @keyframes progress {
    from {
      background-position-x: 0%;
    }
    to {
      background-position-x: 100%;
    }
  }

  top: var(--space-175);
  bottom: var(--space-175);
  left: var(--space-175);
  border-radius: var(--rounding-m);

  background: linear-gradient(
    90deg,
    var(--background-secondary),
    var(--background-primary),
    var(--background-secondary)
  );
  background-size: 50%;
  animation: progress 1s linear infinite;
`;
