import { css } from 'linaria';

export const content = css`
  display: flex;
  flex-direction: column;
`;

export const buttonGroup = css`
  margin-top: var(--space-100);
  display: flex;
  column-gap: var(--space-100);
  align-self: end;
`;
