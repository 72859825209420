import { CatalogueChildrenProps } from '../types/catalogue';

export type CatalogueEvents = {
  'catalogue/FETCH_CATALOGUE/request': { region: string; path: string };
  'catalogue/FETCH_CATALOGUE/success': { data: CatalogueChildrenProps; path: string };
  'catalogue/FETCH_CATALOGUE/failure': string;
  'catalogue/FETCH_CATALOGUE/cancel': undefined;
  'catalogue/RESET': undefined;
};

export const FETCH_CATALOGUE = {
  request: 'catalogue/FETCH_CATALOGUE/request',
  success: 'catalogue/FETCH_CATALOGUE/success',
  failure: 'catalogue/FETCH_CATALOGUE/failure',
  cancel: 'catalogue/FETCH_CATALOGUE/cancel',
} as const;
