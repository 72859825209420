import React from 'react';
import { Typography, RegularButton } from 'fronton-react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { patchUrlByLocale } from 'utils';
import {
  suggestionContentWrap,
  suggestionIconWrap,
  suggestionTextContentWrap,
  suggestionPromoIconWrap,
  suggestionButton,
  suggestionText,
  suggestion,
} from './suggestion-list-item-styles';
import { SuggestionListItemPromoIcon } from './suggestion-list-item-promo-icon';
import { SuggestionsGaMetaInfo } from '../../types';

export interface SuggestionListItemPromoPageProps {
  elementIndex: number;
  navigationIndex: number;
  isSrp: boolean;
  suggestionSelector: string;
  buttonAction: (
    id: number,
    linkToNavigate?: string,
    gaMeta?: SuggestionsGaMetaInfo,
  ) => React.HTMLAttributes<HTMLButtonElement>;
}

export const SuggestionListItemPromoPage = (props: SuggestionListItemPromoPageProps) => {
  const { isSrp, navigationIndex, elementIndex, buttonAction } = props;

  const { t } = useTranslation();

  return (
    <div role="option" aria-selected={elementIndex === navigationIndex} data-qa-header-search-suggestion="">
      <RegularButton
        variant="pseudo"
        tabIndex={-1}
        className={clsx(suggestion, {
          active: elementIndex === navigationIndex,
          [suggestionButton]: isSrp,
        })}
        data-qa="suggestion-button"
        // eslint-disable-next-line i18next/no-literal-string
        {...buttonAction(elementIndex, '/promo/', {
          block: t('header.promo.promo-block'),
          index: elementIndex,
          text: t('header.promo.promo-text'),
        })}
        href={patchUrlByLocale({ url: '/promo/' })}
      >
        <div className={suggestionContentWrap}>
          <div className={clsx(suggestionIconWrap, suggestionPromoIconWrap)}>
            <SuggestionListItemPromoIcon />
          </div>
          <div className={suggestionTextContentWrap}>
            <Typography variant="m" size="body_short" className={suggestionText} color="text-primary">
              {t('header.promo.promo-text')}
            </Typography>
            <Typography variant="caption" color="text-secondary">
              {t('header.promo.promo-sales')}
            </Typography>
          </div>
        </div>
      </RegularButton>
    </div>
  );
};
