import { css } from 'linaria';
import { globalOverlay } from 'styles/z-indexs';

export const tooltip = css`
  & [role='tooltip'] {
    background: var(--background-primary);
    box-shadow: var(--box-shadow-m);
    transition: unset;
    pointer-events: unset;
    padding: var(--space-100) 0;
    z-index: ${globalOverlay};

    & div::before {
      background: var(--background-primary);
    }
  }
`;
