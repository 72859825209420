import React, { useRef, useState } from 'react';
import { RegularButton } from 'fronton-react';
import { UserCircleIcon } from '@fronton/icons-react';
import { HeaderModelData } from 'types/model';
import { WithAuthProps } from 'components/header/hoc/with-auth';
import { useShoppingList, useHeaderAnalyticsEvent } from 'components/header/hooks';
import { useHandleEscPress } from 'common/hooks/use-handle-esc-press';
import { useTranslation } from 'react-i18next';
import { UserMenu } from './user-menu';
import { root } from './styles';

export interface UserProps extends WithAuthProps {
  model: HeaderModelData;
}

export const User = (props: UserProps) => {
  const { model, ...authProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { shoppingListCount } = useShoppingList();

  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  useHandleEscPress(() => setShow(false), show);

  const onAccountEvent = useHeaderAnalyticsEvent('account');

  const onMenuOpen = () => {
    if (!show) {
      setShow(true);
      onAccountEvent();
    }
  };

  return (
    <div data-qa="user-menu" className={root} ref={containerRef}>
      <UserMenu
        show={show}
        title={t('header.main.profile')}
        shoppingListCount={shoppingListCount}
        shoppingListUrl={model.header.shoppingList}
        logout={authProps.logout}
        onClose={() => setShow(false)}
      >
        <RegularButton
          size="l"
          data-qa="user-icon"
          iconOnly
          label={t('header.main.profile-low')}
          rounded
          disabled={show}
          onClick={onMenuOpen}
          variant="secondary"
        >
          <UserCircleIcon type="outline" />
        </RegularButton>
      </UserMenu>
    </div>
  );
};
