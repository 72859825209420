import React from 'react';
import { CATALOG_PAGE_PATH, SERVICES_PAGE_PATH, APRO_PAGE_PATH } from 'common/constants';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter } from 'common/components/left-drawer';
import { HeaderModelData } from 'types/model';
import { headerBackBtn } from 'components/header/styles/common';
import { LanguageSelector } from 'components/language-selector';
import { useLangPickerExperiment } from 'common/hooks/use-lang-picker-experiment';
import { HIDE_PRO_INTERNAL_ID } from 'common/constants/experiment';
import { UseHamburgerMenuStateReturnType, useSideMenuAnalyticsEvent } from '../../hooks';
import {
  secondaryItemsRoot,
  footer,
  content,
  secondaryItem,
  stretchedLink,
  linkSpace,
  primaryMenuItem,
} from './styles';
import { PrimaryMenuItem } from './primary-menu-item';
import { SecondaryMenuItem } from './secondary-menu-item';
import { MenuContentLayer } from '../menu-content-layer';
import clsx from 'clsx';

export const DATA_QA_B2B_LINK = 'b2b-link';
const DATA_QA_CATALOG_LINK = 'catalog-link';
const DATA_QA_SERVICE_LINK = 'services-page-link';
const DATA_QA_STORE_LINK = 'store-link';
const DATA_QA_CLIENT_SUPPORT_LINK = 'client-support-link';

export interface MainMenuProps extends UseHamburgerMenuStateReturnType {
  model: HeaderModelData;
}

export const MainMenu = (props: MainMenuProps) => {
  const { show, onMenuClose, model } = props;
  const { shops } = model.header;
  const { KindNavigationDrawer } = model;

  const isPROHidden = model?.experiments?.some((ex) => ex.internalId === HIDE_PRO_INTERNAL_ID);

  const onCatalogClick = useSideMenuAnalyticsEvent('products');
  const onServicesClick = useSideMenuAnalyticsEvent('services');
  const onShopsClick = useSideMenuAnalyticsEvent('shops');
  const onClientServiceClick = useSideMenuAnalyticsEvent('clientService');
  const onBiznesClick = useSideMenuAnalyticsEvent('b2b');

  const { langDomains, changeLangPath } = useLangPickerExperiment();

  const menuItemDataMap: Record<string, { dataQa: string; onClick: () => void } | undefined> = {
    [CATALOG_PAGE_PATH]: {
      dataQa: DATA_QA_CATALOG_LINK,
      onClick: onCatalogClick,
    },
    [SERVICES_PAGE_PATH]: {
      dataQa: DATA_QA_SERVICE_LINK,
      onClick: onServicesClick,
    },
    [shops]: {
      dataQa: DATA_QA_STORE_LINK,
      onClick: onShopsClick,
    },
    [APRO_PAGE_PATH]: {
      dataQa: DATA_QA_B2B_LINK,
      onClick: onBiznesClick,
    },
  };
  return (
    <Drawer onClose={onMenuClose} show={show}>
      <DrawerHeader title="" onClickBack={onMenuClose} className={headerBackBtn}>
        {langDomains && <LanguageSelector langDomains={langDomains} changeLangPath={changeLangPath} />}
      </DrawerHeader>
      <DrawerContent className={content}>
        {KindNavigationDrawer?.content?.mainNavigation.map((item) => {
          const { title, description, href, target, divider } = item;
          const itemData = menuItemDataMap[href];

          return (
            <PrimaryMenuItem
              key={href}
              divider={divider}
              data-qa={itemData?.dataQa}
              onClick={itemData?.onClick}
              title={title}
              description={description}
              href={href}
              className={clsx(primaryMenuItem, stretchedLink)}
              target={target}
            />
          );
        })}
        <MenuContentLayer className={secondaryItemsRoot}>
          {KindNavigationDrawer?.content?.secondaryNavigation
            .filter(({ KindAnchor: { href } }) => !(href === APRO_PAGE_PATH && isPROHidden))
            .map(({ iconHref, divider, KindAnchor }) => {
              const itemData = menuItemDataMap[KindAnchor.href];

              const className = clsx(secondaryItem, stretchedLink, linkSpace);

              return (
                <SecondaryMenuItem
                  key={KindAnchor.href}
                  iconHref={iconHref}
                  divider={divider}
                  KindAnchor={KindAnchor}
                  data-qa={itemData?.dataQa}
                  onClick={itemData?.onClick}
                  className={className}
                />
              );
            })}
        </MenuContentLayer>
      </DrawerContent>
      <DrawerFooter className={footer}>
        {KindNavigationDrawer?.footer?.secondaryNavigation.map((item) => {
          const { iconHref, divider, KindAnchor } = item;
          const className = clsx(secondaryItem, stretchedLink, linkSpace);
          return (
            <SecondaryMenuItem
              key={KindAnchor.href}
              iconHref={iconHref}
              iconWidth={20}
              iconHeight={16}
              divider={divider}
              KindAnchor={KindAnchor}
              data-qa={DATA_QA_CLIENT_SUPPORT_LINK}
              onClick={onClientServiceClick}
              className={className}
            />
          );
        })}
      </DrawerFooter>
    </Drawer>
  );
};
