import { api, ApiRequestConfig } from './api';
import { CatalogueChildrenProps } from '../types/catalogue';

export type GetCatalogueParamProp = {
  region: string;
  path: string;
  lang: string;
};

export const getCatalogue = (params: GetCatalogueParamProp, config: ApiRequestConfig) =>
  api.get<CatalogueChildrenProps>('get-flex-catalogue', params, config);
